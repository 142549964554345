import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { DevExText } from 'jf/components/DevExText';

import { DevExSkeleton } from '../components/DevExSkeleton';
import { DevExTabs } from '../components/DevExTabs';

import { useDevExTheme } from './themes/DevExTheme';
import { DevExPageLayout } from './DevExPageLayout';

const styles = {
  pageHeader: css`
    padding-bottom: 0;

    .pageHeader__tabs {
      .ant-tabs-nav {
        padding-top: ${(props) => props.theme.variable.spacing.sm};
        margin-bottom: -1px;
      }
    }
  `,
};

export type DevExPageHeaderTab = {
  key: string;
  label: string;
  href: string;
  disabled?: boolean;
  tooltipText?: string;
};

type DevExPageHeaderProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  extra?: ReactNode;
  tabs?: DevExPageHeaderTab[];
  tabKey?: string;
  tabBarExtraContent?: string | React.ReactElement;
};

export const DevExPageHeader: React.FC<DevExPageHeaderProps> = (props) => {
  const navigate = useNavigate();
  const theme = useDevExTheme();

  return (
    <DevExPageLayout css={styles.pageHeader}>
      <Flex vertical gap={theme.variable.spacing.sm}>
        <Flex justify="space-between" align="center" gap={theme.variable.spacing.md}>
          <Flex
            vertical
            gap={theme.variable.spacing.sm}
            justify="center"
            style={{
              minHeight: 40, // same height as a large button
            }}
          >
            {!!props.title ? (
              <DevExText fontSize="xl" fontFamily="secondary" strong>
                {props.title}
              </DevExText>
            ) : (
              <DevExSkeleton width={240} fontSize="xl" noLineHeight />
            )}
            {!!props.subtitle && (
              <DevExText type="secondary" fontSize="md" lined>
                {props.subtitle}
              </DevExText>
            )}
          </Flex>
          <Flex gap={theme.variable.spacing.md}>{props.extra}</Flex>
        </Flex>
        {props.tabs && (
          <DevExTabs
            className="pageHeader__tabs"
            items={props.tabs}
            activeKey={props.tabKey}
            onChange={(tabKey) => {
              const href = props.tabs?.find((tab) => tab.key === tabKey)?.href;
              href && navigate(href);
            }}
            loading={!props.title}
            tabBarExtraContent={props.tabBarExtraContent}
          />
        )}
      </Flex>
    </DevExPageLayout>
  );
};
