import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useParams } from 'react-router-dom';

import { StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CopyLinkInput } from 'jf/components/CopyLinkPopover';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExText } from 'jf/components/DevExText';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { makeStudyAnalyzerPath, SHARE_LINK_EXPIRY_DAYS } from './StudyAnalyzerPage';
import { TeamCascader } from './TeamCascader';

export const GenerateTeamShareLinkModal: React.FC = () => {
  const modal = useJFOverlay(GenerateTeamShareLinkModal);
  const { studyRef } = useParams<{ studyRef: string }>();

  const [teamRef, setTeamRef] = useState<string | undefined>();
  const [surveyLink, setSurveyLink] = useState<string>();
  const { mutateAsync: shareStudy, isLoading: isShareStudyLoading } = useClientMutation(
    StudyClient.shareStudy
  );

  useEffect(() => {
    generateLink();
  }, []);

  const theme = useDevExTheme();
  const generateLink = async (ref?: string) => {
    if (!studyRef) {
      return;
    }
    setTeamRef(ref);
    const response = await shareStudy({
      studyRef,
      requestBody: {
        teamRef: ref,
        expiryDays: SHARE_LINK_EXPIRY_DAYS,
      },
    });

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append('scope_token', response.scopeToken);

    setSurveyLink(
      `${window.location.origin}${makeStudyAnalyzerPath(studyRef, ref, 'insights', Object.fromEntries(queryParams))}`
    );
  };

  const onOk = () => {
    modal.close(true);
  };

  return (
    <DevExModal
      open={modal.isOpened}
      title="Share Results"
      footer={() => (
        <DevExButton type="primary" onClick={onOk}>
          Ok
        </DevExButton>
      )}
      onCancel={onOk}
      width={750}
    >
      <Flex vertical gap={theme.variable.spacing.md} align="flex-start">
        <DevExText type="primary">
          The link below will grant public access to this {teamRef ? 'team' : 'survey'}'s results to
          whomever you share it with.
        </DevExText>
        <DevExText type="secondary">It will expire in {SHARE_LINK_EXPIRY_DAYS} days.</DevExText>
        <Flex gap={theme.variable.spacing.md}>
          <TeamCascader
            studyRef={studyRef}
            placeholder="Company"
            onChange={(ref) => {
              setSurveyLink(undefined);
              generateLink(ref);
            }}
          />
          <CopyLinkInput link={surveyLink} loading={isShareStudyLoading} />
        </Flex>
      </Flex>
    </DevExModal>
  );
};
