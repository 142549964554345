export const useStudyEstimatedTime = (numPrompts: number) => {
  // This formula is based on the line of best fit from the study time analysis done in the following confluence folder:
  // https://jelly-ai.atlassian.net/wiki/spaces/DOC/pages/3839983633/Study+Response+Time+Analysis
  // This equation is based on data as of mid Jan 2025, could be interesting to do with more data in future
  // Note: Keep this logic in sync with estimated_completion_time_seconds on BE for consistent estimations
  const SECONDS_PER_QUESTION = 13;
  const BASE_SURVEY_SECONDS = 78;
  const totalSeconds = BASE_SURVEY_SECONDS + SECONDS_PER_QUESTION * numPrompts;
  const secondsToNearest10 = Math.round(totalSeconds / 10) * 10;
  const minutes = Math.floor(secondsToNearest10 / 60);
  const seconds = secondsToNearest10 % 60;

  return { minutes: minutes, seconds: seconds };
};
