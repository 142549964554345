import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExModal } from 'jf/components/DevExModal';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { makeStudyAnalyzerPath } from './StudyAnalyzerPage';
import { useStudyParticipation } from './useStudyParticipation';

type CloseStudyModalProps = {
  autorunHighlights: boolean;
};
export const CloseStudyModal: React.FC<CloseStudyModalProps> = (props) => {
  const modal = useJFOverlay(CloseStudyModal);
  const flags = useClientFlags();
  const { studyRef } = useParams<{ studyRef: string }>();
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const queryClient = useQueryClient();

  const [closing, setClosing] = useState(false);

  const { data: participation } = useStudyParticipation({ studyRef });

  const closeModal = () => {
    if (!closing) {
      trackEvent('survey-analyzer:close:cancel', { surveyRef: studyRef });
      modal.close();
    }
  };

  const onCancel = () => closeModal();

  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const onOk = () => {
    trackEvent('survey-analyzer:close:close', {
      surveyRef: studyRef,
      responseCount: participation?.uniqueResponses ?? 0,
    });
    setClosing(true);
    if (studyRef) {
      updateStudy({
        studyRef,
        requestBody: {
          status: Study.status.CLOSED,
        },
      })
        .then(() => {
          queryClient.invalidateQueries('GET_STUDIES');
          queryClient.invalidateQueries(['GET_STUDY', studyRef]).then(() => {
            modal.close(true);

            const shouldRunHighlights = props.autorunHighlights && flags.studyReveal;
            navigate(
              makeStudyAnalyzerPath(
                studyRef,
                undefined,
                shouldRunHighlights ? 'reveal' : 'insights'
              )
            );
          });
          queryClient.removeQueries(['GET_PUBLIC_STUDY', studyRef]);
        })
        .catch(() => setClosing(false));
    }
  };

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      title="Are you ready to close your survey?"
      footer={
        <>
          <DevExButton onClick={onCancel} disabled={closing}>
            No, keep it open
          </DevExButton>
          <DevExButton type="primary" onClick={onOk} loading={closing}>
            {closing ? 'Closing' : 'Yes, close it'}
          </DevExButton>
        </>
      }
      width={500}
      closable={!closing}
    >
      <div style={{ fontSize: theme.variable.fontSize.md }}>
        Once your survey is closed, participants won't be able to take it anymore, and you can't
        reopen it.
        <br />
        <br />
        If you're ready, close it now and stay tuned! Your results will be ready in a few seconds.
      </div>
    </DevExModal>
  );
};
