import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DevExButton } from './DevExButton';
import { DevExInput } from './DevExInput';

type DevExSearchProps = {
  placeholder?: string;
  collapsible?: boolean;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  outline?: boolean;
};

export const DevExSearch: React.FC<DevExSearchProps> = (props) => {
  const [searchVisibility, setSearchVisibility] = useState(props.collapsible ? false : null);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (props.collapsible) {
      inputRef.current?.focus();
    }
  }, [searchVisibility]);

  useEffect(() => {
    if (props.collapsible && props.searchQuery === '') {
      setSearchVisibility(false);
    }
  }, [props.searchQuery]);

  if (!props.collapsible) {
    return (
      <DevExInput
        ref={inputRef}
        addonBefore={<FontAwesomeIcon icon={faSearch} />}
        placeholder={props.placeholder}
        value={props.searchQuery}
        onChange={props.setSearchQuery}
        style={{ width: 250 }}
        allowClear
      />
    );
  }
  if (searchVisibility) {
    return (
      <DevExInput
        ref={inputRef}
        addonBefore={
          <FontAwesomeIcon icon={faSearch} onClick={() => setSearchVisibility(!searchVisibility)} />
        }
        placeholder={props.placeholder}
        value={props.searchQuery}
        onChange={props.setSearchQuery}
        style={{ width: 250 }}
        allowClear
      />
    );
  }
  // the icon button is shorter than the full input, causing an issue with layout shifting
  // making the button large makes it a circle
  return (
    <DevExButton
      onClick={() => setSearchVisibility(!searchVisibility)}
      icon={<FontAwesomeIcon icon={faSearch} />}
      size="large"
      type={props.outline ? 'outline' : 'default'}
    />
  );
};
