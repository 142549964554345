import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Flex, Pagination } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faSearch, faTrash, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, sortBy } from 'lodash-es';
import { css } from 'styled-components';

import { SlackTargetingClient, StudySlackTarget } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExDrawer } from 'jf/components/DevExDrawer';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSearch } from 'jf/components/DevExSearch';
import { DevExSelect } from 'jf/components/DevExSelect';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';
import { useLivePropState } from 'jf/utils/useLivePropState';

import { SlackEntityDisplay } from './SlackEntityDisplay';

type SlackTargetDrawerProps = {
  open: boolean;
  onClose: () => void;
  slackTargets: StudySlackTarget[];
  onChange: (slackTargets: StudySlackTarget[]) => void;
  onDuplicateChange: (dupes: number) => void;
};

const styles = {
  select: css`
      &&&&.ant-select {
        min-width: 520px;
        &.ant-select-lg {
        .ant-select-selector {
          border-radius: 5px;
        }
    }
    `,
};

const SHOW_COUNT = 10;
export const SlackTargetDrawer: React.FC<SlackTargetDrawerProps> = (props) => {
  const theme = useDevExTheme();

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * SHOW_COUNT;
  const endIndex = startIndex + SHOW_COUNT;

  const [slackTargets, setSlackTargets] = useLivePropState(props.slackTargets, props.onChange);
  const [searchQuery, setSearchQuery] = useState('');
  const [existingTargetSearchQuery, setExistingTargetSearchQuery] = useState('');

  const [users, setUsers] = useState<StudySlackTarget[]>([]);

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  const { data: slackEntities, isFetching } = useClientQuery(
    SlackTargetingClient.getSlackEntities,
    { filter: debouncedSearchQuery },
    { enabled: debouncedSearchQuery.length >= 1 }
  );

  const { mutateAsync: expandSlackEntity } = useClientMutation(
    SlackTargetingClient.expandSlackEntity
  );

  const debounceFiredSearchQuery = useMemo(() => debounce(setDebouncedSearchQuery, 300), []);
  useEffect(() => {
    if (searchQuery) {
      debounceFiredSearchQuery(searchQuery);
    } else {
      debounceFiredSearchQuery.cancel();
      setDebouncedSearchQuery('');
    }
  }, [searchQuery]);

  const isSearching = searchQuery !== debouncedSearchQuery || isFetching;

  useEffect(() => {
    if (users.length === 0) {
      setCurrentPage(1);
    }
  }, [users]);

  const slackEntityOptions = useMemo(() => {
    return sortBy(slackEntities, (entity) => entity.name.toLowerCase()).map((entity) => ({
      value: entity.id,
      label: (
        <SlackEntityDisplay
          image={entity.image}
          displayName={entity.displayName}
          name={entity.name}
          type={entity.type}
        />
      ),
    }));
  }, [slackEntities]);

  const sortedSlackTargets = sortBy(users, (target) => target.displayName?.toLowerCase());

  const filteredResponses = useMemo(() => {
    const results = sortedSlackTargets?.filter(
      (studySlackTarget) =>
        (studySlackTarget?.displayName &&
          studySlackTarget.displayName
            .toLowerCase()
            .includes(existingTargetSearchQuery.toLowerCase())) ||
        (studySlackTarget?.name &&
          studySlackTarget.name.toLowerCase().includes(existingTargetSearchQuery.toLowerCase()))
    );
    return results;
  }, [sortedSlackTargets, existingTargetSearchQuery, searchQuery]);

  const filteredResponsesByPage = filteredResponses.slice(startIndex, endIndex);

  return (
    <DevExDrawer
      title="Add people from Slack"
      open={props.open}
      onClose={() => {
        setUsers([]);
        props.onClose();
        setCurrentPage(1);
      }}
      width={640}
      closable
      extra={
        <Flex gap={theme.variable.spacing.sm}>
          <DevExButton
            onClick={() => {
              setUsers([]);
              props.onClose();
            }}
          >
            Cancel
          </DevExButton>
          <DevExButton
            type="primary"
            onClick={() => {
              props.onDuplicateChange(
                users.filter((user) => slackTargets.some((target) => target.id === user.id)).length
              );
              setSlackTargets([
                ...users.filter((user) => !slackTargets.some((target) => target.id === user.id)),
                ...slackTargets,
              ]);
              setUsers([]);
              props.onClose();
            }}
            disabled={users?.length === 0 ? true : false}
          >
            Add
          </DevExButton>
        </Flex>
      }
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <div>Add users by searching in Slack</div>
        <Flex justify="space-between" align="center">
          <DevExSelect
            css={styles.select}
            showSearch
            filterOption={() => true} // leave filtering to the BE only
            options={!isSearching ? slackEntityOptions : undefined}
            value={[]}
            onChange={(value) => {
              const entity = slackEntities?.find((entity) => entity.id === value);
              if (entity) {
                expandSlackEntity({
                  requestBody: {
                    entityId: entity.id,
                    entityType: entity.type,
                  },
                }).then((newSlackTargets) => {
                  setUsers((users) => {
                    return [
                      ...users,
                      // filter out duplicates before adding
                      ...newSlackTargets.filter(
                        (newTarget) => !users.some((target) => target.id === newTarget.id)
                      ),
                    ];
                  });
                });
              }
              setSearchQuery('');
            }}
            placeholder="Search for @users, @groups, and #channels"
            loading={isSearching}
            suffixIcon={<FontAwesomeIcon icon={faSearch} />}
            notFoundContent={
              isSearching ? (
                <DevExLoader tip="Searching Slack..." />
              ) : (
                <DevExEmpty
                  icon={faSlack}
                  label={
                    searchQuery
                      ? `No slack targets found matching "${searchQuery}".`
                      : 'Search for @users, @groups, and #channels in your Slack instance.'
                  }
                />
              )
            }
            onSearch={setSearchQuery}
          />
        </Flex>
        <Divider style={{ margin: theme.variable.spacing.sm }} />
        <Flex align="center" justify="space-between">
          <div style={{ color: theme.color.text.secondary }}>
            {filteredResponses?.length || 0} Slack user{filteredResponses?.length !== 1 ? 's' : ''}{' '}
            found
          </div>
          <DevExSearch
            searchQuery={existingTargetSearchQuery}
            setSearchQuery={setExistingTargetSearchQuery}
            collapsible
            outline
          />
        </Flex>
        <Flex vertical>
          <Flex vertical gap={theme.variable.spacing.sm}>
            {filteredResponses.length === 0 && (
              <DevExCard
                style={{
                  padding: theme.variable.spacing.lg,
                }}
              >
                <Flex vertical align="center" gap={theme.variable.spacing.lg}>
                  <DevExEmpty icon={faUser} iconSize={34} />
                  <div style={{ textAlign: 'center', color: theme.color.text.secondary }}>
                    Start adding people from Slack by searching for them.
                  </div>
                </Flex>
              </DevExCard>
            )}
            {filteredResponsesByPage.map((target) => {
              return (
                <Flex
                  gap={theme.variable.spacing.xxl}
                  align="center"
                  justify="space-between"
                  key={target.id}
                  style={{
                    padding: theme.variable.spacing.sm,
                    border: `1px solid`,
                    borderRadius: theme.variable.borderRadius,
                    borderColor: theme.color.border.primary,
                  }}
                >
                  <SlackEntityDisplay
                    image={target.image}
                    displayName={target.displayName}
                    name={target.name}
                  />

                  <DevExButton
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => {
                      setUsers((users) => users.filter(({ id }) => id !== target.id));
                    }}
                  />
                </Flex>
              );
            })}
            <Pagination
              current={currentPage}
              hideOnSinglePage
              showSizeChanger={false}
              total={sortedSlackTargets?.length}
              pageSize={10}
              onChange={(page) => {
                setCurrentPage(page);
              }}
              style={{ alignSelf: 'center' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </DevExDrawer>
  );
};
