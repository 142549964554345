import React from 'react';
import { Flex } from 'antd';
import { faArrowUpRightFromSquare, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faClipboard } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { DevExThemeProvider, useDevExTheme } from 'jf/common/themes/DevExTheme';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { useInferredClick } from 'jf/utils/useInferredClick';

const styles = {
  onboardingBanner: css`
    position: relative;
    padding: ${(props) => props.theme.variable.spacing.md};
    padding-bottom: 0;

    .onboardingBanner__backdrop {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background: ${(props) => props.theme.color.banner.background};
      border-radius: ${(props) => props.theme.variable.borderRadius};
      height: ${(props) => `calc(50% + ${props.theme.variable.spacing.md})`};
      width: 100%;
    }

    .onboardingBanner__dismiss {
      position: absolute;
      right: ${(props) => props.theme.variable.spacing.sm};
      top: ${(props) => props.theme.variable.spacing.sm};
    }

    .onboardingBanner__title {
      color: ${(props) => props.theme.color.banner.text};
      font-size: ${(props) => props.theme.variable.fontSize.lg};
      font-weight: 600;
    }

    .onboardingBanner__steps {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: ${(props) => props.theme.variable.spacing.md};

      .steps__step {
        .step__title {
          font-size: ${(props) => props.theme.variable.fontSize.md};
          font-weight: 600;
        }

        &.step--tour {
          .step__video {
            // these styles are a hack for making the iframe responsively sized
            // https://stackoverflow.com/a/32619329/14250695
            position: relative;
            width: 100%;
            height: 100%;
            padding-bottom: 56.25%; // 16:9 ratio
            border: 1px solid ${(props) => props.theme.color.border.secondary};
            border-radius: ${(props) => props.theme.variable.borderRadius};

            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: ${(props) => props.theme.variable.borderRadius};
              border: none;
            }
          }
        }

        &.step--demo {
          .step__content {
            position: relative;
            cursor: pointer;
            border: 1px solid ${(props) => props.theme.color.border.secondary};
            border-radius: ${(props) => props.theme.variable.borderRadius};

            img {
              width: 100%;
              border-radius: ${(props) => props.theme.variable.borderRadius};
              filter: blur(0.5px);
              transition: filter 150ms ease;
            }

            button {
              pointer-events: none;
              position: absolute;
              background: rgba(46, 46, 46, 0.75);
              backdrop-filter: blur(1px);
              transition:
                background 150ms ease,
                color 150ms ease;
            }

            &:hover {
              img {
                filter: blur(0.5px) brightness(80%);
              }

              button {
                background: rgba(46, 46, 46, 1);
                color: white;
              }
            }
          }
        }

        &.step--cta {
          .step__content {
            text-align: center;
            color: ${(props) => props.theme.color.text.secondary};
            line-height: ${(props) => props.theme.variable.lineHeight};
          }
        }
      }
    }
  `,
};

type OnboardingBannerProps = {
  onDismiss: () => void;
};

export const OnboardingBanner: React.FC<OnboardingBannerProps> = (props) => {
  const theme = useDevExTheme();
  const navigate = useNavigate();

  const iframeInferredClick = useInferredClick(() => {
    trackEvent('survey-list:onboarding-banner:watch-video');
  });

  return (
    <Flex vertical gap={theme.variable.spacing.md} css={styles.onboardingBanner}>
      <DevExThemeProvider themeKey="dark">
        <DevExButton
          className="onboardingBanner__dismiss"
          icon={<FontAwesomeIcon icon={faXmark} />}
          type="text"
          onClick={() => props.onDismiss()}
        />
      </DevExThemeProvider>
      <div className="onboardingBanner__title">Get started with Jellyfish DevEx</div>
      <div className="onboardingBanner__steps">
        <DevExCard className="steps__step step--tour">
          <div className="step__title">Take a quick tour</div>
          <div className="step__video">
            <iframe
              title="DevEx Tour Video"
              src="//play.vidyard.com/2Ewt32L7sZdLNKUr49LUk3.html?"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              {...iframeInferredClick}
            />
          </div>
        </DevExCard>
        <DevExCard className="steps__step step--demo">
          <div className="step__title">Explore example survey results</div>
          <Flex
            justify="center"
            align="center"
            className="step__content"
            onClick={() => {
              trackEvent('survey-list:onboarding-banner:view-demo');
              navigate(
                '/study/00000000-0000-0000-0000-000000000003/analyze/insights?scope_token=DEMO'
              );
            }}
          >
            <img src="/static/img/devex-demo.png" alt="Example DevEx survey" />
            <DevExButton
              type="primary"
              size="large"
              icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              iconPosition="end"
            >
              Explore example
            </DevExButton>
          </Flex>
        </DevExCard>
        <DevExCard className="steps__step step--cta">
          <div className="step__title">Create your first survey</div>
          <Flex vertical align="center" gap={theme.variable.spacing.md} className="step__content">
            <Flex vertical align="center" gap={theme.variable.spacing.sm}>
              <CircledFontAwesomeIcon icon={faClipboard} iconSize={32} />
              <div>Click the button below to start your Developer Experience journey!</div>
            </Flex>

            <DevExButton
              type="primary"
              onClick={() => {
                trackEvent('survey-list:onboarding-banner:create-survey');
                navigate(`/study/build`);
              }}
              adminRequired
            >
              Create survey
            </DevExButton>
          </Flex>
        </DevExCard>
      </div>
      <div className="onboardingBanner__backdrop" />
    </Flex>
  );
};
