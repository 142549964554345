import React from 'react';
import { Flex } from 'antd';
import { faArrowRightArrowLeft, faCircle, faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

import { SlackTargetingClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExText } from 'jf/components/DevExText';
import { ReleaseTag } from 'jf/components/ReleaseTag';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';

import { SlackTargetEditor } from './SlackTargetEditor';

export const TargetRespondentStep: React.FC = () => {
  const theme = useDevExTheme();
  const location = useLocation();
  const state = useStudyBuilderState();
  const { data: slackStatus } = useClientQuery(SlackTargetingClient.getSlackStatus);

  if (!slackStatus) {
    return <DevExLoader />;
  }

  return (
    <Flex
      vertical
      style={{ fontSize: theme.variable.fontSize.md, lineHeight: theme.variable.lineHeight }}
      gap={theme.variable.spacing.lg}
    >
      <div style={{ fontSize: theme.variable.fontSize.xl }}>Target Respondents</div>
      <div>Choose how you want to distribute this survey after publishing.</div>

      <DevExCard
        onClick={
          state.slackTargetingEnabled
            ? () => state.update({ slackTargetingEnabled: false })
            : undefined
        }
        title={
          <>
            <FontAwesomeIcon
              icon={!state.slackTargetingEnabled ? faCircleDot : faCircle}
              color={theme.color.brand.active}
            />
            I'll share the link myself
          </>
        }
      >
        <DevExText type="secondary" fontSize="md">
          Select this option to manage your survey's distribution yourself.
        </DevExText>
      </DevExCard>
      <DevExCard
        onClick={
          !state.slackTargetingEnabled
            ? () => state.update({ slackTargetingEnabled: true })
            : undefined
        }
        title={
          <>
            <FontAwesomeIcon
              icon={state.slackTargetingEnabled ? faCircleDot : faCircle}
              color={theme.color.brand.active}
            />
            Distribute in Slack
          </>
        }
        extra={<ReleaseTag stage="ALPHA" />}
      >
        {state.slackTargetingEnabled ? (
          !slackStatus?.connected ? (
            <Flex justify="center">
              <Flex vertical align="center" gap={theme.variable.spacing.md}>
                <CircledFontAwesomeIcon
                  icon={faArrowRightArrowLeft}
                  style={{ color: theme.color.text.tertiary }}
                />
                <span>Let's set up your Slack integration first!</span>
                <DevExButton
                  type="primary"
                  href={`${window.location.origin}${window.dx.user?.company.slackInstallUrl}?state=${location.pathname}`}
                >
                  Connect
                </DevExButton>
              </Flex>
            </Flex>
          ) : (
            <>
              <DevExText type="secondary" fontSize="md">
                Select this option to send the survey as a Slack direct message to each respondent
              </DevExText>
              <SlackTargetEditor
                size="large"
                slackTargets={state.slackTargets}
                onChange={(slackTargets) => state.update({ slackTargets })}
                eventCategory="survey-editor"
              />
            </>
          )
        ) : (
          <DevExText type="secondary" fontSize="md" lined>
            Our Slack bot handles your survey distribution seamlessly. Key benefits:
            <ul style={{ margin: 0 }}>
              <li>Ensures an accurate response rate</li>
              <li>Sends reminders only to those who haven't responded yet</li>
            </ul>
          </DevExText>
        )}
      </DevExCard>
    </Flex>
  );
};
