import React from 'react';
import { Flex } from 'antd';
import { faFrown, faLaugh, faMeh, faSmile, faTired } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExToggleButton } from 'jf/components/DevExToggleButton';
import { DEFAULT_LIKERT_LABELS } from 'jf/pages/studyBuilder/customizeStudy/CustomizablePrompt';
import { useLivePropState } from 'jf/utils/useLivePropState';

const LIKERT_SCORES = [1, 2, 3, 4, 5];
export const LIKERT_ICONS = [faTired, faFrown, faMeh, faSmile, faLaugh];
const LIKERT_LABELS = ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'];

interface LikertScaleProps {
  value?: number;
  onChange?: (score: number) => void;
  disabled?: boolean;
  numeric?: boolean;
  polarity: number;
  choiceLabels?: string[];
  editing?: boolean; // tells the component if it's in edit mode or not
}

export const LikertScale: React.FC<LikertScaleProps> = (props) => {
  const theme = useDevExTheme();

  const [selectedScore, setSelectedScore] = useLivePropState(props.value, props.onChange);

  const negativePolarity = props.polarity < 0;

  const polarizeIndex = (index: number) => (negativePolarity ? 4 - index : index);

  return (
    <Flex vertical align="stretch" gap={theme.variable.spacing.sm}>
      <Flex gap="2.5%" style={{ height: 'min(72px, 14vw)' }}>
        {LIKERT_LABELS.map((label, i) => {
          const score = LIKERT_SCORES[polarizeIndex(i)];
          const icon = LIKERT_ICONS[polarizeIndex(i)];

          return (
            <DevExToggleButton
              key={score}
              toggled={score === selectedScore}
              onClick={() => setSelectedScore(score)}
              disabled={props.disabled}
              style={{ opacity: props.disabled ? theme.variable.opacity.disabled : 1 }}
            >
              {props.numeric ? <>{score}</> : <FontAwesomeIcon icon={icon} />}
            </DevExToggleButton>
          );
        })}
      </Flex>
      {props.numeric ? (
        <>
          {/* if we're on the edit phase, we have inputs in the parent component that represent the 
          labels. this component is used for both the editing state and the rendered state, so we hide 
          these labels in favor of the inputs in the parent */}
          {!props.editing && (
            <Flex
              justify="space-between"
              style={{ fontSize: theme.variable.fontSize.sm, color: theme.color.text.secondary }}
            >
              {props.choiceLabels && props.choiceLabels.length > 1 ? (
                <>
                  <span style={{ width: '25%' }} key={props.choiceLabels[0]}>
                    {props.choiceLabels[0]}
                  </span>
                  <span
                    style={{ width: '25%', textAlign: 'right' }}
                    key={props.choiceLabels[props.choiceLabels.length - 1]}
                  >
                    {props.choiceLabels[props.choiceLabels.length - 1]}
                  </span>
                </>
              ) : (
                <>
                  <span>{DEFAULT_LIKERT_LABELS[0]}</span>
                  <span>{DEFAULT_LIKERT_LABELS[1]}</span>
                </>
              )}
            </Flex>
          )}
        </>
      ) : (
        <Flex
          justify="space-between"
          style={{ fontSize: theme.variable.fontSize.sm, color: theme.color.text.secondary }}
        >
          {/* just doing space between doesnt alter based on width of text, making `neutral` offset. 
              so we have to specify widths and align text.
              20% is 1/5 so the text wrapping matches the size of the buttons above them */}
          <span style={{ width: '20%', textAlign: 'left' }}>{LIKERT_LABELS[0]}</span>
          <span style={{ width: '60%', textAlign: 'center' }}>{LIKERT_LABELS[2]}</span>
          <span style={{ width: '20%', textAlign: 'right' }}>{LIKERT_LABELS[4]}</span>
        </Flex>
      )}
    </Flex>
  );
};
