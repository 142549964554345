import React from 'react';
import { Flex, TooltipProps } from 'antd';

import { useDevExTheme } from '../common/themes/DevExTheme';

import { DevExButton } from './DevExButton';
import { DevExInput } from './DevExInput';
import { DevExPopover } from './DevExPopover';
import { DevExText } from './DevExText';

type CopyLinkProps = {
  description?: string;
  link?: string;
  loading?: boolean;
  onCopy?: () => void;
};

type CopyLinkPopoverProps = CopyLinkProps & {
  title: string;
  placement?: TooltipProps['placement'];
};

export const CopyLinkPopover: React.FC<CopyLinkPopoverProps> = (props) => {
  return (
    <DevExPopover
      content={<CopyLinkInput {...props} />}
      title={props.title}
      trigger="click"
      placement={props.placement}
    >
      {props.children}
    </DevExPopover>
  );
};

export const CopyLinkInput: React.FC<CopyLinkProps> = (props) => {
  const theme = useDevExTheme();
  return (
    <Flex vertical gap={theme.variable.spacing.sm} style={{ width: 420 }}>
      <Flex gap={theme.variable.spacing.sm}>
        <DevExInput
          value={props.link}
          disabled={!props.link || props.loading}
          placeholder="Retrieving link..."
        />
        <DevExButton
          disabled={!props.link || props.loading}
          loading={props.loading}
          type="primary"
          copyValue={props.link}
          onClick={props.onCopy}
        >
          Copy
        </DevExButton>
      </Flex>
      {!!props.description && (
        <DevExText type="secondary" lined>
          {props.description}
        </DevExText>
      )}
    </Flex>
  );
};
