import React, { createContext, useContext, useMemo } from 'react';
import { ConfigProvider as AntThemeProvider, ThemeConfig as AntThemeConfig } from 'antd';
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components';

import { DevExEnv, getDevExEnv } from 'jf/utils/getDevExEnv';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';

import { DARK_THEME } from './definitions/darkTheme';
import { LIGHT_THEME } from './definitions/lightTheme';
import { THEME_VARIABLES } from './definitions/themeVariables';
import { resolveThemeColors } from './resolveTheme';

export type DevExThemeColors = typeof LIGHT_THEME;

export type DevExThemeVariables = typeof THEME_VARIABLES;

export type DevExTheme = DevExThemeColors & DevExThemeVariables;

export const DEVEX_THEMES_COLORS = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
};

export type ThemeKey = keyof typeof DEVEX_THEMES_COLORS;

const defaultThemeKey: ThemeKey = (() => {
  const isAtlassian = getDevExEnv().key === DevExEnv.ATLASSIAN;
  const isStorybook = !!process.env.STORYBOOK;

  if (!isAtlassian && !isStorybook && window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
  }

  return 'light';
})();

interface DETC extends DevExTheme {
  key: ThemeKey;
  update: (themeKey: ThemeKey) => void;
}

const DevExThemeContext = createContext<DETC>({
  ...DEVEX_THEMES_COLORS[defaultThemeKey],
  ...THEME_VARIABLES,
  key: defaultThemeKey,
  update: () => {},
});

export const useDevExTheme = () => useContext(DevExThemeContext);

type DevExThemeProviderProps = {
  themeKey?: ThemeKey;
};

export const DevExThemeProvider: React.FC<DevExThemeProviderProps> = (props) => {
  const [storedThemeKey, setThemeKey] = useLocalStorage<ThemeKey>(
    'devex_theme_key',
    defaultThemeKey
  );

  const themeKey = props.themeKey ?? storedThemeKey;

  const themeColors = resolveThemeColors(DEVEX_THEMES_COLORS[themeKey]);

  const theme = useMemo(
    () => ({
      ...themeColors,
      ...THEME_VARIABLES,
      key: themeKey,
      update: setThemeKey,
    }),
    [themeKey]
  );

  const antTheme: AntThemeConfig = {
    token: {
      fontSize: parseInt(theme.variable.fontSize.sm),
      fontFamily: theme.variable.fontFamily.primary,
      colorText: theme.color.text.primary,
      colorTextSecondary: theme.color.text.primary,
      colorTextTertiary: theme.color.text.secondary,
      colorTextQuaternary: theme.color.text.tertiary,
      colorBgContainer: theme.color.background.raised,
      colorBgElevated: theme.color.background.raised,
      colorBorder: theme.color.border.primary,
      colorBorderSecondary: theme.color.border.secondary,
      colorPrimary: theme.color.brand.default,
      colorPrimaryHover: theme.color.brand.default,
      controlOutline: 'transparent',
      controlItemBgHover: theme.color.background.hovered,
      controlItemBgActive: theme.color.background.active,
      boxShadow: `0 0 ${theme.variable.spacing.md} ${theme.color.shadow.default}`,
      boxShadowSecondary: `0 0 ${theme.variable.spacing.md} ${theme.color.shadow.default}`,
    },
  };

  // in situations where AntD does not provide tokens to customize colors, we will override via css here
  const AntOverrideStyles = createGlobalStyle`
    .ant-notification {
      .ant-notification-notice-wrapper {
        background: ${theme.color.background.default};
        border-radius: ${theme.variable.borderRadius};
        // this fixes a weird flashing artifact https://github.com/ant-design/ant-design/issues/48608#issuecomment-2152404334
        transition: transform 0.3s, backdrop-filter 0s !important;

        .ant-notification-notice {
          width: 500px;
          border-radius: ${theme.variable.borderRadius};

          .ant-notification-notice-message {
            font-size: ${theme.variable.fontSize.sm};
            margin-bottom: 0;
          }

          &:has(.ant-notification-notice-icon-error) {
            background: ${theme.color.status.error.background};
            border: 1px solid ${theme.color.status.error.border};

            .ant-notification-notice-icon {
              color: ${theme.color.status.error.text};
            }
          }
        }
      }
    }
  `;

  return (
    <DevExThemeContext.Provider value={theme}>
      <StyledThemeProvider theme={theme as DefaultTheme}>
        <AntThemeProvider theme={antTheme}>
          <AntOverrideStyles />
          {props.children}
        </AntThemeProvider>
      </StyledThemeProvider>
    </DevExThemeContext.Provider>
  );
};
