import { differenceBy } from 'lodash-es';
import { useQueryClient } from 'react-query';

import { StudyClient, StudyTeam } from 'jf/api';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

export const useStudyTeamsUpdater = (studyRef: string | undefined) => {
  const queryClient = useQueryClient();

  const { data: studyTeams } = useClientQuery(StudyClient.getStudyTeams, {
    studyRef,
  });

  const { mutateAsync: createStudyTeam } = useClientMutation(StudyClient.createStudyTeam);
  const { mutateAsync: deleteStudyTeams } = useClientMutation(StudyClient.deleteStudyTeams);

  const update = async (newStudyTeams: StudyTeam[]) => {
    if (!studyTeams) {
      return;
    }

    const queries: Promise<any>[] = [];

    // create new study teams
    const studyTeamsToCreate = differenceBy(newStudyTeams, studyTeams, 'teamRef');
    for (const studyTeam of studyTeamsToCreate) {
      queries.push(
        createStudyTeam({
          studyRef: studyRef!,
          requestBody: studyTeam,
        })
      );
    }

    // delete old study teams
    const studyTeamsToDelete = differenceBy(studyTeams, newStudyTeams, 'teamRef');
    if (studyTeamsToDelete.length) {
      queries.push(
        deleteStudyTeams({
          studyRef: studyRef!,
          teamRef: studyTeamsToDelete.map((studyTeam) => studyTeam.teamRef),
        })
      );
    }

    if (queries.length) {
      return Promise.allSettled(queries).then(() =>
        queryClient.refetchQueries(['GET_STUDY_TEAMS', studyRef])
      );
    }
  };

  return { update };
};
