import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { faPen, faQuestionCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faRightLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, keyBy } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { CreatedCustomerPromptRequest, Prompt, StudyClient, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExText } from 'jf/components/DevExText';
import { DevExTextArea } from 'jf/components/DevExTextArea';
import { DevExToggleButton } from 'jf/components/DevExToggleButton';
import { LikertScale } from 'jf/pages/study/question/LikertScale';
import { QuestionCard } from 'jf/pages/study/question/QuestionCard';
import { QuestionCardPrompt } from 'jf/pages/study/question/QuestionCardPrompt';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';
import { useStudyBuilderSteps } from '../useStudyBuilderSteps';
import { useStudyUpdater } from '../useStudyUpdater';

import { OpenTextQuestionModal } from './OpenTextQuestionModal';
import { PromptIcon } from './PromptIcon';
import { TopicSelect } from './TopicSelect';

export const DEFAULT_LIKERT_LABELS = ['Not at all', 'Very'];

const styles = {
  topicSelect: css`
    &&&&.ant-select {
      margin-bottom: ${({ theme }) => theme.variable.spacing.sm};

      .ant-select-selector {
        border-radius: 20px;
        border-color: transparent;
        background-color: ${(props) => props.theme.color.tag.default.background};

        .ant-select-selection-item,
        .ant-select-selection-search input {
          color: ${(props) => props.theme.color.tag.default.text};
        }
      }
    }
  `,
};

const UNEDITIBALE_CANONICAL_QUESTION_TOOLTIP =
  'This is a template question that cannot be edited. If there is not a template question that fits your needs, a custom question can be created.';
const UNEDITIBALE_CUSTOMER_QUESTION_TOOLTIP =
  'This is a custom question that has been used in a prior survey and can no longer be edited. This ensures custom question scores can be compared across surveys.';

type CustomizablePromptProps = {
  promptSlug: string;
};

export const CustomizablePrompt: React.FC<CustomizablePromptProps> = (props) => {
  const state = useStudyBuilderState();

  const [isEditing, setIsEditing] = useState(false);

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const promptsBySlug = useMemo(() => (prompts ? keyBy(prompts, 'slug') : undefined), [prompts]);
  const prompt = promptsBySlug?.[props.promptSlug];

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    setIsEditing(false);
    if (props.promptSlug === 'UNSAVED') {
      state.update({
        promptSlugs: state.promptSlugs.slice(0, -1),
        studyStep: { type: 'INTRO' },
      });
    }
  };

  if (isEditing || props.promptSlug === 'UNSAVED') {
    return <CustomizablePromptEdit prompt={prompt} onCancel={onCancel} />;
  } else if (prompt) {
    return <CustomizablePromptNonEdit prompt={prompt} onEdit={onEdit} />;
  }

  return null;
};

const CustomizablePromptNonEdit: React.FC<{ prompt: Prompt; onEdit: () => void }> = (props) => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);

  const studySteps = useStudyBuilderSteps();
  const studyStepIndex = studySteps.findIndex((step) => isEqual(step, state.studyStep));

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const topicsBySlug = useMemo(() => (topics ? keyBy(topics, 'slug') : undefined), [topics]);
  const topic = topicsBySlug?.[props.prompt.topicSlug];

  const onRemove = () => {
    const newPromptSlugs = state.promptSlugs.filter((slug) => slug !== props.prompt.slug);
    studyUpdater.update({ sequencedPrompts: newPromptSlugs });
    state.update({
      promptSlugs: newPromptSlugs,
      studyStep: studySteps[studyStepIndex - 1],
    });
  };

  const isEditable = props.prompt.scope === Prompt.scope.CUSTOMER && !props.prompt.frozen;
  const stepNumber = state.promptSlugs.findIndex((slug) => slug === props.prompt.slug) + 1;

  if (!topic) {
    return null;
  }

  return (
    <Flex vertical align="end" gap={theme.variable.spacing.md}>
      <Flex gap={theme.variable.spacing.sm}>
        <DevExButton
          key={`remove-${props.prompt.slug}`}
          icon={<FontAwesomeIcon icon={faTrash} />}
          onClick={onRemove}
        >
          Remove
        </DevExButton>

        <Tooltip
          title={
            !isEditable
              ? props.prompt.scope === Prompt.scope.CUSTOMER
                ? UNEDITIBALE_CUSTOMER_QUESTION_TOOLTIP
                : UNEDITIBALE_CANONICAL_QUESTION_TOOLTIP
              : ''
          }
        >
          <div>
            <DevExButton
              type="outline"
              icon={<FontAwesomeIcon icon={faPen} />}
              onClick={props.onEdit}
              disabled={!isEditable}
            >
              Edit
            </DevExButton>
          </div>
        </Tooltip>
      </Flex>

      <QuestionCardPrompt
        key={props.prompt.slug}
        number={stepNumber}
        prompt={props.prompt}
        tag={topic.label}
        validated
        disabled
      />
    </Flex>
  );
};

const CustomizablePromptEdit: React.FC<{ prompt: Prompt | undefined; onCancel: () => void }> = (
  props
) => {
  const flags = useClientFlags();

  const theme = useDevExTheme();
  const queryClient = useQueryClient();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);
  const openTextQuestionModal = useJFOverlay(OpenTextQuestionModal);

  const [promptRequest, setPromptRequest] = useState<CreatedCustomerPromptRequest>();
  const [savingStatus, setSavingStatus] = useState<'saving' | 'savingAsNew'>();

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const { mutateAsync: createCustomerPrompt } = useClientMutation(StudyClient.createCustomerPrompt);
  const { mutateAsync: updateCustomerPrompt } = useClientMutation(StudyClient.updateCustomerPrompt);

  const onSave = (saveAsNew = false) => {
    if (!promptRequest) {
      return;
    }

    setSavingStatus(saveAsNew ? 'savingAsNew' : 'saving');

    if (!props.prompt || saveAsNew) {
      // Track custom question creation, by prompt type
      // pass entire prompt as context, and a new boolean value
      // to easily check if labels are updated from the default.
      // saves us having magic strings in grafana
      trackEvent(`survey-editor:custom-question-create:${promptRequest.type}`, {
        areLabelsCustom:
          promptRequest.type === Prompt.type.RATING_5N &&
          promptRequest.choiceLabels &&
          (promptRequest.choiceLabels[0] !== DEFAULT_LIKERT_LABELS[0] ||
            promptRequest.choiceLabels[promptRequest.choiceLabels.length] ===
              DEFAULT_LIKERT_LABELS[1]),
        ...promptRequest,
      });
      createCustomerPrompt({ requestBody: promptRequest })
        .then(({ slug }) => {
          queryClient.refetchQueries('GET_PROMPTS').then(() => {
            // add new prompt to study
            const newPromptSlugs = saveAsNew
              ? [...state.promptSlugs, slug]
              : [...state.promptSlugs.slice(0, -1), slug]; // replace "UNSAVED" with newly created slug
            studyUpdater.update({ sequencedPrompts: newPromptSlugs });
            state.update({
              promptSlugs: newPromptSlugs,
              studyStep: { type: 'PROMPT', key: slug },
            });
          });

          setSavingStatus(undefined);
          props.onCancel();
        })
        .catch(() => setSavingStatus(undefined));
    } else {
      updateCustomerPrompt({
        slug: props.prompt.slug,
        requestBody: promptRequest,
      })
        .then(() => {
          queryClient.invalidateQueries('GET_PROMPTS');
          setSavingStatus(undefined);
          props.onCancel();
        })
        .catch(() => setSavingStatus(undefined));
    }
  };

  useEffect(() => {
    setPromptRequest({
      text: props.prompt?.text ?? '',
      type: props.prompt?.type ?? Prompt.type.RATING_5L,
      topicSlug: props.prompt?.topicSlug,
      choiceLabels: props.prompt?.choiceLabels,
      polarity: props.prompt?.polarity,
    });
  }, [props.prompt]);

  const isSaving = !!savingStatus;

  const isPromptValid = useMemo(() => {
    if (!promptRequest) {
      return false;
    }
    const commonValidChecks = promptRequest.text && promptRequest.topicSlug && !isSaving;
    if (promptRequest.type === Prompt.type.RATING_5N) {
      return (
        commonValidChecks &&
        promptRequest.choiceLabels &&
        promptRequest.choiceLabels.length > 1 &&
        promptRequest.choiceLabels[0].length > 1 &&
        promptRequest.choiceLabels[promptRequest.choiceLabels.length - 1].length > 1
      );
    }
    return commonValidChecks;
  }, [promptRequest, isSaving]);

  if (!promptRequest) {
    return null;
  }

  const promptTypes = flags.openTextQuestion
    ? [Prompt.type.RATING_5L, Prompt.type.RATING_5N, Prompt.type.OPEN_END]
    : [Prompt.type.RATING_5L, Prompt.type.RATING_5N];

  return (
    <QuestionCard
      title={
        <DevExTextArea
          value={promptRequest.text}
          onChange={(value) => {
            setPromptRequest({ ...promptRequest, text: value });
          }}
          style={{ marginTop: theme.variable.spacing.md, fontSize: theme.variable.fontSize.lg }}
          placeholder="Ask your developers a custom question..."
          autoSize={{ minRows: 1, maxRows: 2 }}
          showCount
          maxLength={256} // enforced by the database
        />
      }
      validated
      actions={
        <>
          <DevExButton onClick={props.onCancel}>Cancel</DevExButton>
          {!!props.prompt && (
            <DevExButton
              type="outline"
              disabled={!isPromptValid}
              loading={savingStatus === 'savingAsNew'}
              onClick={() => onSave(true)}
            >
              Save as new
            </DevExButton>
          )}
          <DevExButton
            type="primary"
            disabled={!isPromptValid}
            loading={savingStatus === 'saving'}
            onClick={() => onSave()}
          >
            Save
          </DevExButton>
        </>
      }
      tag={
        <Flex>
          <TopicSelect
            value={promptRequest.topicSlug}
            onChange={(value) => {
              setPromptRequest({ ...promptRequest, topicSlug: value });
            }}
            topics={topics}
            css={styles.topicSelect}
          />
          *
        </Flex>
      }
    >
      <Flex
        vertical
        style={{ marginTop: theme.variable.spacing.lg, marginBottom: theme.variable.spacing.lg }}
        gap={theme.variable.spacing.sm}
      >
        <Flex vertical gap={theme.variable.spacing.sm}>
          <DevExText type="secondary">Question type</DevExText>
          <Flex gap={theme.variable.spacing.md}>
            {promptTypes.map((type) => (
              <DevExToggleButton
                key={type}
                toggled={promptRequest.type === type}
                onClick={() => {
                  // set this back to undefined when changing prompt types
                  // so that we dont carry polarity into other custom question types
                  delete promptRequest.polarity;
                  let choiceLabels: string[] = [];
                  if (type === Prompt.type.RATING_5N) {
                    // set default labels for custom questions
                    choiceLabels = [...DEFAULT_LIKERT_LABELS];
                  }
                  setPromptRequest({ ...promptRequest, choiceLabels, type });
                }}
                inline
              >
                <PromptIcon type={type} />
                {type === Prompt.type.RATING_5L && <span>Sentiment</span>}
                {type === Prompt.type.RATING_5N && <span>Rating</span>}
                {type === Prompt.type.OPEN_END && <span>Open-Ended</span>}
              </DevExToggleButton>
            ))}
          </Flex>
        </Flex>
        <Flex justify="flex-end">
          {promptRequest.type === Prompt.type.OPEN_END && (
            <Flex
              gap={theme.variable.spacing.sm}
              style={{ color: theme.color.text.secondary, cursor: 'pointer' }}
              onClick={() => openTextQuestionModal.open()}
            >
              What makes a great open-ended question?
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Flex>
          )}
        </Flex>
        <div style={{ paddingTop: theme.variable.spacing.md }}>
          {promptRequest.type === Prompt.type.RATING_5L && (
            <Flex justify="flex-end">
              <Button
                type="text"
                css={css`
                  margin-bottom: ${({ theme }) => theme.variable.spacing.sm};
                `}
                icon={
                  <FontAwesomeIcon
                    css={css`
                      transition: all 0.25s ease-in-out;
                      transform: scaleX(1);
                      &.flip-icon {
                        transform: scaleX(-1);
                      }
                    `}
                    className={promptRequest.polarity === -1 ? 'flip-icon' : ''}
                    icon={faRightLeft}
                  />
                }
                onClick={() => {
                  // undefined polarity is the same as positive
                  if (!promptRequest.polarity || promptRequest.polarity === 1) {
                    setPromptRequest({ ...promptRequest, polarity: -1 });
                  } else {
                    setPromptRequest({ ...promptRequest, polarity: 1 });
                  }
                }}
              >
                Reverse sentiment
              </Button>
            </Flex>
          )}
          {promptRequest.type === Prompt.type.OPEN_END ? (
            <DevExTextArea
              autoSize={{ minRows: 3 }}
              disabled
              placeholder="Respondents will input their answers here"
            />
          ) : (
            <Flex vertical gap={theme.variable.spacing.md}>
              <LikertScale
                polarity={promptRequest.polarity || 0}
                disabled
                numeric={promptRequest.type === Prompt.type.RATING_5N}
                choiceLabels={promptRequest.choiceLabels}
                editing
              />
              {promptRequest.type === Prompt.type.RATING_5N && promptRequest.choiceLabels && (
                <Flex justify="space-between">
                  {promptRequest.choiceLabels.map((l, i) => (
                    // Using textArea for this because the showCount
                    // functionality is styled correctly
                    <DevExTextArea
                      onChange={(value) => {
                        const newlabels = promptRequest.choiceLabels
                          ? [...promptRequest.choiceLabels]
                          : [];
                        newlabels[i] = value;
                        setPromptRequest({ ...promptRequest, choiceLabels: newlabels });
                      }}
                      style={{ width: '25%', resize: 'none' }}
                      value={l}
                      onFocus={(e) => e.target.select()}
                      maxLength={24}
                      autoSize={{ minRows: 1, maxRows: 2 }}
                      rightAlign={
                        promptRequest.choiceLabels && i === promptRequest.choiceLabels.length - 1
                      }
                      showCountOnFocus
                      embedded
                      key={i}
                    />
                  ))}
                </Flex>
              )}
            </Flex>
          )}
        </div>
      </Flex>
    </QuestionCard>
  );
};
