import React from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { useScoreColors } from './useScoreColors';

const styles = {
  block: css`
    color: ${(props) => props.theme.color.text.dark};
    width: fit-content;
    height: fit-content;
    line-height: 1;
    box-sizing: content-box;
  `,
};

type ScoreBlockProps = {
  score?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export const ScoreBlock: React.FC<ScoreBlockProps> = (props) => {
  const { colorByScore } = useScoreColors();
  const theme = useDevExTheme();

  const backgroundColor = colorByScore(props.score ?? 50);
  let size;
  if (props.size) {
    switch (props.size) {
      case 'xs':
        size = 12;
        break;
      case 'sm':
        size = 16;
        break;
      case 'md':
        size = 24;
        break;
      case 'lg':
        size = 32;
        break;
      case 'xl':
        size = 50;
        break;
      default:
        size = 16;
        break;
    }
  } else {
    size = parseInt(theme.variable.fontSize.sm);
  }

  return (
    <Flex
      css={styles.block}
      style={{
        backgroundColor,
        fontSize: size,
        minWidth: size,
        padding: Math.round(size / 4),
        borderRadius: Math.round(size / 4),
      }}
      align="center"
      justify="center"
    >
      {props.score ?? '—'}
    </Flex>
  );
};
