import React, { useState } from 'react';
import { Flex } from 'antd';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExTextArea } from 'jf/components/DevExTextArea';
import { QuestionCardIntro } from 'jf/pages/study/question/QuestionCardIntro';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';
import { useStudyUpdater } from '../useStudyUpdater';

export const DEFAULT_WELCOME_MESSAGE =
  'This survey will ask about your feelings towards the developer experience at your company.';

export const CustomizableIntroduction: React.FC = () => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);

  const [isEditing, setIsEditing] = useState(false);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: state.studyRef });

  // Only count prompts to match up with FE (spots using useStudyEstimatedTime) and BE (estimated_completion_time_seconds)
  const numberedStepCount = state.promptSlugs.length;

  const onSave = () => {
    studyUpdater.update({ welcomeMessage: state.welcomeMessage });
    setIsEditing(false);
  };

  const onCancel = () => {
    state.update({ welcomeMessage: study!.welcomeMessage });
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <Flex vertical align="end" gap={theme.variable.spacing.md}>
        <DevExButton
          type="outline"
          icon={<FontAwesomeIcon icon={faPen} />}
          onClick={() => setIsEditing(true)}
        >
          Edit
        </DevExButton>

        <QuestionCardIntro
          title={<strong>{state.name}</strong>}
          message={state.welcomeMessage || DEFAULT_WELCOME_MESSAGE}
          questionCount={numberedStepCount}
        />
      </Flex>
    );
  }

  return (
    <QuestionCardIntro
      title={<strong>{state.name}</strong>}
      message={
        <DevExTextArea
          value={state.welcomeMessage}
          placeholder={DEFAULT_WELCOME_MESSAGE}
          onChange={(value) => state.update({ welcomeMessage: value })}
          style={{ fontSize: theme.variable.fontSize.lg }}
        />
      }
      questionCount={numberedStepCount}
      actions={
        <>
          <DevExButton onClick={onCancel}>Cancel</DevExButton>
          <DevExButton type="primary" onClick={onSave}>
            Save
          </DevExButton>
        </>
      }
    />
  );
};
