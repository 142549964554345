/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NumericScore } from '../models/NumericScore';
import type { NumericScoreWithTeam } from '../models/NumericScoreWithTeam';
import type { NumericScoreWithTeamAndSegment } from '../models/NumericScoreWithTeamAndSegment';
import type { NumericScoreWithTeamOrSegment } from '../models/NumericScoreWithTeamOrSegment';
import type { Team } from '../models/Team';
import type { TextualSummary } from '../models/TextualSummary';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudyScoreClient {

    /**
     * Get a study's scores across all respondents.
     * @returns NumericScore
     * @throws ApiError
     */
    public static getStudyScores({
        studyRef,
        type,
    }: {
        studyRef: string,
        /**
         * Which object type to get scores for, either PROMPT, TOPIC, or SUBTOPIC.
         */
        type: 'PROMPT' | 'SUBTOPIC' | 'TOPIC',
    }): CancelablePromise<Array<NumericScore>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/scores',
            path: {
                'studyRef': studyRef,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * Get a study's scores across all respondents, broken out by segment name/value pairs.
     * @returns NumericScoreWithTeamOrSegment
     * @throws ApiError
     */
    public static getStudyScoresBySegment({
        studyRef,
        type,
    }: {
        studyRef: string,
        /**
         * Which object type to get scores for, either PROMPT, TOPIC, or SUBTOPIC.
         */
        type: 'PROMPT' | 'SUBTOPIC' | 'TOPIC',
    }): CancelablePromise<Array<NumericScoreWithTeamOrSegment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/scores/segments',
            path: {
                'studyRef': studyRef,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * Get a study's scores for a single team's respondents.
     * @returns NumericScoreWithTeam
     * @throws ApiError
     */
    public static getStudyScoresForTeam({
        studyRef,
        teamRef,
        type,
    }: {
        studyRef: string,
        teamRef: string,
        /**
         * Which object type to get scores for, either PROMPT, TOPIC, or SUBTOPIC.
         */
        type: 'PROMPT' | 'SUBTOPIC' | 'TOPIC',
    }): CancelablePromise<Array<NumericScoreWithTeam>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/scores/team/{teamRef}',
            path: {
                'studyRef': studyRef,
                'teamRef': teamRef,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * Get a study's scores for a single team's respondents, broken out by segment name/value pairs.
     * @returns NumericScoreWithTeamAndSegment
     * @throws ApiError
     */
    public static getStudyScoresForTeamBySegment({
        studyRef,
        teamRef,
        type,
    }: {
        studyRef: string,
        teamRef: string,
        /**
         * Which object type to get scores for, either PROMPT, TOPIC, or SUBTOPIC.
         */
        type: 'PROMPT' | 'SUBTOPIC' | 'TOPIC',
    }): CancelablePromise<Array<NumericScoreWithTeamAndSegment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/scores/team/{teamRef}/segments',
            path: {
                'studyRef': studyRef,
                'teamRef': teamRef,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * Get a study's selection of teams that had enough respondents to be scored. This will be filtered based on ScopeToken if applicable.
     * @returns Team
     * @throws ApiError
     */
    public static getStudyScoredTeams({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/scores/teams',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * Summarize textual response data.
     * @returns TextualSummary
     * @throws ApiError
     */
    public static getStudyScoresTextual({
        studyRef,
        regenerate,
        teamRef,
        topicSlug,
    }: {
        studyRef: string,
        /**
         * If provided, the requested summary will be regenerated.
         */
        regenerate?: boolean,
        teamRef?: string,
        topicSlug?: string,
    }): CancelablePromise<TextualSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/scores/textual',
            path: {
                'studyRef': studyRef,
            },
            query: {
                'regenerate': regenerate,
                'teamRef': teamRef,
                'topicSlug': topicSlug,
            },
        });
    }

}
