import React from 'react';
import { Badge, Cascader } from 'antd';
import { faFilterList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DefaultOptionType } from 'antd/es/select';
import { CascaderProps } from 'antd/lib';

import { DevExButton } from './DevExButton';

export type Filter = {
  options: DefaultOptionType[];
  value: string;
  type: 'categorical' | 'date' | 'numeric' | 'string';
  label: string;
  conditionText: string;
};

type FilterMultiSelectProps = {
  options: CascaderProps['options'];
  enabledCount?: number;
  onChange: (filters: any[]) => void; // antD upgrade will fix needing to have any[] here
};

export const FilterMultiSelect: React.FC<FilterMultiSelectProps> = ({
  options,
  onChange,
  enabledCount,
}) => {
  // dropdown for choosing which filters to enable
  return (
    <Badge count={enabledCount}>
      <Cascader
        multiple
        options={options}
        onChange={(values) => {
          onChange(values);
        }}
      >
        <DevExButton type="outline" icon={<FontAwesomeIcon icon={faFilterList} />} />
      </Cascader>
    </Badge>
  );
};
