import { useMemo } from 'react';
import { keyBy, sortBy } from 'lodash-es';

import {
  BenchmarkClient,
  CanonicalTopic,
  NumericScore,
  StudyClient,
  StudyLibraryClient,
  TopicVerbatimWithTeamRef,
} from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { BenchmarkPercentile } from '../benchmark/BenchmarkSelect';
import { getTopicPriorityBySlug } from '../insights/getTopicPriorityBySlug';
import { useStudyScores } from '../useStudyScores';
import { useStudyVerbatims } from '../useStudyVerbatims';

type UseTopicDataProps = {
  studyRef: string | undefined;
  teamRef: string | undefined;
  benchmarkPercentile?: BenchmarkPercentile | undefined;
  // TODO: Break sort and core functionality out into different hooks... Until then...
  // ironically sort is optional, due to a bug when multiple sort states being used
  sortBy?: string;
};

export type TopicDataRecord = {
  score: NumericScore;
  promptScores: NumericScore[];
  scoreTrend?: number;
  freqDist: number[];
  priority?: number;
  calculatedBenchmark?: number;
  comments: TopicVerbatimWithTeamRef[];
  votes: TopicVerbatimWithTeamRef[];
  topic: CanonicalTopic;
};

export const useTopicData = (props: UseTopicDataProps) => {
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: topicScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'TOPIC',
  });
  const { data: promptScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'PROMPT',
  });

  const showBenchmarks = !!window.dx.user?.company.aggregatable && !!props.benchmarkPercentile;

  const { data: cohorts } = useClientQuery(BenchmarkClient.getBenchmarkCohorts, null, {
    enabled: showBenchmarks,
  });
  const cohort = cohorts?.at(-1); // grab the most recent cohort
  const { data: cohortScores } = useClientQuery(
    BenchmarkClient.getBenchmarkCohortScores,
    { slug: cohort?.slug, type: 'TOPIC' },
    { enabled: showBenchmarks }
  );

  const { data: topicVerbatims } = useStudyVerbatims({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  const { data: lastTopicScores } = useStudyScores({
    studyRef: study?.lastStudyRef,
    teamRef: props.teamRef,
    type: 'TOPIC',
  });

  const data = useMemo(() => {
    if (!study || !topics || !prompts || !topicScores || !promptScores || !topicVerbatims) {
      return undefined;
    }

    const data: TopicDataRecord[] = [];

    const topicsBySlug = keyBy(topics, 'slug');
    const promptsBySlug = keyBy(prompts, 'slug');
    const benchmarkBySlug = keyBy(cohortScores, 'slug');
    const topicPriorityBySlug = getTopicPriorityBySlug(topics, topicScores, topicVerbatims);
    const comments = topicVerbatims.filter((verbatim) => verbatim.responseText);

    // create a data record for each topic
    for (const score of topicScores) {
      const topic = topicsBySlug[score.slug];

      const lastScore = lastTopicScores?.find(({ slug }) => slug === score.slug);
      const benchmark = benchmarkBySlug[topic.slug];
      const topicComments = comments.filter((comment) => comment.topicSlug === topic.slug);
      const topicVotes = topicVerbatims.filter((verbatim) => verbatim.topicSlug === topic.slug);
      if (topic) {
        data.push({
          topic,
          score: score,
          scoreTrend: lastScore ? score.scaledAvg - lastScore.scaledAvg : undefined,
          freqDist: score.freqDist,
          priority: topicPriorityBySlug[topic.slug],
          calculatedBenchmark:
            benchmark && props.benchmarkPercentile
              ? score.scaledAvg - benchmark[props.benchmarkPercentile]
              : undefined,
          comments: topicComments,
          votes: topicVotes,
          promptScores: promptScores.filter(
            (score) => promptsBySlug[score.slug].topicSlug === topic.slug
          ),
        });
      }
    }
    // if this hook is called multiple times for one component, and sorted
    // the state can conflict and cause issues. Making sort optional for that case.
    if (props.sortBy) {
      return sortBy(data, props.sortBy);
    }
    return data;
  }, [
    study,
    topics,
    prompts,
    topicScores,
    promptScores,
    topicVerbatims,
    cohortScores,
    props.sortBy,
    props.benchmarkPercentile,
    lastTopicScores,
  ]);

  return data;
};
