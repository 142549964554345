/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TextualSummary = {
    summary: string;
    /**
     * * `SUCCESS` - SUCCESS
     * * `FAILURE` - FAILURE
     */
    summaryType: TextualSummary.summaryType;
};

export namespace TextualSummary {

    /**
     * * `SUCCESS` - SUCCESS
     * * `FAILURE` - FAILURE
     */
    export enum summaryType {
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
    }


}

