import React from 'react';
import { Flex } from 'antd';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExModal } from 'jf/components/DevExModal';

export const OpenTextQuestionModal: React.FC = () => {
  const modal = useJFOverlay(OpenTextQuestionModal);
  const theme = useDevExTheme();

  const onCancel = () => {
    modal.close();
  };

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      title="What makes a great open-ended question?"
      width={650}
      footer={null}
    >
      <Flex vertical style={{ paddingInline: theme.variable.spacing.sm }}>
        <div>
          <strong>First, does it need to be open-ended? </strong>
          These questions provide valuable insights but take more effort to answer. Use them
          sparingly to prevent survey fatigue—place them strategically, ideally after simpler
          questions.
        </div>
        <div style={{ paddingTop: theme.variable.spacing.lg }}>
          <strong>What makes a great text question:</strong>
        </div>
        <ul>
          <li>
            <strong> Specific yet flexible </strong>– Guide respondents while leaving room for
            unexpected insights. Instead of <i> "What do you think of our team processes?"</i>, try{' '}
            <i> "What aspects of our team processes could be improved?"</i>
          </li>
          <li>
            <strong> Neutral and unbiased </strong>– Avoid questions that imply a "correct" answer.
            Rather than <i>"Why do you enjoy our release process?"</i>, ask{' '}
            <i>"What are your thoughts on our release process?"</i>
          </li>
          <li>
            <strong> Clear and focused </strong>– Keep each question centered on a single DevEx
            topic. <i>"Tell us about the test automation and your development environment"</i> is
            too broad, whereas{' '}
            <i>"What challenges, if any, do you encounter with your dev environment?"</i> is more
            focused.
          </li>
          <li>
            <strong> Encourages detailed responses </strong>– Questions starting with <i>"How"</i>{' '}
            or <i>"What"</i> often yield richer insights than those beginning with <i>"Why"</i> or{' '}
            <i>"Do."</i> Compare <i>"How would you describe your onboarding experience?"</i> with{' '}
            <i>"Did you like your onboarding experience?"</i>—the first invites more thoughtful
            responses.
          </li>
        </ul>
        By crafting open-ended questions with these principles in mind, you can maximize the depth
        of feedback while keeping respondents engaged.
      </Flex>
    </DevExModal>
  );
};
