import React, { useMemo, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faChevronLeft, faChevronRight, faPlay, faShare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyClient } from 'jf/api';
import { DevExHeader } from 'jf/common/DevExHeader';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { CopyLinkPopover } from 'jf/components/CopyLinkPopover';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLottie } from 'jf/components/DevExLottie';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExSteps } from 'jf/components/DevExSteps';
import SavedCloud from 'jf/devex/lottie/SavedCloud.json';
import SavingCloud from 'jf/devex/lottie/SavingCloud.json';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ANONYMITY_THRESHOLD } from '../studyAnalyzer/StudyAnalyzerPage';

import { useCompleteConfigureTeamsStep } from './configureTeams/useCompleteConfigureTeamsStep';
import { useCompleteSelectTeamsStep } from './selectTeams/useCompleteSelectTeamsStep';
import { useCompleteTargetRespondentsStep } from './targetRespondents/useCompleteTargetRespondentsStep';
import { STUDY_BUILDER_STEPS, StudyBuilderStep, useStudyBuilderState } from './StudyBuilderState';
import { useStudyUpdater } from './useStudyUpdater';

const STUDY_BUILDER_STEP_LABELS: { [key in StudyBuilderStep]: string } = {
  'choose-study': 'Choose base survey',
  'customize-study': 'Customize survey',
  'configure-teams': 'Set up your teams',
  'select-teams': 'Select teams',
  'target-respondents': 'Target respondents',
  'publish-study': 'Review and publish',
};

export const StudyBuilderHeader: React.FC = () => {
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);
  const completeConfigureTeamsStep = useCompleteConfigureTeamsStep();
  const completeTargetRespondentsStep = useCompleteTargetRespondentsStep();
  const completeSelectTeamsStep = useCompleteSelectTeamsStep();

  const [nextLoading, setNextLoading] = useState(false);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: state.studyRef });
  const flags = useClientFlags();
  let steps = [...STUDY_BUILDER_STEPS];
  if (!flags.slackTargeting) {
    const stepIndex = steps.findIndex((step) => step === 'target-respondents');
    steps.splice(stepIndex, 1);
  }
  if (flags.globalTeamsPage) {
    const stepIndex = steps.findIndex((step) => step === 'configure-teams');
    steps.splice(stepIndex, 1, 'select-teams');
  }

  const stepIndex = steps.findIndex((key) => key === state.step);
  const nextStep = steps[stepIndex + 1];
  const lastStep = steps[stepIndex - 1];

  const completionCallbacks: { [key in StudyBuilderStep]?: () => Promise<void> } = {
    'configure-teams': completeConfigureTeamsStep,
    'target-respondents': completeTargetRespondentsStep,
    'select-teams': completeSelectTeamsStep,
  };

  const onNext = async () => {
    const complete = completionCallbacks[state.step];
    if (complete) {
      setNextLoading(true);
      await complete();
      setNextLoading(false);
    }
    state.update({ step: nextStep });
  };

  const onPreview = () => {
    trackEvent('survey-editor:preview:start', { surveyRef: state.studyRef });
    navigate(`/study/${state.studyRef}`);
  };

  const nextDisabledTooltip = useMemo(() => {
    if (state.step === 'customize-study' && !state.promptSlugs.length) {
      return 'You must add a question to continue.';
    }
    if (state.step === 'configure-teams' && !state.teams.filter((team) => !team.isHidden).length) {
      return 'You must add a valid team to continue. These teams will be used to segment your survey results for analysis.';
    }
    if (state.step === 'select-teams' && state.studyTeams.length === 0) {
      return 'You must select at least one team to continue.';
    }
    if (
      state.step === 'target-respondents' &&
      state.slackTargetingEnabled &&
      state.slackTargets.length < ANONYMITY_THRESHOLD
    ) {
      return 'You must add more slack targets to continue.';
    }
  }, [state]);

  return (
    <DevExHeader
      left={
        <Flex gap={theme.variable.spacing.md}>
          {stepIndex > 1 ? (
            <DevExButton
              size="large"
              type="outline"
              icon={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={() => state.update({ step: lastStep })}
            >
              {STUDY_BUILDER_STEP_LABELS[lastStep]}
            </DevExButton>
          ) : (
            <DevExButton
              size="large"
              type="outline"
              onClick={() => {
                if (state.updatingStudy) {
                  window.alert('Please wait while your survey is being saved.');
                } else {
                  navigate('/studies');
                }
              }}
            >
              Back to surveys
            </DevExButton>
          )}

          {state.step === 'customize-study' && (
            <Flex
              gap={theme.variable.spacing.xs}
              style={{ color: theme.color.text.secondary }}
              align="center"
            >
              <DevExLottie
                key={state.updatingStudy ? 'saving' : 'saved'}
                data={state.updatingStudy ? SavingCloud : SavedCloud}
                loop={state.updatingStudy}
                colors={[theme.color.background.default, theme.color.text.secondary]}
                style={{ height: 24 }}
              />
              {state.updatingStudy ? 'Saving' : 'Saved'}
            </Flex>
          )}
        </Flex>
      }
      right={
        <Flex gap={theme.variable.spacing.md}>
          {!!state.studyRef && (
            <>
              <Tooltip
                title={
                  state.step !== 'publish-study' ? 'Preview study questions like a respondent.' : ''
                }
              >
                <DevExButton
                  type="outline"
                  size="large"
                  icon={<FontAwesomeIcon icon={faPlay} />}
                  onClick={onPreview}
                  loading={state.updatingStudy}
                >
                  {state.step === 'publish-study' ? 'Preview' : ''}
                </DevExButton>
              </Tooltip>
              <CopyLinkPopover
                title="Share Preview Link"
                description="Anyone with this link will be able to preview the study questions like a respondent."
                link={`${window.location.origin}/study/${state.studyRef}`}
              >
                <DevExButton size="large" type="outline" icon={<FontAwesomeIcon icon={faShare} />}>
                  Share preview
                </DevExButton>
              </CopyLinkPopover>{' '}
            </>
          )}

          {!!state.studyRef && !!nextStep && (
            <Tooltip title={nextDisabledTooltip}>
              <DevExButton
                size="large"
                type="primary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                iconPosition="end"
                onClick={onNext}
                loading={nextLoading}
                disabled={!!nextDisabledTooltip}
                style={{ cursor: nextDisabledTooltip ? 'not-allowed' : undefined }}
              >
                {STUDY_BUILDER_STEP_LABELS[nextStep]}
              </DevExButton>
            </Tooltip>
          )}
        </Flex>
      }
    >
      <Flex
        vertical
        align="center"
        gap={theme.variable.spacing.lg}
        style={{ lineHeight: theme.variable.lineHeight }}
      >
        {state.studyRef ? (
          study ? (
            <DevExInput
              value={state.name}
              onChange={(value) => {
                state.update({ name: value });
                studyUpdater.update({ name: value });
              }}
              placeholder="Add name"
              error={!state.name}
              autosize
              style={{ minWidth: 240 }}
              embedded
            />
          ) : (
            <Flex justify="center">
              <DevExSkeleton width={360} fontSize="xl" />
            </Flex>
          )
        ) : (
          <div>Create DevEx survey</div>
        )}

        <div style={{ fontSize: 'initial', fontWeight: 'initial' }}>
          <DevExSteps
            labels={steps.map((step) => STUDY_BUILDER_STEP_LABELS[step])}
            activeIndex={steps.findIndex((key) => key === state.step)}
          />
        </div>
      </Flex>
    </DevExHeader>
  );
};
