import React, { useRef } from 'react';
import { Flex } from 'antd';
import { useScroll } from 'framer-motion';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { DevExThemeProvider, useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { formatAvgTimeSec } from 'jf/utils/timeUtils';

import { ParticipationChart } from '../participation/ParticipationChart';
import { useStudyParticipation } from '../useStudyParticipation';

import { GradientTransition } from './GradientTransition';
import { MotionDiv } from './MotionDiv';
import { ParticipationCard } from './ParticipationCard';
import { usePageIndexTracker } from './studyReveal';

const styles = {
  participation: css`
    width: 100vw;
    height: 500vh;
    background-color: ${(props) => props.theme.color.reveal.purple.main.background};

    .participation__positiveMessage {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.xxl};
      color: ${(props) => props.theme.color.reveal.purple.main.text};
      font-weight: bold;
      padding-inline: ${(props) => props.theme.variable.spacing.xl};
      padding-top: ${(props) => props.theme.variable.spacing.xxl};
      padding-bottom: ${(props) => props.theme.variable.spacing.xl};
      position: relative;
    }

    .participation__countMessage {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.xxl};
      color: ${(props) => props.theme.color.reveal.purple.main.text};
      font-weight: bold;
      padding-inline: ${(props) => props.theme.variable.spacing.xl};
      padding-top: ${(props) => props.theme.variable.spacing.xxl};
      padding-bottom: ${(props) => props.theme.variable.spacing.xl};
      position: absolute;
    }
  `,
};

export const ParticipationReveal: React.FC = () => {
  const theme = useDevExTheme();

  //fetch study data
  const { studyRef } = useParams<{ studyRef: string }>();
  const { data: participation } = useStudyParticipation({ studyRef: studyRef });

  //get user scroll position
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });

  usePageIndexTracker(targetRef, 'participation');

  //calculate days open
  const date1Str = participation?.openDate;
  const date2Str = participation?.closeDate;
  let diffInDays;
  if (date1Str && date2Str) {
    const date1 = DateTime.fromISO(date1Str);
    const date2 = DateTime.fromISO(date2Str);
    diffInDays = date2.diff(date1, 'days').toObject().days;
  }

  const positiveVariants = {
    visible: { opacity: 1, y: '20px', transition: { duration: 0.05 } },
    hidden: { opacity: 0, y: '70px', transition: { duration: 0.05 } },
    remove: { opacity: 0, y: '-100px', transition: { duration: 0.05 } },
  };

  const chartVariants = {
    visible: { opacity: 1, transition: { duration: 0.05 } },
    hidden: { opacity: 0, transition: { duration: 0.05 } },
  };

  return (
    <>
      <div
        style={{
          height: '100vh',
          backgroundColor: theme.color.reveal.purple.main.background,
        }}
      />
      <div css={styles.participation} ref={targetRef}>
        <div
          style={{
            display: 'flex',
            position: 'sticky',
            alignItems: 'center',
            justifyContent: 'center',
            top: theme.variable.spacing.md,
          }}
        >
          <div>
            <Flex vertical align="center">
              <MotionDiv
                className="participation__positiveMessage"
                variants={positiveVariants}
                scrollYProgress={scrollYProgress}
                fadeIn={15}
                fadeOut={35}
              >
                Let's take a look at participation
              </MotionDiv>
              <MotionDiv
                className="participation__countMessage"
                variants={positiveVariants}
                scrollYProgress={scrollYProgress}
                fadeIn={36}
              >
                {participation?.uniqueResponses} participants
              </MotionDiv>
            </Flex>
            <Flex className="card__content" gap={theme.variable.spacing.md} justify="space-between">
              {participation && (
                <>
                  <MotionDiv variants={chartVariants} scrollYProgress={scrollYProgress} fadeIn={65}>
                    <ParticipationCard
                      title="Average Time"
                      subtitle={formatAvgTimeSec(participation?.avgTimeSec)}
                    />
                  </MotionDiv>
                  <MotionDiv variants={chartVariants} scrollYProgress={scrollYProgress} fadeIn={75}>
                    <ParticipationCard
                      title="Question Count"
                      subtitle={participation?.prompts.length}
                    />
                  </MotionDiv>
                  <MotionDiv variants={chartVariants} scrollYProgress={scrollYProgress} fadeIn={85}>
                    <ParticipationCard title="Days Open" subtitle={diffInDays} />
                  </MotionDiv>
                </>
              )}
            </Flex>
            <MotionDiv
              variants={chartVariants}
              scrollYProgress={scrollYProgress}
              style={{
                width: '1000px',
                paddingTop: theme.variable.spacing.lg,
              }}
              fadeIn={55}
            >
              <DevExCard
                style={{
                  paddingTop: theme.variable.spacing.xl,
                  backgroundColor: theme.color.reveal.purple.card.background,
                }}
              >
                <DevExThemeProvider themeKey="light">
                  <ParticipationChart studyRef={studyRef} />
                </DevExThemeProvider>
              </DevExCard>
            </MotionDiv>
          </div>
        </div>
      </div>
      <GradientTransition
        topColor={theme.color.reveal.purple.main.background}
        bottomColor={theme.color.reveal.dark.background}
      />
    </>
  );
};
