import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy } from 'lodash-es';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';
import { css } from 'styled-components';

import { StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExTag } from 'jf/components/DevExTag';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../../StudyBuilderState';
import { useStudyUpdater } from '../../useStudyUpdater';
import { PromptIcon } from '../PromptIcon';

import { StudyStepItem } from './StudyStepItem';

const styles = {
  promptSteps: css`
    .smooth-dnd-container {
      flex-shrink: 0;

      .promptSteps__step {
        cursor: grab;

        .step__icon {
          font-size: 18px;
          min-width: 18px;
        }

        .step__drag {
          font-size: 20px;
          padding-inline: ${(props) => props.theme.variable.spacing.xs};
        }

        &:not(:hover) {
          .step__drag {
            display: none;
          }
        }

        &:hover {
          .step__icon {
            display: none;
          }
        }
      }
    }
  `,
};

export const PromptSteps: React.FC = () => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const studyUpdater = useStudyUpdater(state.studyRef);

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const topicsBySlug = useMemo(() => (topics ? keyBy(topics, 'slug') : undefined), [topics]);
  const promptsBySlug = useMemo(() => (prompts ? keyBy(prompts, 'slug') : undefined), [prompts]);

  const movePrompt = (oldIndex: number, newIndex: number) => {
    const newPromptSlugs = [...state.promptSlugs].filter((slug) => slug !== 'UNSAVED');

    const slug = newPromptSlugs.splice(oldIndex, 1)[0]; // remove slug at oldIndex
    newPromptSlugs.splice(newIndex, 0, slug); // insert slug at newIndex

    studyUpdater.update({ sequencedPrompts: newPromptSlugs });
    state.update({
      promptSlugs: newPromptSlugs,
      studyStep: { type: 'PROMPT', key: slug }, // follow the prompt being moved
    });
  };

  if (!state.loaded || !topicsBySlug || !promptsBySlug) {
    return (
      <>
        {arrayOf(20).map((i) => (
          <StudyStepItem key={i} disabled>
            <DevExSkeleton width={['100%', '70%']} fontSize="sm" />
          </StudyStepItem>
        ))}
      </>
    );
  }

  return (
    <div css={styles.promptSteps}>
      <DragContainer
        onDrop={(result) => {
          if (
            result.removedIndex != null &&
            result.addedIndex != null &&
            result.removedIndex !== result.addedIndex
          ) {
            movePrompt(result.removedIndex, result.addedIndex);
          }
        }}
      >
        {state.promptSlugs.map((promptSlug, i) => {
          const prompt = promptsBySlug[promptSlug];

          if (!prompt) {
            return null;
          }

          const topic = topicsBySlug[prompt.topicSlug];

          return (
            <Draggable key={promptSlug}>
              <StudyStepItem
                step={{ type: 'PROMPT', key: promptSlug }}
                className="promptSteps__step"
              >
                <Flex gap={theme.variable.spacing.sm} align="center">
                  <PromptIcon type={prompt.type} className="step__icon" />
                  <FontAwesomeIcon icon={faGripDotsVertical} className="step__drag" />
                  <span>{prompt.text}</span>
                </Flex>
                {topic && <DevExTag style={{ flexShrink: 0 }}>{topic.label}</DevExTag>}
              </StudyStepItem>
            </Draggable>
          );
        })}
      </DragContainer>
    </div>
  );
};
