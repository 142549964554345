import React, { useMemo } from 'react';
import { Divider, Flex, Tooltip } from 'antd';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isNil from 'lodash-es/isNil';

import { StudyClient, StudySequenceClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExText } from 'jf/components/DevExText';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { BenchmarkPercentile } from '../benchmark/BenchmarkSelect';
import { ScoreBlock } from '../ScoreBlock';
import { TopicDataRecord, useTopicData } from '../topicDrawer/useTopicData';

import { BenchmarkCell } from './BenchmarkCell';
import { TrendCell } from './TrendCell';

type OverallStudyScoreCardProps = {
  studyRef: string | undefined;
  prevStudyRef?: string;
  teamRef?: string;
  benchmarkPercentile?: BenchmarkPercentile;
};

const getTotal = (topics?: TopicDataRecord[]) => {
  if (topics) {
    // averaging all scores from topic level.
    // this assumes every topic has the same number of responses
    // this may not be the case forever
    return Math.ceil(
      topics.reduce((total, topic) => (total += topic.score.scaledAvg), 0) / topics.length
    );
  }
  return undefined;
};

export const OverallStudyScoreCard: React.FC<OverallStudyScoreCardProps> = ({
  studyRef,
  teamRef,
  benchmarkPercentile,
}) => {
  const topicData = useTopicData({
    studyRef,
    teamRef,
    benchmarkPercentile,
  });
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, {
    sequenceRef: study?.sequenceRef,
  });

  const theme = useDevExTheme();

  const prevStudyRef = useMemo(() => {
    if (sequence && studyRef) {
      const refindex = sequence.studyRefs.indexOf(studyRef);
      return refindex !== sequence.studyRefs.length - 1 // if isn't the first study in the sequence (last in array)
        ? sequence.studyRefs[refindex + 1]
        : undefined;
    }
    return undefined;
  }, [sequence, studyRef]);

  const nextSurveyTopicData = useTopicData({
    studyRef: prevStudyRef,
    teamRef,
    benchmarkPercentile,
  });

  const scoreSummary: number | undefined = useMemo(() => getTotal(topicData), [topicData]);
  const benchmarkAvg: number | undefined = useMemo(() => {
    let benchmarkTotal;
    let nobenchmark = 0;
    if (topicData && benchmarkPercentile) {
      benchmarkTotal = topicData.reduce((total, topic) => {
        if (topic.calculatedBenchmark) {
          return (total += topic.calculatedBenchmark);
        }
        nobenchmark++;
        return total;
      }, 0);
      if (nobenchmark === topicData.length) {
        // no benchmarks
        return undefined;
      }
      return Math.round(benchmarkTotal / (topicData.length - nobenchmark));
    }
    return undefined;
    // benchmark percentile causes the data to change
    // this gets triggered by that chain, not benchmarkPercentile
  }, [topicData]);

  const nextScoreSummary: number | undefined = useMemo(
    () => getTotal(nextSurveyTopicData),
    [nextSurveyTopicData]
  );

  const trendval = useMemo(() => {
    if (isNil(nextScoreSummary) || isNil(scoreSummary)) {
      return undefined;
    }
    return scoreSummary - nextScoreSummary;
  }, [scoreSummary, nextScoreSummary]);
  return (
    <DevExCard style={{ width: 360 }}>
      <Flex align="center" gap={theme.variable.spacing.md}>
        <ScoreBlock score={scoreSummary} size="lg" />
        <Flex vertical gap={theme.variable.spacing.sm}>
          <Flex gap={theme.variable.spacing.sm} align="center">
            <DevExText strong fontSize="lg">
              Overall DevEx Score
            </DevExText>
            <Tooltip
              overlayStyle={{ width: 400 }}
              title={
                <>
                  The <strong>Overall DevEx Score</strong> is a single metric summarizing your
                  overall developer experience, calculated as an average of topic scores from your
                  survey.
                </>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </Flex>
          <Flex gap={theme.variable.spacing.sm} align="center">
            <DevExText type="secondary">Trend</DevExText>
            <TrendCell trend={trendval} />

            {benchmarkPercentile && (
              <>
                <Divider type="vertical" />
                <DevExText type="secondary">Benchmark</DevExText>
                <BenchmarkCell benchmark={benchmarkAvg} />
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </DevExCard>
  );
};
