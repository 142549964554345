import React from 'react';
import { Avatar, Flex } from 'antd';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SlackEntity } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';

export const SlackEntityDisplay = ({
  image,
  displayName,
  type,
  name,
}: {
  image: string | undefined | null;
  displayName: string | undefined | null;
  name: string | undefined;
  type?: SlackEntity.type;
}) => {
  const theme = useDevExTheme();

  return (
    <Flex gap={theme.variable.spacing.sm} align="center">
      <Avatar
        shape={'square'}
        style={{ backgroundColor: theme.color.background.active }}
        size="large"
        src={image ?? <FontAwesomeIcon icon={faUsers} />}
      />
      <Flex vertical>
        {displayName}
        <div style={{ color: theme.color.text.secondary }}>
          {' '}
          {`${type === SlackEntity.type.CHANNEL ? '#' : '@'}${name}`}
        </div>
      </Flex>
    </Flex>
  );
};
