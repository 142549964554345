import React, { useRef } from 'react';
import { Flex } from 'antd';
import { motion, useScroll } from 'framer-motion';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { TopicDataRecord } from '../topicDrawer/useTopicData';

import { DefinitionCard } from './DefinitionCard';
import { MotionDiv } from './MotionDiv';
import { RevealCard } from './revealCard';
import { usePageIndexTracker, useStudyRevealState } from './studyReveal';

const styles = {
  filteredTopics: css`
    width: 100vw;
    height: 800vh;
    background-color: ${(props) => props.theme.color.reveal.scores.background};

    .filteredTopics__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: 64px;
      color: ${(props) => props.theme.color.reveal.scores.text};
      font-weight: bold;
      padding-top: ${(props) => props.theme.variable.spacing.xxl};
      padding-bottom: ${(props) => props.theme.variable.spacing.xl};
    }

    .filteredScores__score {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.md};
      color: ${(props) => props.theme.color.reveal.scores.secondary};
      padding-bottom: ${(props) => props.theme.variable.spacing.sm};
      font-weight: bold;
      position: relative;
      text-align: right;
    }
  `,
};

type TopicRevealProps = {
  title: string;
  filteredTopics: TopicDataRecord[];
  slug: string;
};

export const TopicReveal: React.FC<TopicRevealProps> = (props) => {
  const theme = useDevExTheme();

  //get user scroll position
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });

  usePageIndexTracker(targetRef, props.slug);

  const scoreVariants = {
    visible: { x: '-215px', opacity: 1, transition: { duration: 0.05 } },
    hidden: { x: '120px', opacity: 0, transition: { duration: 0.05 } },
    remove: { opacity: 0, transition: { duration: 0.05 } },
  };
  const studyRevealState = useStudyRevealState();

  const pageInView =
    studyRevealState.pageSlug === 'high-three' || studyRevealState.pageSlug === 'low-three';

  return (
    <div ref={targetRef}>
      <div
        style={{
          height: '50vh',
          backgroundColor: theme.color.reveal.scores.background,
        }}
      />
      <div css={styles.filteredTopics}>
        {pageInView && (
          <div
            style={{
              display: 'flex',
              position: 'sticky',
              alignItems: 'center',
              justifyContent: 'center',
              top: theme.variable.spacing.md,
              overflow: 'hidden',
            }}
          >
            <Flex>
              <Flex vertical style={{ width: '100%' }}>
                <Flex align="center">
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="filteredTopics__header"
                  >
                    {props.title}
                  </motion.div>
                  <DefinitionCard
                    title="What's a Topic?"
                    subtitle="Topics are research-identified areas that are key factors impacting developer experience. Each question of your survey is associated with a Topic."
                    scrollYProgress={scrollYProgress}
                    scrollIn={0.05}
                    scrollOut={0.3}
                  />
                  <DefinitionCard
                    title="What's a Score?"
                    subtitle="Scores represent the sentiment for each Topic, calculated on a 0-100 scale, where 100 is the most favorable score. The topic score is the average score of all the survey questions relating to that topic."
                    scrollYProgress={scrollYProgress}
                    scrollIn={0.32}
                    scrollOut={0.52}
                  />
                </Flex>
                {props.filteredTopics.map((topicData, i) => (
                  <Flex key={i} align="flex-start" gap={theme.variable.spacing.md}>
                    <Flex vertical>
                      {i === 0 && (
                        <MotionDiv
                          variants={scoreVariants}
                          scrollYProgress={scrollYProgress}
                          fadeIn={24}
                          fadeOut={51}
                          className="filteredScores__score"
                        >
                          Score
                        </MotionDiv>
                      )}
                      <motion.div
                        key={i}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{
                          duration: 0.5,
                          delay: i * 0.5 + 0.8,
                        }}
                      >
                        <RevealCard
                          key={i}
                          rank={i}
                          title={topicData.topic.label}
                          subtitle={topicData.topic.longDescription}
                          votes={topicData.votes.length}
                          scrollYProgress={scrollYProgress}
                          icon={i + 1}
                          score={topicData.score.scaledAvg}
                          dist={topicData.score.freqDist}
                        />
                      </motion.div>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
};
