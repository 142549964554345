import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';

import { Prompt, PublicPrompt } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExTextArea } from 'jf/components/DevExTextArea';

import { LikertScale } from './LikertScale';
import { QuestionCard, QuestionCardProps } from './QuestionCard';

interface QuestionCardPromptProps extends Omit<QuestionCardProps, 'onBack' | 'onNext'> {
  number: number;
  onBack?: (value?: number | string) => void;
  onNext?: (value?: number | string) => void;
  prompt: PublicPrompt | Prompt;
  tag?: string;
  initialValue?: number | string;
}

export const QuestionCardPrompt: React.FC<QuestionCardPromptProps> = (props) => {
  const theme = useDevExTheme();

  const { prompt, ...cardProps } = props;
  const [value, setValue] = useState(props.initialValue);

  const isLikert = prompt.type === Prompt.type.RATING_5L || prompt.type === Prompt.type.RATING_5N;
  const isOpenEnded = prompt.type === Prompt.type.OPEN_END;

  const validated = useMemo(() => {
    if (typeof value === 'string') {
      return !!value.trim();
    }
    return !!value;
  }, [value]);

  return (
    <QuestionCard
      title={props.prompt.text}
      subtitle={<span>Question {props.number}</span>}
      tag={props.tag}
      validated={validated}
      auto={isLikert}
      {...cardProps}
      onBack={props.onBack && (() => props.onBack?.(value))}
      onNext={props.onNext && (() => props.onNext?.(value))}
    >
      {isLikert && (
        <LikertScale
          value={value as number}
          onChange={setValue}
          polarity={prompt.polarity}
          numeric={prompt.type === Prompt.type.RATING_5N}
          disabled={props.disabled}
          choiceLabels={prompt.choiceLabels}
        />
      )}
      {isOpenEnded && (
        <Flex style={{ paddingBottom: theme.variable.spacing.md }}>
          <DevExTextArea
            value={value}
            onChange={setValue}
            maxLength={1000}
            showCount={{ formatter: ({ count }) => (count >= 900 ? `${count} / 1000` : null) }}
            autoSize={{ minRows: 3 }}
          />
        </Flex>
      )}
    </QuestionCard>
  );
};
