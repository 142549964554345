import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SlackTargetingClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

export const useSyncSlackUsersQuery = () => {
  const { studyRef } = useParams<{ studyRef: string }>();

  const [nextCursor, setNextCursor] = useState<string>();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState(false);

  const { data, isFetching } = useClientQuery(
    SlackTargetingClient.syncSlackUsers,
    {
      studyRef,
      // pageSize: 100,
      ...(nextCursor ? { cursor: nextCursor } : {}), // dont pass param if undefined
    },
    {
      enabled,
    }
  );

  // Prefetch the next nextCursor!
  useEffect(() => {
    if (data?.next) {
      setNextCursor(data.next);
    } else {
      setIsFinished(true);
    }
  }, [data]);
  const kickSlackUsersSync = () => {
    setEnabled(true);
  };
  return { isFinished, slackUsers: data, isFetching, kickSlackUsersSync, enabled };
};
