import React from 'react';
import { Flex, Spin, Tooltip } from 'antd';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextualSummary } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExText } from 'jf/components/DevExText';
import { ThumbsFeedback } from 'jf/components/ThumbsFeedback';

type AISummaryProps = {
  summary?: string;
  summaryType?: TextualSummary.summaryType;
};

const AISummary: React.FC<AISummaryProps> = ({ summary, summaryType }) => {
  const theme = useDevExTheme();

  if (summary) {
    if (summaryType === TextualSummary.summaryType.SUCCESS) {
      return (
        <Flex
          vertical
          gap={theme.variable.spacing.sm}
          style={{ lineHeight: theme.variable.lineHeight }}
        >
          <Flex
            gap={theme.variable.spacing.sm}
            style={{ color: theme.color.status.discovery.text }}
            align="center"
          >
            <Tooltip title={`Generative AI was used to summarize all the responses`}>
              <FontAwesomeIcon icon={faSparkles} />
            </Tooltip>
            Summary of what respondents said
          </Flex>
          {summary}
          <ThumbsFeedback title={'Do you find this summary useful?'} eventName={'ai-summary'} />
        </Flex>
      );
    }

    if (summaryType === TextualSummary.summaryType.FAILURE) {
      return (
        <Flex
          vertical
          gap={theme.variable.spacing.sm}
          style={{ lineHeight: theme.variable.lineHeight }}
        >
          <Flex
            gap={theme.variable.spacing.sm}
            style={{ color: theme.color.text.secondary }}
            align="center"
          >
            <Tooltip title={`Generative AI was used to summarize all the responses`}>
              <FontAwesomeIcon icon={faSparkles} />
            </Tooltip>
            <DevExText type="secondary">{summary}</DevExText>{' '}
          </Flex>
        </Flex>
      );
    }
  }
  return (
    <Flex vertical gap={theme.variable.spacing.sm}>
      <Flex gap={theme.variable.spacing.md} style={{ color: theme.color.text.secondary }}>
        <Spin style={{ color: theme.color.text.secondary }} />
        Generating AI summary
      </Flex>
      <DevExSkeleton height={75} />
    </Flex>
  );
};

export { AISummary };
