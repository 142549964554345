import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faUsers } from '@fortawesome/pro-thin-svg-icons';
import { keyBy } from 'lodash-es';

import { RosterClient, Team } from 'jf/api';
import { DevExPageHeader } from 'jf/common/DevExPageHeader';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExExpandableAlert } from 'jf/components/DevExExpandableAlert';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExRadioButtons } from 'jf/components/DevExRadioButtons';
import { DevExSearch } from 'jf/components/DevExSearch';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { CATCH_ALL_TEAM_REF } from '../study/question/QuestionCardDemographic';

import { EditableTeamHierarchy } from './EditableTeamHierarchy';

type TeamsFilter = 'ALL' | 'HIDDEN' | 'DEVEX';

const TEAMS_FILTER_LABELS: { [key in TeamsFilter]: string } = {
  ALL: 'All',
  HIDDEN: 'Hidden',
  DEVEX: 'DevEx Only',
};

/**
 * Filter teams by a filtering function, while also including all parent teams above the filtered teams.
 */
export const filterTeamsInHierarchy = (teams: Team[], filterFn: (team: Team) => boolean) => {
  const filteredTeams = teams.filter(filterFn);

  const teamVisibilty: { [ref: string]: boolean } = {};
  const teamsByRef = keyBy(teams, 'ref');

  // include parents of filtered teams
  for (const filteredTeam of filteredTeams) {
    let team = filteredTeam;
    teamVisibilty[team.ref] = true;

    while (team.parentRef && !teamVisibilty[team.parentRef] && !!teamsByRef[team.parentRef]) {
      team = teamsByRef[team.parentRef];
      teamVisibilty[team.ref] = true;
    }
  }

  return teams.filter((team) => teamVisibilty[team.ref]);
};

export const TeamsPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState<TeamsFilter>('ALL');

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const editableTeams = useMemo(() => {
    return teams?.filter((team) => team.ref !== CATCH_ALL_TEAM_REF);
  }, [teams]);

  const visibleTeams = useMemo(() => {
    if (!editableTeams) {
      return [];
    }

    return filterTeamsInHierarchy(editableTeams, (team) => {
      // ingore "Unspecified" team
      if (team.ref === CATCH_ALL_TEAM_REF) {
        return false;
      }

      // apply search query
      if (searchQuery && !team.value.toLowerCase().includes(searchQuery.toLowerCase())) {
        return false;
      }

      // apply filter
      if (filter === 'ALL') {
        return true;
      } else if (filter === 'HIDDEN') {
        return team.isHidden;
      } else if (filter === 'DEVEX') {
        return !team.isEmp;
      }

      return false;
    });
  }, [editableTeams, searchQuery, filter]);

  const teamsFilterOptions = useMemo(() => {
    if (!editableTeams) {
      return [];
    }

    const optionKeys: TeamsFilter[] = ['ALL'];
    if (editableTeams.some((team) => team.isHidden)) {
      optionKeys.push('HIDDEN');
    }
    if (editableTeams.some((team) => !team.isEmp) && window.dx.user?.company.isEmp) {
      optionKeys.push('DEVEX');
    }

    return optionKeys.map((key) => ({ key, label: TEAMS_FILTER_LABELS[key] }));
  }, [editableTeams]);

  const areAnyTeamsEditable = !!editableTeams?.length;
  const areAnyTeamsVisible = !!visibleTeams?.length;
  const isWaitingOnSync = !areAnyTeamsEditable && window.dx.user?.company.isEmp;
  const isFlatTeamHierarchy = !editableTeams?.some((team) => team.parentRef);

  return (
    <Flex vertical align="center" style={{ flex: 1 }}>
      <DevExPageTitle>DevEx Teams</DevExPageTitle>
      <DevExPageHeader
        title="Teams"
        subtitle="Manage the teams available for targeting in surveys. Teams that are hidden will be excluded from targeting selection."
      />

      <DevExPageLayout style={{ flex: 1 }}>
        {!teams ? (
          <DevExLoader />
        ) : (
          <>
            {areAnyTeamsEditable && (
              <Flex justify="space-between">
                {teamsFilterOptions.length > 1 ? (
                  <DevExRadioButtons
                    value={filter}
                    onChange={(value: TeamsFilter) => setFilter(value)}
                    items={teamsFilterOptions}
                  />
                ) : (
                  <div />
                )}

                <DevExSearch
                  placeholder="Search for a team"
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </Flex>
            )}

            {isWaitingOnSync && (
              <DevExExpandableAlert title="Your teams will be synchronized from Jellyfish after creating your first survey." />
            )}

            {!isWaitingOnSync && (
              <EditableTeamHierarchy teams={visibleTeams} isFlat={isFlatTeamHierarchy} />
            )}

            {areAnyTeamsEditable && !areAnyTeamsVisible && (
              <DevExEmpty icon={faUsers} label="No teams match your filter and search." />
            )}
          </>
        )}
      </DevExPageLayout>
    </Flex>
  );
};
