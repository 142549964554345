import { useSearchParams } from 'react-router-dom';

import { Study, StudyClient } from 'jf/api';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientQuery } from 'jf/utils/useClientQuery';

type UseCanViewSurveyResultsProps = {
  studyRef: string | undefined;
};

export const useCanViewSurveyResults = ({ studyRef }: UseCanViewSurveyResultsProps): boolean => {
  const flags = useClientFlags();
  const [searchParams] = useSearchParams();
  const scopeToken = searchParams.get('scope_token');

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  const isClosed = study?.status === Study.status.CLOSED;
  const isOpen = study?.status === Study.status.OPEN;

  // We currently can't do FF tracking on scoped auth, we will assume if they are on
  // Assume if they are scoped auth with isOpen that they got that link from someone with the viewOpenSurveyResults flag
  const isViewingSharedOpenSurvey = scopeToken && isOpen;

  const canViewSurveyResults = flags.viewOpenSurveyResults || isViewingSharedOpenSurvey || isClosed;

  return canViewSurveyResults;
};
