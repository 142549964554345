import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { faUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MotionValue } from 'framer-motion';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';

import { FreqDistBar } from '../insights/FreqDistBar';
import { ScoreBlock } from '../ScoreBlock';

import { MotionDiv } from './MotionDiv';

const styles = {
  card: css`
    width: 1000px;
    height: 200px;
    padding-inline-start: ${(props) => props.theme.variable.spacing.lg};
    background-color: transparent;
    border-color: ${(props) => props.theme.color.reveal.scores.background};

    .header__icon {
      font-size: 100px;
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-weight: bold;
      color: ${(props) => props.theme.color.reveal.scores.text};
    }

    .header__title {
      font-size: 58px;
      color: ${(props) => props.theme.color.reveal.scores.text};
    }

    .header__subtitle {
      font-size: 22px;
      color: ${(props) => props.theme.color.reveal.scores.secondary};
    }
  `,
};

type RevealCardProps = {
  rank: number;
  title?: ReactNode;
  subtitle?: ReactNode;
  votes?: ReactNode;
  scrollYProgress: MotionValue<number>;
  icon: number;
  score: number;
  dist: number[];
};

export const RevealCard: React.FC<RevealCardProps> = (props) => {
  const theme = useDevExTheme();

  const rankVariants = {
    visible: { x: '-100vh', transition: { duration: 0.05 } },
    hidden: { x: '0', transition: { duration: 0.05 } },
  };

  const scoreVariants = {
    visible: { x: '100px', opacity: 1, transition: { duration: 0.05 } },
    hidden: { x: '120px', opacity: 0, transition: { duration: 0.05 } },
  };

  const cardXVariants = {
    visible: { x: '-120px', transition: { duration: 0.05 } },
    hidden: { x: '0', transition: { duration: 0.05 } },
  };

  const subtitleVariants = {
    visible: { opacity: 0, transition: { duration: 0.05 } },
    hidden: { opacity: 1, transition: { duration: 0.05 } },
  };

  const verbatimScaleVariants = {
    visible: { scale: '0%', transition: { duration: 0.05 } },
    hidden: { scale: '100%', transition: { duration: 0.05 } },
  };

  const cardScaleVariants = {
    visible: { scale: '60%', transition: { duration: 0.05 } },
    hidden: { scale: '100%', transition: { duration: 0.05 } },
  };

  const graphVariants = {
    visible: { opacity: 1, x: '30%', transition: { duration: 0.05 } },
    hidden: { opacity: 0, x: '20%', transition: { duration: 0.05 } },
  };

  return (
    <Flex>
      <DevExCard css={styles.card} style={{ paddingBottom: theme.variable.spacing.lg }}>
        <Flex>
          {!!props.icon && (
            <Flex
              className={'header__icon'}
              style={{
                width: theme.variable.spacing.xxl,
                paddingInlineEnd: theme.variable.spacing.xxl,
              }}
            >
              <MotionDiv
                variants={rankVariants}
                scrollYProgress={props.scrollYProgress}
                fadeIn={25}
              >
                {props.icon}
              </MotionDiv>
            </Flex>
          )}
          <MotionDiv variants={cardXVariants} scrollYProgress={props.scrollYProgress} fadeIn={25}>
            <Flex vertical gap={theme.variable.spacing.md} style={{ width: '100%' }}>
              {!!props.title && (
                <MotionDiv
                  variants={cardScaleVariants}
                  style={{ transformOrigin: 'top left' }}
                  scrollYProgress={props.scrollYProgress}
                  fadeIn={45 + 7 * props.icon}
                >
                  <Flex align="center" justify="center">
                    <Flex
                      className={'header__title'}
                      justify="space-between"
                      align="center"
                      style={{ width: '700px' }}
                    >
                      <Flex flex="1 0 100%">
                        {props.title}
                        {!!props.votes && (
                          <Flex
                            style={{
                              fontSize: theme.variable.fontSize.xl,
                              paddingTop: theme.variable.spacing.md,
                              paddingInlineStart: theme.variable.spacing.md,
                            }}
                          >
                            <MotionDiv
                              variants={verbatimScaleVariants}
                              style={{ color: theme.color.reveal.scores.secondary }}
                              scrollYProgress={props.scrollYProgress}
                              fadeIn={45 + 7 * props.icon}
                            >
                              <FontAwesomeIcon
                                icon={faUp}
                                style={{
                                  paddingInline: theme.variable.spacing.sm,
                                }}
                              />
                              {props.votes}
                            </MotionDiv>
                          </Flex>
                        )}
                      </Flex>
                      <MotionDiv
                        variants={scoreVariants}
                        scrollYProgress={props.scrollYProgress}
                        fadeIn={25}
                      >
                        <ScoreBlock score={props.score} size="xl" />
                      </MotionDiv>
                    </Flex>
                    <MotionDiv
                      variants={graphVariants}
                      style={{ width: '800px' }}
                      scrollYProgress={props.scrollYProgress}
                      fadeIn={70 + props.icon * 5}
                    >
                      <FreqDistBar freqDist={props.dist} />
                    </MotionDiv>
                  </Flex>
                </MotionDiv>
              )}

              {!!props.subtitle && (
                <MotionDiv
                  variants={subtitleVariants}
                  style={{ width: '700px', paddingInlineStart: theme.variable.spacing.sm }}
                  scrollYProgress={props.scrollYProgress}
                  fadeIn={45 + 7 * props.icon}
                  className={'header__subtitle'}
                >
                  {props.subtitle}
                </MotionDiv>
              )}
            </Flex>
          </MotionDiv>
        </Flex>
      </DevExCard>
    </Flex>
  );
};
