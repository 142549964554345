import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { StudiesTable, StudiesTableFilter } from 'jf/pages/studies/StudiesTable';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyCreator } from '../useStudyCreator';

export const ClosedStudies: React.FC = () => {
  const [searchParams] = useSearchParams();
  const theme = useDevExTheme();
  const studyCreator = useStudyCreator();

  const studyRef = searchParams.get('survey');

  const [study, setStudy] = useState<Study>();

  const { data: studies } = useClientQuery(StudyClient.getStudies);
  const { data: studyTeams } = useClientQuery(StudyClient.getStudyTeams, { studyRef: study?.ref });

  useEffect(() => {
    if (study && studyTeams) {
      trackEvent('survey-creator:past-surveys:clone', {
        ref: study.ref,
        name: study.name,
        closeDate: study.closeDate,
      });
      studyCreator.duplicate(study.ref);
    }
  }, [study, studyTeams]);

  // if a study ref is passed via query param, auto select it
  useEffect(() => {
    if (studyRef && studies) {
      setStudy(studies.filter((study) => study.ref === studyRef)[0]);
    }
  }, [studyRef]);

  return (
    <>
      <div style={{ fontSize: theme.variable.fontSize.lg }}>
        Select the past survey you'd like to use
      </div>
      <Flex vertical gap={theme.variable.spacing.md} style={{ width: '100%' }}>
        <StudiesTable
          filter={StudiesTableFilter.CLOSED}
          onRowClick={setStudy}
          columns={['name', 'status', 'last_updated', 'created_by']}
        />
      </Flex>
    </>
  );
};
