import React from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib';
import { css } from 'styled-components';

const styles = {
  drawer: css`
    &.ant-drawer-content {
      background-color: ${(props) => props.theme.color.background.overlay};

      .ant-drawer-header {
        border-color: ${(props) => props.theme.color.border.primary};

        .ant-drawer-title {
          color: inherit;
          font-size: ${(props) => props.theme.variable.fontSize.lg};
          font-family: ${(props) => props.theme.variable.fontFamily.secondary};
          line-height: 1;
        }
      }
    }
  `,
};

interface DevExDrawerProps extends DrawerProps {
  onCloseComplete?: () => void;
}

export const DevExDrawer: React.FC<DevExDrawerProps> = (props) => {
  return (
    <Drawer
      css={styles.drawer}
      closable={false}
      {...props}
      afterOpenChange={(open) => !open && props.onCloseComplete?.()} // emit onCloseComplete after animation completes
    />
  );
};
