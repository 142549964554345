import React from 'react';
import { Flex } from 'antd';
import { faChartSimple } from '@fortawesome/pro-thin-svg-icons';
import { keyBy } from 'lodash-es';

import { StudyClient, StudyLibraryClient, StudyScoreClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { PromptScoreCard } from '../topicDrawer/PromptScoreCard';
import { useTextResponses } from '../topicDrawer/useTextResponses';
import { useCanViewSurveyResults } from '../useCanViewSurveyResults';
import { useStudyScores } from '../useStudyScores';

const HISTOGRAM_HEIGHT = 270;

type PromptResultsProps = {
  studyRef: string | undefined;
  teamRef?: string;
  promptSlug: string | undefined;
};

export const PromptResults: React.FC<PromptResultsProps> = (props) => {
  const theme = useDevExTheme();

  const { data: study, isLoading: studyIsLoading } = useClientQuery(StudyClient.getStudy, {
    studyRef: props.studyRef,
  });
  const { data: prompts, isLoading: promptsAreLoading } = useClientQuery(
    StudyLibraryClient.getPrompts
  );
  const { data: topics, isLoading: topicsAreLoading } = useClientQuery(
    StudyLibraryClient.getTopics
  );
  const { data: promptScores, isLoading: promptScoresAreLoading } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: 'PROMPT',
  });
  const { data: textResponses, isLoading: textResponsesAreLoading } = useTextResponses({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  const promptScore = promptScores?.find((score) => score.slug === props.promptSlug);
  const textResponse = textResponses?.filter(
    (textResponse) => textResponse.prompt === props.promptSlug
  );

  const promptsBySlug = keyBy(prompts, 'slug');
  const topicsBySlug = keyBy(topics, 'slug');

  const prompt = props.promptSlug && promptsBySlug[props.promptSlug];
  const topic = prompt && topicsBySlug[prompt.topicSlug];

  const { data: summary, isLoading: summaryIsLoading } = useClientQuery(
    StudyScoreClient.getStudyScoresTextual,
    {
      studyRef: props.studyRef,
      topicSlug: topic && topic.slug,
    }
  );

  const promptIndex = study?.prompts.findIndex((prompt) => prompt.slug === props.promptSlug) ?? 0;

  const shouldShowSurveyResults = useCanViewSurveyResults({ studyRef: props.studyRef });

  const isLoading =
    studyIsLoading ||
    promptsAreLoading ||
    topicsAreLoading ||
    promptScoresAreLoading ||
    textResponsesAreLoading ||
    summaryIsLoading;

  return (
    <Flex
      vertical
      gap={theme.variable.spacing.md}
      style={{ flex: 1, paddingTop: theme.variable.spacing.sm }}
    >
      <Flex align="center" justify="space-between" style={{ height: 24 }}>
        <DevExText type="secondary" loading={!prompt || !study} loadingWidth={120}>
          Question {promptIndex + 1} / {study?.prompts.length}
        </DevExText>

        {prompt ? (
          !!topic && (
            <Flex justify="end">
              <DevExTag>{topic.label}</DevExTag>
            </Flex>
          )
        ) : (
          <DevExSkeleton width={120} height={24} />
        )}
      </Flex>

      {isLoading ? (
        <DevExLoader />
      ) : shouldShowSurveyResults && prompt && promptScores && promptScore ? (
        <PromptScoreCard score={promptScore} promptSlug={prompt.slug} />
      ) : shouldShowSurveyResults && prompt && textResponses && textResponse?.length ? (
        <PromptScoreCard textResponses={textResponse} summary={summary} promptSlug={prompt.slug} />
      ) : (
        <DevExCard
          style={{ height: HISTOGRAM_HEIGHT }}
          bodyStyle={{ justifyContent: 'center', height: '100%' }}
        >
          <DevExEmpty
            icon={faChartSimple}
            label="Your results will be available after the survey is closed"
          />
        </DevExCard>
      )}
    </Flex>
  );
};
