/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SlackEntity = {
    id: string;
    name: string;
    /**
     * * `user` - USER
     * * `group` - GROUP
     * * `channel` - CHANNEL
     */
    type: SlackEntity.type;
    displayName?: string | null;
    image?: string | null;
};

export namespace SlackEntity {

    /**
     * * `user` - USER
     * * `group` - GROUP
     * * `channel` - CHANNEL
     */
    export enum type {
        USER = 'user',
        GROUP = 'group',
        CHANNEL = 'channel',
    }


}

