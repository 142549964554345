import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Flex, notification, Pagination, Tooltip } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowRightFromFile,
  faArrowUpRightFromSquare,
  faTrash,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, partition, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackEntity, SlackTargetingClient, StudyClient, StudySlackTarget } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExCheckbox } from 'jf/components/DevExCheckbox';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSearch } from 'jf/components/DevExSearch';
import { DevExSelect, DevExSelectProps } from 'jf/components/DevExSelect';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { exportCSV } from 'jf/pages/studyAnalyzer/export/ExportStudyModal';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';
import { useLivePropState } from 'jf/utils/useLivePropState';

import { RosterTargetDrawer } from './RosterTargetDrawer';
import { SlackEntityDisplay } from './SlackEntityDisplay';
import { SlackTargetDrawer } from './SlackTargetDrawer';

const styles = {
  select: css`
    &&&&.ant-select {
      min-width: none;
      .ant-select-selector {
        border-radius: ${(props) => props.theme.variable.borderRadius} !important;
        padding-inline: ${(props) => props.theme.variable.spacing.lg};
        font-size: ${(props) => props.theme.variable.fontSize.sm};
      }
    }
  `,
};

const COLLAPSED_TARGET_COUNT = 10;

const SHOW_COUNT = 10;

type SlackTargetEditorProps = {
  size?: DevExSelectProps['size'];
  slackTargets: StudySlackTarget[];
  onChange: (slackTargets: StudySlackTarget[]) => void;
  eventCategory: 'survey-analyzer' | 'survey-editor';
  preventDeletion?: boolean; // prevent deleting initial slack targets
};

export const SlackTargetEditor: React.FC<SlackTargetEditorProps> = (props) => {
  const theme = useDevExTheme();
  const flags = useClientFlags();

  const [initialSlackTargets] = useState(props.preventDeletion ? props.slackTargets : []);
  const [slackTargets, setSlackTargets] = useLivePropState(props.slackTargets, props.onChange);
  const [slackDrawerOpen, setSlackDrawerOpen] = useState(false);
  const [showWarning, setShowWarning] = useLocalStorage('showWarning', false);

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [isOldCollapsed, setIsOldCollapsed] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const { studyRef: studyRefParam } = useParams<{ studyRef: string }>();
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: studyRefParam });
  const [duplicates, setDuplicates] = useState(0);

  const addOptions = [
    { value: '1', label: 'Add people from your roster' },
    { value: '2', label: 'Add people from Slack directly' },
  ];
  const { data: slackEntities, isFetching } = useClientQuery(
    SlackTargetingClient.getSlackEntities,
    { filter: debouncedSearchQuery },
    { enabled: debouncedSearchQuery.length >= 1 }
  );

  const { mutateAsync: expandSlackEntity, isLoading: isExpanding } = useClientMutation(
    SlackTargetingClient.expandSlackEntity
  );

  const debounceFiredSearchQuery = useMemo(() => debounce(setDebouncedSearchQuery, 300), []);

  useEffect(() => {
    if (searchQuery) {
      debounceFiredSearchQuery(searchQuery);
    } else {
      debounceFiredSearchQuery.cancel();
      setDebouncedSearchQuery('');
    }
  }, [searchQuery]);

  const isSearching = searchQuery !== debouncedSearchQuery || isFetching;

  const slackEntityOptions = useMemo(() => {
    return sortBy(slackEntities, (entity) => entity.name.toLowerCase()).map((entity) => ({
      value: entity.id,
      label: (
        <Flex gap={theme.variable.spacing.sm} align="center">
          <Avatar
            shape={'square'}
            style={{ backgroundColor: theme.color.background.active }}
            size="large"
            src={entity.image ?? <FontAwesomeIcon icon={faUsers} />}
          />
          <Flex vertical>
            {entity.displayName}
            <div style={{ color: theme.color.text.secondary }}>
              {' '}
              {`${entity.type === SlackEntity.type.CHANNEL ? '#' : '@'}${entity.name}`}
            </div>
          </Flex>
        </Flex>
      ),
    }));
  }, [slackEntities]);

  const sortedSlackTargets = sortBy(slackTargets, (target) => target.displayName?.toLowerCase());

  // if props.preventDeletion is set, targets will be separated into "old" and "new" targets
  // if not, all targets will be considered "new"
  let [oldSlackTargets, newSlackTargets] = partition(sortedSlackTargets, (target) =>
    initialSlackTargets.find((t) => t.id === target.id)
  );
  const startIndex = (currentPage - 1) * SHOW_COUNT;
  const endIndex = startIndex + SHOW_COUNT;
  const filteredResponsesByPage = newSlackTargets?.slice(startIndex, endIndex);
  const [rosterDrawerOpen, setRosterDrawerOpen] = useState(false);

  const exportToCSV = (targets: StudySlackTarget[]) => {
    if (!targets || targets.length === 0) {
      return;
    }
    const filteredTargets = targets.map(({ id: _, ...target }) => target);
    const headerKeys = Object.keys(filteredTargets[0]);
    const headers = headerKeys.join(',');
    const csvData = [[headers]];

    for (const target of targets) {
      const dataRow = [headerKeys.map((h) => target[h]).join(',')];
      csvData.push(dataRow);
    }
    const fileNameParts = [
      study!.name,
      'Respondents',
      DateTime.now().toLocaleString(DateTime.DATE_SHORT).replaceAll('/', '-'),
    ];
    exportCSV(csvData, fileNameParts);
  };
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    !showWarning &&
      !slackDrawerOpen &&
      api.info({
        message: (
          <Flex vertical gap={theme.variable.spacing.md}>
            {duplicates} {duplicates === 1 ? 'person was' : 'people were'} not added because they
            were already included in your list
            <Flex gap={theme.variable.spacing.sm}>
              <DevExCheckbox onChange={() => setShowWarning(!showWarning)} /> Don't warn me again
            </Flex>
          </Flex>
        ),
        placement: 'topRight',
        duration: 0,
        onClose: () => notification.destroy(),
      });
  };

  useEffect(() => {
    if (duplicates > 0) {
      openNotification();
    }
    setDuplicates(0);
  }, [duplicates, slackTargets]);

  return (
    <Flex vertical gap={theme.variable.spacing.md}>
      {contextHolder}
      {flags.largeOrgSlackTargeting && (
        <Flex justify="space-between" align="center">
          <strong>
            {slackTargets.length} respondent{slackTargets.length === 1 ? '' : 's'} selected
          </strong>
          <Flex gap={theme.variable.spacing.sm} justify="right" align="center">
            {!!newSlackTargets.length && !isExpanding && (
              <DevExButton
                size="small"
                type="text"
                onClick={() => setSlackTargets(props.preventDeletion ? initialSlackTargets : [])}
                style={{ marginLeft: `-${theme.variable.spacing.sm}` }}
              >
                Remove all
              </DevExButton>
            )}
            <Tooltip title={'Search for a name, an email, or a Slack @username'}>
              <div>
                <DevExSearch
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  collapsible
                  outline
                />
              </div>
            </Tooltip>
            <Tooltip title={'Export the list of respondents to a CSV'}>
              <DevExButton
                size="large"
                type="outline"
                icon={<FontAwesomeIcon icon={faArrowRightFromFile} />}
                onClick={() => exportToCSV(slackTargets)}
                disabled={slackTargets.length === 0}
              />
            </Tooltip>
            <DevExSelect
              style={{ minWidth: 40 }}
              size="large"
              css={styles.select}
              placeholder={'Add'}
              options={addOptions}
              onChange={(value) => {
                if (value === '1') {
                  setRosterDrawerOpen(true);
                } else {
                  setSlackDrawerOpen(true);
                }
              }}
              value={[]}
            />
          </Flex>
        </Flex>
      )}
      {flags.largeOrgSlackTargeting && (
        <RosterTargetDrawer
          onClose={() => setRosterDrawerOpen(false)}
          open={rosterDrawerOpen}
          addUsers={(users) =>
            /* TODO - Add duplicate check for notifications on add */
            setSlackTargets((targets) => {
              return [...targets, users]; // pseudocode
            })
          }
        />
      )}
      {!flags.largeOrgSlackTargeting && (
        <DevExSelect
          size={props.size}
          showSearch
          filterOption={() => true} // leave filtering to the BE only
          options={!isSearching ? slackEntityOptions : undefined}
          value={[]}
          onChange={(value) => {
            const entity = slackEntities?.find((entity) => entity.id === value);
            if (entity) {
              trackEvent(`${props.eventCategory}:target-respondent:add`, {
                id: entity.id,
                type: entity.type,
              });

              expandSlackEntity({
                requestBody: {
                  entityId: entity.id,
                  entityType: entity.type,
                },
              }).then((newSlackTargets) => {
                setSlackTargets((slackTargets) => {
                  return [
                    ...slackTargets,
                    // filter out duplicates before adding
                    ...newSlackTargets.filter(
                      (newTarget) => !slackTargets.some((target) => target.id === newTarget.id)
                    ),
                  ];
                });
              });
            }

            setSearchQuery('');
          }}
          placeholder="Search for @users, @groups, and #channels"
          loading={isSearching}
          notFoundContent={
            isSearching ? (
              <DevExLoader tip="Searching Slack..." />
            ) : (
              <DevExEmpty
                icon={faSlack}
                label={
                  searchQuery
                    ? `No slack targets found matching "${searchQuery}".`
                    : 'Search for @users, @groups, and #channels in your Slack instance.'
                }
              />
            )
          }
          onSearch={setSearchQuery}
        />
      )}
      {flags.largeOrgSlackTargeting && (
        <Flex vertical align="center" gap={theme.variable.spacing.sm}>
          {newSlackTargets.length > 0 ? (
            <>
              {filteredResponsesByPage.map((target) => {
                return (
                  <Flex
                    style={{
                      width: '100%',
                      borderRadius: theme.variable.borderRadius,
                      border: `1px solid`,
                      borderColor: theme.color.border.primary,
                      padding: theme.variable.spacing.sm,
                    }}
                    justify="space-between"
                    key={target.id}
                  >
                    <SlackEntityDisplay
                      key={target.id}
                      name={target.name}
                      displayName={target.displayName}
                      image={target.image}
                    />
                    <Flex align="center">
                      <DevExButton
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => {
                          setSlackTargets((slackTargets) =>
                            slackTargets.filter(({ id }) => id !== target.id)
                          );
                          trackEvent(`${props.eventCategory}:target-user:remove`, {
                            id: target.id,
                          });
                        }}
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </>
          ) : (
            <DevExCard style={{ paddingInline: theme.variable.spacing.xxl }}>
              <DevExEmpty icon={faUser} iconSize={34} />
              <Flex
                style={{ textAlign: 'center', color: theme.color.text.secondary }}
                align="center"
                vertical
                gap={theme.variable.spacing.md}
              >
                <div>
                  {`Start adding Slack users by selecting them from your `}
                  <a
                    href={window.dx.user?.isCustomerAdmin ? `https://app.jellyfish.co/people` : ``}
                  >
                    Jellyfish roster{' '}
                    {window.dx.user?.isCustomerAdmin && (
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    )}
                  </a>
                  {` or searching for them in Slack.`}
                </div>
                <div>Click 'Add' above to begin.</div>
              </Flex>
            </DevExCard>
          )}
        </Flex>
      )}
      <Flex vertical gap={theme.variable.spacing.sm}>
        {!!oldSlackTargets.length && (
          <DevExText type="secondary" fontSize="xs">
            {oldSlackTargets.length} target respondent{oldSlackTargets.length === 1 ? '' : 's'}
          </DevExText>
        )}
        {flags.largeOrgSlackTargeting && (
          <Flex justify="center" gap={theme.variable.spacing.md}>
            {newSlackTargets.length > 8 && (
              <Pagination
                total={newSlackTargets?.length}
                pageSize={SHOW_COUNT}
                onChange={(page) => {
                  trackEvent('study-analyzer:open-text-results:pagination', {
                    page,
                  });
                  setCurrentPage(page);
                }}
                showSizeChanger={false}
              />
            )}
            <SlackTargetDrawer
              onClose={() => setSlackDrawerOpen(false)}
              open={slackDrawerOpen}
              slackTargets={props.slackTargets}
              onDuplicateChange={setDuplicates}
              onChange={props.onChange}
            />
          </Flex>
        )}

        {!flags.largeOrgSlackTargeting && (
          <>
            <Flex gap={theme.variable.spacing.sm} wrap="wrap" align="center">
              {oldSlackTargets
                .slice(0, isOldCollapsed ? COLLAPSED_TARGET_COUNT : undefined)
                .map((target) => (
                  <DevExTag key={target.id} color="purple">
                    <div>{target.displayName}</div>
                  </DevExTag>
                ))}
              {isOldCollapsed && oldSlackTargets.length > COLLAPSED_TARGET_COUNT && (
                <DevExButton
                  size="small"
                  type="text"
                  onClick={() => setIsOldCollapsed(false)}
                  style={{ marginLeft: `-${theme.variable.spacing.sm}` }}
                >
                  + {oldSlackTargets.length - COLLAPSED_TARGET_COUNT} others
                </DevExButton>
              )}
            </Flex>

            {!!newSlackTargets.length && (
              <DevExText type="secondary" fontSize="xs">
                {newSlackTargets.length}
                {props.preventDeletion ? ' new' : ''} target respondent
                {newSlackTargets.length === 1 ? '' : 's'}
              </DevExText>
            )}

            <Flex gap={theme.variable.spacing.sm} wrap="wrap" align="center">
              {newSlackTargets.map((target) => {
                return (
                  <DevExTag
                    key={target.id}
                    color="purple"
                    css={css`
                      &&&& {
                        padding-block: 0;
                        padding-right: ${theme.variable.spacing.xs};
                      }
                    `}
                  >
                    <Flex align="center">
                      {target.displayName ? target.displayName : target.name}
                      <DevExButton
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        size="small"
                        type="text"
                        onClick={() => {
                          setSlackTargets((slackTargets) =>
                            slackTargets.filter(({ id }) => id !== target.id)
                          );
                          trackEvent(`${props.eventCategory}:target-user:remove`, {
                            id: target.id,
                          });
                        }}
                      />
                    </Flex>
                  </DevExTag>
                );
              })}

              {isExpanding && <DevExTag>Adding targets...</DevExTag>}

              {!!newSlackTargets.length && !isExpanding && (
                <DevExButton
                  size="small"
                  type="text"
                  onClick={() => setSlackTargets(props.preventDeletion ? initialSlackTargets : [])}
                  style={{ marginLeft: `-${theme.variable.spacing.sm}` }}
                >
                  Clear all
                </DevExButton>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};
