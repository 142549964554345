import React from 'react';

import { DevExTheme, useDevExTheme } from 'jf/common/themes/DevExTheme';

import { DevExSkeleton } from './DevExSkeleton';

type DevExTextProps = {
  fontSize?: keyof DevExTheme['variable']['fontSize'];
  fontFamily?: keyof DevExTheme['variable']['fontFamily'];
  type?: 'primary' | 'secondary' | 'tertiary';
  loading?: boolean;
  loadingWidth?: string | number;
  strong?: boolean;
  italic?: boolean;
  lined?: boolean;
  ellipsis?: boolean;
  title?: string;
  style?: React.CSSProperties;
};

export const DevExText: React.FC<DevExTextProps> = (props) => {
  const theme = useDevExTheme();

  const fontSize = props.fontSize ?? 'sm';
  const fontFamily = props.fontFamily ?? 'primary';
  const type = props.type ?? 'primary';

  if (props.loading) {
    return (
      <DevExSkeleton
        fontSize={props.fontSize}
        width={props.loadingWidth}
        noLineHeight={!props.lined}
      />
    );
  }
  return (
    <div
      style={{
        fontSize: theme.variable.fontSize[fontSize],
        fontFamily: theme.variable.fontFamily[fontFamily],
        color: theme.color.text[type],
        fontWeight: props.strong ? 600 : 400,
        fontStyle: props.italic ? 'italic' : 'normal',
        lineHeight: props.lined ? theme.variable.lineHeight : 1,
        ...(props.ellipsis
          ? { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
          : {}),
        ...props.style,
      }}
      title={props.title}
    >
      {props.children}
    </div>
  );
};
