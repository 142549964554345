import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { differenceBy } from 'lodash-es';

import { RosterClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCheckbox } from 'jf/components/DevExCheckbox';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSearch } from 'jf/components/DevExSearch';
import { DevExText } from 'jf/components/DevExText';
import { CATCH_ALL_TEAM_REF } from 'jf/pages/study/question/QuestionCardDemographic';
import { filterTeamsInHierarchy } from 'jf/pages/teams/TeamsPage';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { DEFAULT_TEAM_DEMOGRAPHIC_TEXT } from '../customizeStudy/CustomizableDemographic';
import { useStudyBuilderState } from '../StudyBuilderState';

import { SelectableTeamHierarchy } from './SelectableTeamHierarchy';

export const SelectTeamsStep: React.FC = () => {
  const theme = useDevExTheme();
  const state = useStudyBuilderState();

  const [searchQuery, setSearchQuery] = useState('');

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const editableTeams = useMemo(() => {
    return teams?.filter((team) => team.ref !== CATCH_ALL_TEAM_REF);
  }, [teams]);

  const leafTeams = useMemo(() => {
    return editableTeams?.filter((team) => team.isLeaf);
  }, [editableTeams]);

  const visibleTeams = useMemo(() => {
    if (!editableTeams) {
      return [];
    }

    return filterTeamsInHierarchy(editableTeams, (team) => {
      // ingore "Unspecified" team
      if (team.ref === CATCH_ALL_TEAM_REF) {
        return false;
      }

      // apply search query
      if (searchQuery && !team.value.toLowerCase().includes(searchQuery.toLowerCase())) {
        return false;
      }

      return !team.isHidden;
    });
  }, [editableTeams, searchQuery]);

  const areAnyTeamsEditable = !!editableTeams?.length && !!leafTeams?.length;
  const areAnyTeamsVisible = !!visibleTeams?.length;

  return (
    <Flex
      vertical
      style={{ fontSize: theme.variable.fontSize.md, lineHeight: theme.variable.lineHeight }}
      gap={theme.variable.spacing.lg}
    >
      <div style={{ fontSize: theme.variable.fontSize.xl }}>Select Teams</div>

      {!teams ? (
        <DevExLoader />
      ) : (
        <>
          {areAnyTeamsEditable && (
            <div>
              When asked <em>"{DEFAULT_TEAM_DEMOGRAPHIC_TEXT}"</em>, respondents will be able to
              choose from the teams below. If a team is missing, you can edit teams on the{' '}
              <DevExLink href="/teams">Teams</DevExLink> page.
            </div>
          )}

          {areAnyTeamsEditable && (
            <Flex justify="space-between" align="center">
              <Flex align="center" gap={theme.variable.spacing.sm}>
                <DevExCheckbox
                  checked={state.studyTeams.length === leafTeams.length}
                  indeterminate={
                    !!state.studyTeams.length && state.studyTeams.length !== leafTeams.length
                  }
                  onChange={() => {
                    if (state.studyTeams.length === leafTeams.length) {
                      state.update({ studyTeams: [] });
                    } else {
                      state.update({
                        studyTeams: leafTeams.map((team) => ({ teamRef: team.ref })),
                      });
                    }
                  }}
                />
                <DevExText type="secondary">
                  {state.studyTeams.length} / {leafTeams.length} teams selected
                </DevExText>
              </Flex>
              <DevExSearch
                placeholder="Search for a team"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Flex>
          )}

          {!areAnyTeamsEditable && (
            <DevExEmpty
              icon={faUsers}
              label={
                <span>
                  To target your survey, please configure the teams in your organization. This
                  configuration can be managed on the <DevExLink href="/teams">Teams</DevExLink>{' '}
                  page.
                </span>
              }
            />
          )}

          <SelectableTeamHierarchy
            teams={visibleTeams}
            selectedTeamRefs={state.studyTeams.map((studyTeam) => studyTeam.teamRef)}
            onChangeSelectedTeamRefs={(teamRefs) => {
              // any selected team that is currently filtered out is static
              const staticStudyTeams = differenceBy(
                state.studyTeams,
                visibleTeams.map((team) => ({ teamRef: team.ref })),
                'teamRef'
              );
              state.update({
                studyTeams: [...staticStudyTeams, ...teamRefs.map((teamRef) => ({ teamRef }))],
              });
            }}
          />

          {areAnyTeamsEditable && !areAnyTeamsVisible && (
            <DevExEmpty icon={faUsers} label="No teams match your search." />
          )}
        </>
      )}
    </Flex>
  );
};
