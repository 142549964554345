import { useQueryClient } from 'react-query';

import { useStudyBuilderState } from '../StudyBuilderState';

import { useStudyTeamsUpdater } from './useStudyTeamsUpdater';

export const useCompleteSelectTeamsStep = () => {
  const state = useStudyBuilderState();
  const studyTeamsUpdater = useStudyTeamsUpdater(state.studyRef);
  const queryClient = useQueryClient();

  const complete = async () => {
    return studyTeamsUpdater.update(state.studyTeams).then(() => {
      queryClient.invalidateQueries(['GET_PUBLIC_STUDY', state.studyRef]);
    });
  };

  return complete;
};
