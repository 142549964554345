import React from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import classNames from 'classnames';
import { css } from 'styled-components';

const styles = {
  textArea: css`
    &.ant-input {
      background: ${(props) => props.theme.color.background.default};
      border-color: ${(props) => props.theme.color.border.primary};
      color: ${(props) => props.theme.color.text.primary};
      border-radius: ${(props) => props.theme.variable.borderRadius};
      line-height: ${(props) => props.theme.variable.lineHeight};
      transition: opacity 0.25s ease-in-out;

      &::placeholder {
        color: ${(props) => props.theme.color.text.tertiary};
      }

      &:focus {
        border-color: ${(props) => props.theme.color.border.hovered};
      }
    }
    &.input--rightAlign {
      textarea {
        text-align: right;
      }
    }
    &.input--embedded {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding: 0;
      height: auto;
      background: none;
    }

    &.input--showCountOnFocus:not(:has(textarea:focus)) {
      .ant-input-suffix {
          opacity: 0;
        }
      }
    }
    .ant-input-suffix {
      opacity: 1;
      .ant-input-data-count {
        font-size: ${(props) => props.theme.variable.fontSize.xs};
        color: ${(props) => props.theme.color.text.tertiary};
      }
    }
  `,
};

interface DevExTextAreaProps extends Omit<TextAreaProps, 'onChange'> {
  onChange?: (value: string) => void;
  embedded?: boolean; // mimicking ant v5's variant=underlined property
  rightAlign?: boolean; // aligns text in the input to the right
  showCountOnFocus?: boolean; // sets showCount on the antD textarea, but only shows it when input is focused
}

export const DevExTextArea: React.FC<DevExTextAreaProps> = ({
  embedded,
  rightAlign,
  showCountOnFocus,
  ...props
}) => {
  const className = classNames(props.className, {
    'input--embedded': embedded,
    'input--rightAlign': rightAlign,
    'input--showCountOnFocus': showCountOnFocus,
  });
  let showCount = props.showCount || showCountOnFocus;
  return (
    <Input.TextArea
      css={styles.textArea}
      className={className}
      showCount={showCount}
      {...props}
      onChange={(event) => props.onChange?.(event.target.value)}
    />
  );
};
