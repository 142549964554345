import { useFlags } from 'launchdarkly-react-client-sdk';

/**
 * Get DevEx feature flags.
 */
export const useClientFlags = () => {
  const flags = useFlags();

  const getFlag = (key: string) => !!flags[key];

  return {
    studyReveal: getFlag('bakunawaDevexSurveyRevealQ324'),
    metricCards: getFlag('bakunawaDevexMetricsCardsQ324'),
    actionItems: getFlag('bakunawaDevexActionItemsQ424'),
    slackTargeting: getFlag('bakunawaDevexSlackTargetingQ424'),
    largeOrgSlackTargeting: getFlag('bakunawaLargeOrgSlackTargetingQ125'),
    globalTeamsPage: getFlag('bakunawaDevexGlobalTeamsPageQ125'),
    openTextQuestion: getFlag('bakunawaDevexOpenTextQuestionQ125'),
    viewOpenSurveyResults: getFlag('bakunawaDevexCanViewOpenSurveyResultsQ125'),
    overallStudyScore: getFlag('bakunawaOverallScoreQ125'),
  };
};
