import React, { ReactNode, useEffect, useState } from 'react';
import { Cascader, Flex } from 'antd';
import { faEmptySet } from '@fortawesome/pro-thin-svg-icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/themes/DevExTheme';

import { DevExEmpty } from './DevExEmpty';

type DevExCascaderOption = {
  value: string;
  label: string;
  disabled?: boolean;
  children?: DevExCascaderOption[];
};

const cascaderStyle = css`
  &&&&.ant-cascader {
    &:hover .ant-select-clear {
      background: ${(props) => props.theme.color.background.overlay};
    }
`;

export type DevExCascaderProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  options?: DevExCascaderOption[];
  disabled?: boolean;
  placeholder?: ReactNode;
  allowClear?: boolean;
  size?: SizeType;
};

export const DevExCascader: React.FC<DevExCascaderProps> = (props) => {
  const theme = useDevExTheme();

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const placeholder = props.placeholder && (
    <Flex gap={theme.variable.spacing.xs} align="center">
      {props.placeholder}
    </Flex>
  );

  return (
    <Cascader
      css={cascaderStyle}
      value={value}
      onChange={(value: string[]) => {
        setValue(value);
        props.onChange?.(value);
      }}
      options={props.options}
      placeholder={placeholder}
      dropdownMatchSelectWidth={false}
      notFoundContent={<DevExEmpty icon={faEmptySet} label="No items found." />}
      disabled={props.disabled}
      allowClear={props.allowClear}
      expandTrigger="hover"
      style={{ width: 'fit-content' }}
      size={props.size}
    />
  );
};
