import React, { useEffect, useState } from 'react';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash-es';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyScoreClient } from 'jf/api';
import { DevExCascader } from 'jf/components/DevExCascader';
import { useClientQuery } from 'jf/utils/useClientQuery';

type TeamCascaderProps = {
  studyRef: string | undefined;
  value?: string;
  onChange?: (value?: string) => void;
  allowClear?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

export const TeamCascader: React.FC<TeamCascaderProps> = (props) => {
  const [value, setValue] = useState<string[]>();

  const { data: scoredTeams } = useClientQuery(StudyScoreClient.getStudyScoredTeams, {
    studyRef: props.studyRef,
  });

  const teamOptions = Object.entries(groupBy(scoredTeams, 'level')).map(([level, teams]) => ({
    value: level.toString(),
    label: teams[0].levelName,
    children: teams.map((team) => ({ value: team.ref, label: team.value })),
  }));

  // use props.value (a team ref) to set cascader's value (which includes that team's level)
  useEffect(() => {
    if (scoredTeams && props.value) {
      const team = scoredTeams?.find((team) => team.ref === props.value);
      if (team) {
        setValue([team.level.toString(), team.ref]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoredTeams]);

  return (
    <DevExCascader
      placeholder={
        <>
          <FontAwesomeIcon icon={faUsers} />
          {props.placeholder ? props.placeholder : 'Team'}
        </>
      }
      options={teamOptions}
      value={value}
      onChange={(value) => {
        setValue(value);

        const teamRef = value?.at(-1);
        props.onChange?.(teamRef);

        if (teamRef && scoredTeams) {
          const team = scoredTeams.find((team) => team.ref === teamRef);
          if (team) {
            trackEvent('survey-analyzer:team-selector:click', {
              teamLevel: team.level,
              isLeaf: team.isLeaf,
            });
          }
        }
      }}
      allowClear={props.allowClear}
      disabled={props.disabled}
    />
  );
};
