import React, { useEffect, useState } from 'react';
import { Col, Divider, Flex, Row } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { faExternalLink, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { RosterClient, TargetRespondent } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExDrawer } from 'jf/components/DevExDrawer';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExLink } from 'jf/components/DevExLink';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSearch } from 'jf/components/DevExSearch';
import { DevExSelect } from 'jf/components/DevExSelect';
import { DevExText } from 'jf/components/DevExText';
import { Filter, FilterMultiSelect } from 'jf/components/FilterMultiSelect';
import { getDevExEnv } from 'jf/utils/getDevExEnv';
import { useClientQuery } from 'jf/utils/useClientQuery';

type RosterTargetDrawerProps = {
  open: boolean;
  onClose: () => void;
  addUsers: (user: any) => void;
};

const RosterTargetDrawerStyles = css`
  &.roster__filter {
    .filter__header {
      align-items: center;
      .header__dropdown {
        display: flex;
        justify-content: flex-end;
      }
    }
    .filter__content {
      padding: ${({ theme }) => theme.variable.spacing.md};
      border: 1px solid ${({ theme }) => theme.color.border.secondary};
      border-radius: ${({ theme }) => theme.variable.borderRadius};
      align-items: center;
      gap: ${({ theme }) => theme.variable.spacing.xs};
    }
    .filter__submit {
      justify-content: flex-end;
    }
    .filter__results {
      justify-content: space-between;
      align-items: center;
    }
    .filter__empty {
      border: 1px solid ${({ theme }) => theme.color.border.secondary};
      border-radius: ${({ theme }) => theme.variable.borderRadius};
      padding: ${({ theme }) => theme.variable.spacing.lg};
      text-align: center;
    }
  }
`;

export const RosterTargetDrawer: React.FC<RosterTargetDrawerProps> = ({
  addUsers,
  open,
  onClose,
}) => {
  const theme = useDevExTheme();
  const [users, setUsers] = useState<any[]>([]);
  const { studyRef } = useParams<{ studyRef: string }>();

  const queueUser = (user) => {
    const newusers = [...users];
    const userindex = users.findIndex(user);
    if (userindex !== -1) {
      newusers.splice(userindex, 1);
    } else {
      newusers.push(user);
    }
    setUsers(newusers);
  };

  const [filteredSlackUsers, setFilteredSlackUsers] = useState<TargetRespondent[]>([]);
  const [enabledFilters, setEnabledFilters] = useState<Filter[]>([]);
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({});
  const [searchQuery, setSearchQuery] = useState('');

  const rosterFilters: { [key: string]: Filter } = {
    classification: {
      options: [
        { value: 'engineer', label: 'Engineer' },
        { value: 'engineering-manager', label: 'Engineering Manager' },
        { value: 'team-lead', label: 'Team Lead' },
      ],
      type: 'categorical',
      value: 'classification',
      label: 'Classification',
      conditionText: 'are classified as',
    },
    jobtitle: {
      options: [
        { value: 'frontend-engineer', label: 'Frontend Engineer' },
        { value: 'backend-engineer', label: 'Backend Engineer' },
        { value: 'sales-engineer', label: 'Sales Engineer' },
      ],
      type: 'categorical',
      value: 'jobtitle',
      label: 'Job Title',
      conditionText: 'have job title',
    },
    location: {
      options: [
        { value: 'boston', label: 'Boston' },
        { value: 'new-york', label: 'New York' },
        { value: 'san-diego', label: 'San Diego' },
      ],
      type: 'categorical',
      value: 'location',
      label: 'Location',
      conditionText: 'work in',
    },
  };
  const userFilters: { value: string; label: string }[] = Object.keys(rosterFilters).map((f) => ({
    value: f,
    label: rosterFilters[f].label,
  }));

  const {
    data: rosterUsers,
    isLoading: isLoadingSlackUsers,
    refetch: refetchSlackUsers,
  } = useClientQuery(RosterClient.getStudyTargets, { studyRef }, { enabled: false }); // placeholder route

  const waitingForSync = false; // should come from the new route somehow

  useEffect(() => {
    // reset users and search whenever we update filters and get new users
    if (rosterUsers) {
      let filteredusers = [...rosterUsers];
      Object.keys(activeFilters).forEach((f) => {
        // const filter = rosterFilters[f];
        const values = activeFilters[f];
        filteredusers = filteredusers.reduce((users, user) => {
          if (values.find((v) => v === user[f])) {
            users.push(user);
          }
          return users;
        }, [] as any[]); // replace with typing
      });
      setFilteredSlackUsers(filteredusers);
    } else {
      setFilteredSlackUsers([]);
    }
  }, [rosterUsers]);

  return (
    <DevExDrawer
      title="Add people from your roster"
      open={open}
      onClose={onClose}
      width={640}
      closable
      extra={
        <>
          <DevExButton onClick={onClose}>Cancel</DevExButton>
          <DevExButton
            type="primary"
            onClick={() => {
              addUsers(users);
              onClose();
            }}
            disabled={!users.length}
          >
            Add
          </DevExButton>
        </>
      }
    >
      <Flex
        className="roster__filter"
        vertical
        gap={theme.variable.spacing.md}
        css={RosterTargetDrawerStyles}
      >
        <Row className="filter__header">
          <Col span={22}>
            <DevExText type="secondary" lined>
              Add people from your {/* TODO - Needs to be permission gated */}
              {window.dx.user?.isCustomerAdmin ? (
                <>
                  <DevExLink href={`${getDevExEnv().appOrigin}/people`} target="_blank">
                    Jellyfish roster <FontAwesomeIcon icon={faExternalLink} />
                  </DevExLink>
                  .
                </>
              ) : (
                'Jellyfish roster.'
              )}{' '}
              You can filter their attributes to select the respondents that are relevant to your
              survey.
            </DevExText>
          </Col>
          <Col className="header__dropdown" span={2}>
            <FilterMultiSelect
              options={userFilters}
              enabledCount={enabledFilters.length}
              onChange={(keys) => {
                const _rosterfilters = { ...rosterFilters };
                const tmpEnabled: Filter[] = [];
                keys.forEach((k) => {
                  tmpEnabled.push(_rosterfilters[k]);
                });

                setEnabledFilters(tmpEnabled);
              }}
            />
          </Col>
        </Row>
        <Row className="filter__content">
          <DevExText>Find people from my Jellyfish Roster</DevExText>
          {!!enabledFilters?.length && <DevExText>that</DevExText>}
          {enabledFilters &&
            enabledFilters.map((f, i) => (
              <>
                <DevExText>{f.conditionText}</DevExText>
                <DevExSelect
                  variant="borderless"
                  mode="multiple"
                  style={{ borderBottom: `1px solid ${theme.color.brand.active}` }}
                  options={f.options}
                  placeholder={`any ${f.label.toLowerCase()}`}
                  onChange={(values) => {
                    setActiveFilters({ ...activeFilters, [f.value]: values });
                  }}
                />
                {i !== enabledFilters.length - 1 && <DevExText>AND</DevExText>}
              </>
            ))}
        </Row>
        <Row className="filter__submit">
          <DevExButton
            icon={<FontAwesomeIcon icon={faSlack} />}
            type="outline"
            iconPosition="end"
            onClick={() => {
              refetchSlackUsers();
            }}
          >
            Find in Slack
          </DevExButton>
        </Row>
        <Divider />
        <Row className="filter__results">
          <span>
            {rosterUsers?.length || 0} Slack user{rosterUsers?.length !== 1 ? 's' : ''} found
          </span>
          <DevExSearch
            placeholder="Search for a user"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            collapsible
          />
        </Row>
        {!!filteredSlackUsers.length && !isLoadingSlackUsers ? (
          filteredSlackUsers.map((user) => (
            <>
              {/* TODO - Replace with user cards */}
              {user.name} <DevExButton onClick={() => queueUser(user)}>Add</DevExButton>
            </>
          ))
        ) : (
          <div className="filter__empty">
            <Flex vertical justify="center" gap={theme.variable.spacing.lg}>
              {isLoadingSlackUsers ? (
                <>
                  <DevExLoader />
                  <DevExText>Searching Slack...</DevExText>
                  {waitingForSync && ( // backend says that users arent ready
                    <DevExText>
                      This is taking a bit longer than usual; please allow a couple more minutes. We
                      appreciate your patience!
                    </DevExText>
                  )}
                </>
              ) : (
                <>
                  <DevExEmpty icon={faUser} iconSize={34} />
                  {!rosterUsers || rosterUsers.length === 0 ? (
                    <Flex vertical gap={theme.variable.spacing.lg}>
                      <DevExText type="secondary" lined>
                        Start adding people from your roster by finding them in Slack. You can
                        filter their attributes.
                      </DevExText>
                      <DevExText type="secondary" lined>
                        Click &lsquo;Find in Slack&rsquo; above to begin.
                      </DevExText>
                    </Flex>
                  ) : filteredSlackUsers.length === 0 ? (
                    <DevExText type="secondary">No users matching that search criteria</DevExText>
                  ) : null}
                </>
              )}
            </Flex>
          </div>
        )}
      </Flex>
    </DevExDrawer>
  );
};
