// this theme will dictate the color tokens required by all other themes
export const LIGHT_THEME = {
  color: {
    text: {
      primary: 'neutral-15', // main body text (ex. titles)
      secondary: 'neutral-9', // secondary body text (ex. subtitles)
      tertiary: 'neutral-8', // tertiary body text (ex. notes)
      disabled: 'neutral-7', // disabled text
      placeholder: 'neutral-8', // placeholder text (ex. Select, Input)
      contrast: 'neutral-1', // constant light text against a constant dark background (ex. Tooltip, charts)
      dark: 'neutral-15', // constant dark text against a constant light background
    },
    background: {
      default: 'neutral-3', // base background of the entire app; IF UPDATED, ALSO UPDATE jf_tags.py!
      raised: 'neutral-1', // elevated content on base background (ex. Card, PageHeader)
      sunken: 'neutral-4', // recessed content on base background (ex. Segmented)
      disabled: 'neutral-3', // disabled element on elevated content (ex. Input)
      active: 'purple-6 8%', // active element on elevated content (ex. Table)
      hovered: 'purple-5 8%', // hovered element on elevated content (ex. Table)
      overlay: 'neutral-1', // elevated content over backdrop (ex. Modal, Drawer)
      backdrop: 'neutral-15 25%', // transluent background behind elevated content (ex. Modal, Drawer)
      contrast: 'neutral-15', // constant dark background (ex. Tooltip)
    },
    border: {
      primary: 'neutral-5', // base border for elevated content (ex. Checkbox)
      secondary: 'neutral-4', // subtle border for elevated content (ex. Card)
      disabled: 'neutral-4', // border for disabled elevated content (ex. default Button)
      active: 'purple-6', // border for active elevated content
      hovered: 'purple-5', // border for hovered elevated content
    },
    shadow: {
      default: 'neutral-15 16%', // base shadow for elevated content (ex. primary Button)
      subtle: 'neutral-15 4%', // subtle shadow for elevated content (ex. default Button)
    },
    brand: {
      default: 'purple-6',
      active: 'purple-6',
      hovered: 'purple-5',
      background: {
        default: 'purple-6 8%',
        hovered: 'purple-5 8%',
      },
      logo: 'purple-6',
    },
    offbrand: {
      default: 'purple-6',
      active: 'purple-6',
      hovered: 'purple-5',
      background: {
        default: 'purple-6 8%',
        hovered: 'purple-5 8%',
      },
    },
    scrollbar: {
      track: {
        default: 'neutral-5',
      },
      thumb: {
        default: 'neutral-7',
        hovered: 'neutral-8',
      },
    },
    tooltip: {
      text: 'neutral-1',
      background: 'neutral-15',
    },
    skeleton: {
      background: 'neutral-15 5%',
    },
    status: {
      default: {
        text: 'neutral-11',
        background: 'neutral-5 60%',
      },
      info: {
        text: 'blue-9',
        background: 'blue-3 15%',
        border: 'blue-3',
      },
      warning: {
        text: 'lime-9',
        background: 'lime-4 15%',
        border: 'lime-4',
      },
      error: {
        text: 'red-10',
        background: 'red-4 15%',
        border: 'red-4',
      },
      success: {
        text: 'green-8',
        background: 'green-3 15%',
        border: 'green-3',
      },
      discovery: {
        text: 'purple-6',
        background: 'purple-3 15%',
        border: 'purple-3',
      },
    },
    tag: {
      default: {
        text: 'neutral-11',
        background: 'neutral-5 60%',
      },
      green: {
        text: 'green-8',
        background: 'green-3',
      },
      blue: {
        text: 'blue-9',
        background: 'blue-2',
      },
      yellow: {
        text: 'lime-10',
        background: 'lime-4',
      },
      red: {
        text: 'red-10',
        background: 'red-4',
      },
      purple: {
        text: 'purple-9',
        background: 'purple-2',
      },
    },
    link: {
      text: {
        default: 'blue-6', // navigation link (ex. Typography.Link)
        hovered: 'blue-5', // hovered navigation link (ex. Typography.Link)
        active: 'blue-7', // active navigation link (ex. Typography.Link)
      },
    },
    card: {
      background: {
        default: 'neutral-1',
      },
    },
    banner: {
      background: 'neutral-15',
      text: 'neutral-1 95%',
    },
    reveal: {
      accent: 'lime-5',
      dark: {
        background: 'neutral-15',
        text: 'neutral-3',
        secondary: 'neutral-1 60%',
      },
      header: {
        background: 'neutral-15 0%',
        scroll: 'neutral-15',
      },
      purple: {
        main: {
          text: 'neutral-3',
          background: 'purple-6',
        },
        card: {
          text: 'neutral-15',
          background: 'neutral-3',
          secondary: 'neutral-15 60%',
        },
      },
      scores: {
        text: 'neutral-15',
        background: 'neutral-3',
        secondary: 'neutral-15 60%',
        expandable: {
          background: 'neutral-1',
          text: 'neutral-15',
          border: 'neutral-4',
        },
      },
    },
    visualization: {
      grid: 'neutral-5',
      polarity: {
        neutral: {
          text: 'neutral-6',
          background: 'neutral-4',
        },
        positive: {
          high: {
            text: 'teal-10',
            background: 'teal-10',
          },
          low: {
            text: 'teal-5',
            background: 'teal-5',
          },
        },
        negative: {
          high: {
            text: 'salmon-10',
            background: 'salmon-10',
          },
          low: {
            text: 'salmon-5',
            background: 'salmon-5',
          },
        },
      },

      palette: {
        1: {
          data: 'blue-7',
        },
      },
    },
    topic: {
      tools_envs: 'blue-2',
      clear_dir: 'red-4',
      team_proc: 'lime-5',
      con_switch: 'purple-5',
      codebase: 'green-4',
      review: 'magenta-4',
      test: 'blue-4',
      release: 'neutral-8',
      oncall: 'blue-6',
      docs: 'red-6',
      learning: 'lime-8',
      onboard: 'purple-8',
      dev_enable: 'green-7',
      satis: 'magenta-7',
      perc_prod: 'red-8',
      other: 'neutral-6',
    },
    star: {
      text: {
        default: 'yellow-5',
        hovered: 'yellow-4',
      },
      border: {
        default: 'neutral-6',
        hovered: 'neutral-7',
      },
    },
  },
};
