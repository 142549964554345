/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompletedStudyResponseRequest } from '../models/CompletedStudyResponseRequest';
import type { CreatedStudyPromptResponseRequest } from '../models/CreatedStudyPromptResponseRequest';
import type { CreatedStudyResponse } from '../models/CreatedStudyResponse';
import type { CreatedStudyResponseRequest } from '../models/CreatedStudyResponseRequest';
import type { CreatedStudyVerbatimsResponseRequest } from '../models/CreatedStudyVerbatimsResponseRequest';
import type { CreatedTeamAssignmentRequest } from '../models/CreatedTeamAssignmentRequest';
import type { PublicStudy } from '../models/PublicStudy';
import type { StudyResponse } from '../models/StudyResponse';
import type { TextualResponse } from '../models/TextualResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicStudyClient {

    /**
     * Create a new study response based on an email address.
     * @returns CreatedStudyResponse
     * @throws ApiError
     */
    public static createStudyResponse({
        requestBody,
    }: {
        requestBody: CreatedStudyResponseRequest,
    }): CancelablePromise<CreatedStudyResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/study-response',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a study response.
     * @returns StudyResponse
     * @throws ApiError
     */
    public static getStudyResponse({
        studyResponseRef,
    }: {
        studyResponseRef: string,
    }): CancelablePromise<StudyResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/study-response/{studyResponseRef}',
            path: {
                'studyResponseRef': studyResponseRef,
            },
        });
    }

    /**
     * Formally mark the study response as completed.
     * @returns any No response body
     * @throws ApiError
     */
    public static completeStudyResponse({
        studyResponseRef,
        requestBody,
    }: {
        studyResponseRef: string,
        requestBody: CompletedStudyResponseRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/public/study-response/{studyResponseRef}/complete',
            path: {
                'studyResponseRef': studyResponseRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add a prompt response to a specific study response.
     * @returns any No response body
     * @throws ApiError
     */
    public static respondToStudyPrompt({
        studyResponseRef,
        requestBody,
    }: {
        studyResponseRef: string,
        requestBody: CreatedStudyPromptResponseRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/study-response/{studyResponseRef}/prompt',
            path: {
                'studyResponseRef': studyResponseRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates or updates a team assignment for a specific study response.
     * @returns any No response body
     * @throws ApiError
     */
    public static createTeamAssignment({
        studyResponseRef,
        requestBody,
    }: {
        studyResponseRef: string,
        requestBody: CreatedTeamAssignmentRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/study-response/{studyResponseRef}/team-assignment',
            path: {
                'studyResponseRef': studyResponseRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add a verbatims response to a specific study response.
     * @returns any No response body
     * @throws ApiError
     */
    public static respondToStudyVerbatims({
        studyResponseRef,
        requestBody,
    }: {
        studyResponseRef: string,
        requestBody: CreatedStudyVerbatimsResponseRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/public/study-response/{studyResponseRef}/verbatims',
            path: {
                'studyResponseRef': studyResponseRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get public study metadata.
     * @returns PublicStudy
     * @throws ApiError
     */
    public static getPublicStudy({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<PublicStudy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/study/{studyRef}',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * Get a study's textual responses for given team for open ended questions.
     * @returns TextualResponse
     * @throws ApiError
     */
    public static getTextualResponsesForTeam({
        studyRef,
        teamRef,
    }: {
        studyRef: string,
        teamRef: string,
    }): CancelablePromise<Array<TextualResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/team/{teamRef}/textualresponses',
            path: {
                'studyRef': studyRef,
                'teamRef': teamRef,
            },
        });
    }

    /**
     * Get a study's textual responses across all respondents for open ended questions.
     * @returns TextualResponse
     * @throws ApiError
     */
    public static getTextualResponses({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<Array<TextualResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/textualresponses',
            path: {
                'studyRef': studyRef,
            },
        });
    }

}
