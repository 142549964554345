import { DateTime, Duration } from 'luxon';

export const formatISO = (iso: string | undefined | null, options: Intl.DateTimeFormatOptions) => {
  if (!iso) {
    return '';
  }

  return DateTime.fromISO(iso).toLocaleString(options);
};

export const formatAvgTimeSec = (avgTimeSec: number) => {
  if (avgTimeSec === 0) {
    return '—';
  }
  return Duration.fromMillis(avgTimeSec * 1000).toFormat(avgTimeSec >= 3600 ? 'hh:mm:ss' : 'mm:ss');
};
