import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faSlack } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpFromSquare,
  faClipboardCheck,
  faLineChart,
  faShare,
  faSparkles,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash/capitalize';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackTargetingClient, Study, StudyClient, StudySequenceClient } from 'jf/api';
import { startFullstory } from 'jf/common/DevEx';
import { DevExPageHeader, DevExPageHeaderTab } from 'jf/common/DevExPageHeader';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CopyLinkPopover } from 'jf/components/CopyLinkPopover';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExTag } from 'jf/components/DevExTag';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientPerms } from 'jf/utils/useClientPerms';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { StudyStatusTag } from '../studies/StudyStatusTag';

import { ExportStudyModal } from './export/ExportStudyModal';
import { CloseStudyModal } from './CloseStudyModal';
import { GenerateTeamShareLinkModal } from './GenerateTeamShareLinkModal';
import { SendSlackReminderModal } from './SendSlackReminderModal';
import {
  STARRED_TOPICS_SIDE_PANEL_COLLAPSED_WIDTH,
  STARRED_TOPICS_SIDE_PANEL_WIDTH,
  StarredTopicsSidePanel,
} from './StarredTopicsSidePanel';
import { useCanViewSurveyResults } from './useCanViewSurveyResults';
import { useStudyParticipation } from './useStudyParticipation';

export const ANONYMITY_THRESHOLD = 3;

export const SHARE_LINK_EXPIRY_DAYS = 14;

const SHINY_REVEAL_TIMER = 6000;

export const makeStudyAnalyzerPath = (
  studyRef: string | undefined,
  teamRef: string | undefined,
  tabKey: string,
  params: Record<string, string> = {}
) => {
  const searchParams = new URLSearchParams(params);

  // persist scope_token in Survey Analyzer
  const scopeToken = new URLSearchParams(window.location.search).get('scope_token');
  if (scopeToken) {
    searchParams.append('scope_token', scopeToken);
  }

  return `/study/${studyRef}/analyze/${teamRef ? `${teamRef}/` : ''}${tabKey}?${searchParams}`;
};

export const StudyAnalyzerPage: React.FC = () => {
  const flags = useClientFlags();
  useEffect(() => startFullstory('survey-analyzer'), []);
  const generateTeamShareLinkModal = useJFOverlay(GenerateTeamShareLinkModal);

  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const exportStudyModal = useJFOverlay(ExportStudyModal);
  const closeStudyModal = useJFOverlay(CloseStudyModal);
  const sendSlackReminderModal = useJFOverlay(SendSlackReminderModal);
  const theme = useDevExTheme();
  const perms = useClientPerms();

  const [shareLink, setShareLink] = useState('');
  const [starredTopicsCollapsed, setStarredTopicsCollapsed] = useLocalStorage(
    'devex_actionItems_collapsed',
    false
  );

  const { data: slackTargets } = useClientQuery(
    SlackTargetingClient.getStudySlackTargets,
    { studyRef },
    { enabled: flags.slackTargeting }
  );

  // reveal / highlights button shiny on page load
  const [isRevealButtonShiny, setIsRevealButtonShiny] = useState(true);

  // timer to un-shiny the highlights/reveal button after SHINY_REVEAL_TIMER ms
  setTimeout(() => {
    setIsRevealButtonShiny(false);
  }, SHINY_REVEAL_TIMER);

  const tabKey = useMemo(() => {
    const TAB_KEY_REGEX = /\/([^/]*?)$/g;
    return TAB_KEY_REGEX.exec(location.pathname)?.[1];
  }, [location.pathname]);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  const allowedTabsForOpenEndedText = ['questions'];
  const allPromptsAreOpenEnd = useMemo(() => {
    return study?.prompts.every((prompt) => prompt.type === 'OPEN_END') || false;
  }, [study]);

  // If a user is on a tab they are not allowed to be on (due to having an open ended only survey)
  // then redirect them to the first tab that is allowed. We often link to /insights so this is needed
  useEffect(() => {
    if (tabKey && allPromptsAreOpenEnd && !allowedTabsForOpenEndedText.includes(tabKey)) {
      navigate(makeStudyAnalyzerPath(studyRef, teamRef, allowedTabsForOpenEndedText[0]));
    }
  }, [tabKey, allPromptsAreOpenEnd]);

  // persist scope_token when using tabs
  const scopeToken = searchParams.get('scope_token');
  let tabKeys = ['insights', 'topics', 'questions', 'participation'];
  const canViewSurveyResults = useCanViewSurveyResults({ studyRef });
  if (!canViewSurveyResults) {
    // removes topics tab
    tabKeys.splice(tabKeys.indexOf('topics'), 1);
    // removes insights tab
    tabKeys.splice(tabKeys.indexOf('insights'), 1);
  }
  // hide participation tab for currently open surveys if
  // the user is not logged in
  if (scopeToken && !window.dx.user) {
    tabKeys.splice(tabKeys.indexOf('participation'), 1);
  }

  const disabledForAllOpenEndedText =
    'This feature is unavailable for surveys only containing Open-Ended Text questions.';

  const tabs: DevExPageHeaderTab[] = tabKeys.map((key) => {
    // If all prompts are open ended, disable all tabs except 'questions' because they are broken / not very useful
    //  Maybe we can make them work better for this use case in the future
    const isTabDisabled = !allowedTabsForOpenEndedText.includes(key) && allPromptsAreOpenEnd;
    return {
      key,
      label: capitalize(key),
      href: makeStudyAnalyzerPath(studyRef, teamRef, key),
      disabled: isTabDisabled,
      tooltipText: isTabDisabled ? disabledForAllOpenEndedText : '',
    };
  });

  const { mutateAsync: shareStudy } = useClientMutation(StudyClient.shareStudy);

  const onClose = () => {
    trackEvent('survey-analyzer:close:start', { surveyRef: studyRef });
    closeStudyModal.open({ autorunHighlights: !allPromptsAreOpenEnd });
  };

  const onExport = () => {
    trackEvent('survey-analyzer:export:start', { surveyRef: studyRef });
    exportStudyModal.open();
  };

  const { data: participation } = useStudyParticipation({ studyRef, teamRef });
  const isCloseDisabled = participation && participation.uniqueResponses < 3;

  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, {
    sequenceRef: study?.sequenceRef,
  });

  // we show starred topics even if study is not in a sequence since there is a special state for that case
  const showStarredTopics =
    !!flags.actionItems && perms.role.customerAdmin && study?.status === Study.status.CLOSED;

  const sequenceButton = sequence && !scopeToken && (
    <DevExButton
      icon={<FontAwesomeIcon icon={faLineChart} />}
      type="text"
      style={{ paddingInline: theme.variable.spacing.sm, marginTop: '4px' }}
      onClick={() => {
        const teamSection = teamRef ? `team/${teamRef}` : '';
        navigate(`/series/${sequence.ref}/${teamSection}`);
      }}
    >
      {sequence.name}
    </DevExButton>
  );

  const showExampleTag = scopeToken === 'DEMO'; // Used to show either example tag or status tag otherwise
  const shouldShowNewShareLinkModal =
    flags.viewOpenSurveyResults && canViewSurveyResults && perms.role.customerAdmin;
  return (
    <div
      style={{
        marginRight: showStarredTopics
          ? starredTopicsCollapsed
            ? STARRED_TOPICS_SIDE_PANEL_COLLAPSED_WIDTH
            : STARRED_TOPICS_SIDE_PANEL_WIDTH
          : 0,
        transition: 'margin-right 150ms ease',
      }}
    >
      <DevExPageTitle>{`Survey ${study?.name ?? ''}`}</DevExPageTitle>
      <DevExPageHeader
        title={
          study ? (
            <Flex gap={theme.variable.spacing.sm} align="center">
              <div>
                {/* &nbsp; instead of padding so if the tag wraps to next line (long study name) it still lines up */}
                <div>
                  {study?.name} &nbsp;
                  {!showExampleTag && (
                    <StudyStatusTag style={{ verticalAlign: 'middle' }} status={study?.status} />
                  )}
                </div>
                {sequenceButton}
              </div>
              {showExampleTag && <DevExTag color="green">Example</DevExTag>}
            </Flex>
          ) : undefined
        }
        extra={
          <>
            {study?.status === Study.status.CLOSED && flags.studyReveal && (
              <Tooltip title={allPromptsAreOpenEnd ? disabledForAllOpenEndedText : ''}>
                <DevExButton
                  size="large"
                  type="outline"
                  shiny={isRevealButtonShiny}
                  onClick={() => {
                    trackEvent('survey-reveal:open:click');
                    navigate(makeStudyAnalyzerPath(studyRef, undefined, 'reveal'));
                  }}
                  disabled={allPromptsAreOpenEnd}
                  icon={<FontAwesomeIcon icon={faSparkles} />}
                >
                  Highlights
                </DevExButton>
              </Tooltip>
            )}
            {study?.status === Study.status.CLOSED && flags.studyReveal && (
              <Tooltip title={allPromptsAreOpenEnd ? disabledForAllOpenEndedText : ''}>
                <DevExButton
                  size="large"
                  type="outline"
                  icon={<FontAwesomeIcon icon={faArrowUpFromSquare} />}
                  onClick={onExport}
                  disabled={allPromptsAreOpenEnd}
                >
                  Export
                </DevExButton>
              </Tooltip>
            )}
            {
              // GBAC RESTRICTION
              // only customer admins can create share links
              study?.status === Study.status.CLOSED && !scopeToken && (
                <CopyLinkPopover
                  title="Share Results"
                  description={`The link above will grant public access to this ${teamRef ? 'team' : 'survey'}'s results to whomever you share it with. It will expire in ${SHARE_LINK_EXPIRY_DAYS} days.`}
                  link={shareLink}
                  placement="bottomRight"
                  onCopy={() =>
                    trackEvent('survey-analyzer:share-results:copy', { surveyRef: studyRef })
                  }
                >
                  <DevExButton
                    size="large"
                    type="outline"
                    icon={<FontAwesomeIcon icon={faShare} />}
                    onClick={() => {
                      if (studyRef) {
                        trackEvent('survey-analyzer:share-results:start', {
                          surveyRef: studyRef,
                        });
                        shareStudy({
                          studyRef,
                          requestBody: {
                            teamRef,
                            expiryDays: SHARE_LINK_EXPIRY_DAYS,
                          },
                        }).then((response) => {
                          const queryParams = new URLSearchParams(window.location.search);
                          queryParams.append('scope_token', response.scopeToken);
                          setShareLink(
                            `${window.location.origin}${window.location.pathname}?${queryParams}`
                          );
                        });
                      }
                    }}
                  >
                    Share results
                  </DevExButton>
                </CopyLinkPopover>
              )
            }
            {study?.status === Study.status.OPEN && perms.role.customerAdmin && (
              <>
                <Tooltip
                  title={
                    isCloseDisabled
                      ? 'To protect anonymity, we require at least 3 responses before a survey can be closed.'
                      : ''
                  }
                >
                  <div>
                    <DevExButton
                      size="large"
                      type="outline"
                      icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                      onClick={onClose}
                      disabled={isCloseDisabled}
                      adminRequired
                    >
                      Close
                    </DevExButton>
                  </div>
                </Tooltip>

                {slackTargets?.length ? (
                  <DevExButton
                    size="large"
                    type="primary"
                    icon={<FontAwesomeIcon icon={faSlack} />}
                    onClick={() => {
                      trackEvent(`survey-analyzer:slack-reminder:open`);
                      sendSlackReminderModal.open();
                    }}
                  >
                    Send reminder
                  </DevExButton>
                ) : (
                  <CopyLinkPopover
                    title="Share Survey"
                    description="Copy the survey link above and share it with your target respondents."
                    link={`${window.location.origin}/study/${studyRef}`}
                    placement="bottomRight"
                    onCopy={() =>
                      trackEvent('survey-analyzer:share-survey:copy', { surveyRef: studyRef })
                    }
                  >
                    <DevExButton
                      size="large"
                      type="primary"
                      icon={<FontAwesomeIcon icon={faShare} />}
                      onClick={() =>
                        trackEvent('survey-analyzer:share-survey:start', { surveyRef: studyRef })
                      }
                    >
                      Share survey
                    </DevExButton>
                  </CopyLinkPopover>
                )}
              </>
            )}
          </>
        }
        tabs={tabs}
        tabKey={tabKey}
        tabBarExtraContent={
          study && study.status !== Study.status.CLOSED && shouldShowNewShareLinkModal ? (
            <DevExButton
              onClick={() => {
                generateTeamShareLinkModal.open();
              }}
              icon={<FontAwesomeIcon icon={faUsers} />}
            >
              Share results
            </DevExButton>
          ) : undefined
        }
      />
      <Outlet />
      {showStarredTopics && (
        <StarredTopicsSidePanel
          sequenceRef={study?.sequenceRef}
          collapsed={starredTopicsCollapsed}
          onCollapse={setStarredTopicsCollapsed}
        />
      )}
    </div>
  );
};
