import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import classNames from 'classnames';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/themes/DevExTheme';

const styles = {
  card: css`
    position: relative;
    background-color: ${(props) => props.theme.color.card.background.default};
    padding: ${(props) => props.theme.variable.spacing.md};
    border-radius: ${(props) => props.theme.variable.borderRadius};
    border: 1px solid ${(props) => props.theme.color.border.secondary};
    gap: ${(props) => props.theme.variable.spacing.md};

    .card__header {
      gap: ${(props) => props.theme.variable.spacing.md};

      .header__title {
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        font-size: ${(props) => props.theme.variable.fontSize.lg};
        line-height: ${(props) => props.theme.variable.fontSize.lg};
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card__body {
      // specify both overflow-y and overflow-x so it's simpler to override overflow-x: auto;
      overflow-y: visible;
      overflow-x: visible;
      gap: ${(props) => props.theme.variable.spacing.md};
    }

    &.card--small {
      padding-block: ${(props) => props.theme.variable.spacing.sm};
      gap: ${(props) => props.theme.variable.spacing.sm};

      .card__header {
        gap: ${(props) => props.theme.variable.spacing.sm};

        .header__title {
          font-size: ${(props) => props.theme.variable.fontSize.sm};
        }
      }
    }

    &.card--clickable {
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0 2px ${(props) => props.theme.color.border.hovered};
      }
    }

    &.card--active {
      background: ${(props) => props.theme.color.background.active};
      border-color: ${(props) => props.theme.color.border.active};
    }

    &.card--disabled {
      cursor: default;
      opacity: ${(props) => props.theme.variable.opacity.disabled};
      pointer-events: none;
    }
  `,
};

type DevExCardProps = {
  title?: ReactNode;
  extra?: ReactNode;
  size?: 'small' | 'large';
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  'data-cy'?: string;
  onHoverChange?: (hovered: boolean) => void;
};

export const DevExCard: React.FC<DevExCardProps> = (props) => {
  const theme = useDevExTheme();

  const className = classNames({
    'card--small': props.size === 'small',
    'card--clickable': !!props.onClick,
    'card--active': props.active,
    'card--disabled': props.disabled,
  });

  const showHeader = !!(props.title || props.extra);

  return (
    <Flex
      vertical
      css={styles.card}
      className={`${className} ${props.className}`}
      style={props.style}
      onClick={props.onClick}
      data-cy={props['data-cy']}
      onMouseEnter={() => props.onHoverChange?.(true)}
      onMouseLeave={() => props.onHoverChange?.(false)}
    >
      {showHeader && (
        <Flex justify="space-between" align="center" className={'card__header'}>
          <Flex align="center" gap={theme.variable.spacing.sm} className="header__title">
            {props.title}
          </Flex>
          {props.extra}
        </Flex>
      )}

      <Flex vertical className="card__body" style={props.bodyStyle}>
        {props.children}
      </Flex>
    </Flex>
  );
};
