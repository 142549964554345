import { differenceBy } from 'lodash-es';
import { useQueryClient } from 'react-query';

import { SlackTargetingClient, StudySlackTarget } from 'jf/api';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

export const useStudySlackTargetUpdater = (studyRef: string | undefined) => {
  const queryClient = useQueryClient();

  const { data: slackTargets } = useClientQuery(SlackTargetingClient.getStudySlackTargets, {
    studyRef,
  });

  const { mutateAsync: createSlackTarget } = useClientMutation(
    SlackTargetingClient.createStudySlackTarget
  );
  const { mutateAsync: deleteSlackTargets } = useClientMutation(
    SlackTargetingClient.deleteStudySlackTargets
  );

  const update = async (newSlackTargets: StudySlackTarget[]) => {
    if (!slackTargets) {
      return;
    }

    const queries: Promise<any>[] = [];

    // create new targets
    const slackTargetsToCreate = differenceBy(newSlackTargets, slackTargets, 'id');
    for (const slackTarget of slackTargetsToCreate) {
      queries.push(
        createSlackTarget({
          studyRef: studyRef!,
          requestBody: slackTarget,
        })
      );
    }

    // delete removed targets
    const slackTargetsToDelete = differenceBy(slackTargets, newSlackTargets, 'id');
    if (slackTargetsToDelete.length) {
      queries.push(
        deleteSlackTargets({
          studyRef: studyRef!,
          slackTargetId: slackTargetsToDelete.map((target) => target.id),
        })
      );
    }

    if (queries.length) {
      return Promise.allSettled(queries).then(() =>
        queryClient.refetchQueries(['GET_STUDY_SLACK_TARGETS', studyRef])
      );
    }
  };

  return { update };
};
