import React, { useEffect, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { faBookSpells, faClipboard, faPaste } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExLoader } from 'jf/components/DevExLoader';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from '../StudyBuilderState';
import { useStudyCreator } from '../useStudyCreator';

import { ClosedStudies } from './ClosedStudies';
import { StudyTemplates } from './StudyTemplates';

const styles = {
  optionSet: css`
    .card__body {
      align-items: center;
      gap: ${(props) => props.theme.variable.spacing.sm};
      line-height: ${(props) => props.theme.variable.lineHeight};

      .optionSet__title {
        font-size: ${(props) => props.theme.variable.fontSize.md};

        &:not(:first-child) {
          margin-top: ${(props) => props.theme.variable.spacing.sm};
        }
      }

      .optionSet__description {
        font-size: ${(props) => props.theme.variable.fontSize.xs};
        color: ${(props) => props.theme.color.text.secondary};
        text-align: center;
      }
    }
  `,
};

export const ChooseStudyStep: React.FC = () => {
  const [searchParams] = useSearchParams();
  const theme = useDevExTheme();
  const state = useStudyBuilderState();
  const studyCreator = useStudyCreator();

  const studyRef = searchParams.get('survey');

  const [optionSet, setOptionSet] = useState<'studyTemplates' | 'closedStudies'>();

  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const closedStudies = studies?.filter((study) => study.status === Study.status.CLOSED);

  // if a study ref is passed via query param, auto select it
  useEffect(() => {
    if (studyRef) {
      setOptionSet('closedStudies');
    }
  }, [studyRef]);

  if (state.creatingStudy) {
    return (
      <DevExLoader
        style={{ paddingBlock: theme.variable.spacing.xxl }}
        tip="Please wait while we get your survey ready..."
      />
    );
  }

  return (
    <Flex vertical gap={theme.variable.spacing.lg} align="center">
      <Flex style={{ alignSelf: 'stretch' }}>
        <DevExButton
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          style={!optionSet ? { opacity: 0, pointerEvents: 'none' } : undefined}
          onClick={() => setOptionSet(undefined)}
        >
          Back
        </DevExButton>
      </Flex>

      {!optionSet && (
        <Flex vertical align="center">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: theme.variable.spacing.lg,
            }}
          >
            <DevExCard
              css={styles.optionSet}
              onClick={() => {
                trackEvent(`survey-creator:templates:navigate`);
                setOptionSet('studyTemplates');
              }}
            >
              <CircledFontAwesomeIcon icon={faBookSpells} />
              <div className="optionSet__title">Use a template</div>
              <div className="optionSet__description">
                Choose one of our research-backed preset templates for a quick start.
              </div>
            </DevExCard>

            <Tooltip title={!closedStudies?.length ? "You haven't completed any surveys yet." : ''}>
              <div>
                <DevExCard
                  css={styles.optionSet}
                  onClick={() => {
                    trackEvent(`survey-creator:past-surveys:navigate`);
                    setOptionSet('closedStudies');
                  }}
                  disabled={!closedStudies?.length}
                >
                  <CircledFontAwesomeIcon icon={faPaste} />
                  <div className="optionSet__title">Duplicate a past survey</div>
                  <div className="optionSet__description">
                    Duplicate one of your past surveys to track questions and topics over time.
                  </div>
                </DevExCard>
              </div>
            </Tooltip>
          </div>

          <DevExDivider>or</DevExDivider>

          <DevExCard
            css={styles.optionSet}
            style={{
              width: 300,
              background: 'none',
              border: 'none',
            }}
            onClick={() => {
              trackEvent(`survey-creator:blank:navigate`);
              studyCreator.create({
                name: `New Blank Survey`,
                sequencedPrompts: [],
                flags: ['voting_enabled'],
              });
            }}
          >
            <CircledFontAwesomeIcon icon={faClipboard} />
            <div className="optionSet__title">Start from scratch</div>
            <div className="optionSet__description">
              Start with a blank slate and browse our research-backed questions for inspiration.
            </div>
          </DevExCard>
        </Flex>
      )}

      {optionSet === 'studyTemplates' && <StudyTemplates />}

      {optionSet === 'closedStudies' && <ClosedStudies />}
    </Flex>
  );
};
