import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { keyBy } from 'lodash-es';
import { css } from 'styled-components';

import { StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExPopover } from 'jf/components/DevExPopover';
import { DevExTag } from 'jf/components/DevExTag';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../ScoreBlock';
import { useTopicResults } from '../topicDrawer/useTopicResults';
import { useStudySegments } from '../useStudySegments';

const styles = {
  topicScorePopover: css`
    width: 600px;
    line-height: ${(props) => props.theme.variable.lineHeight};

    .topicScorePopover__title,
    .topicScorePopover__priority {
      font-size: ${(props) => props.theme.variable.fontSize.md};
      font-weight: 600;
    }

    .topicScorePopover__subtitle {
      color: ${(props) => props.theme.color.text.secondary};
      font-weight: 600;
    }

    ul {
      margin: 0;
      padding-inline-start: 32px;

      li:not(:last-child) {
        padding-bottom: ${(props) => props.theme.variable.spacing.xs};
      }
    }
  `,
};

type TopicScorePopoverProps = {
  topicSlug: string;
  studyRef: string | undefined;
  teamRef: string | undefined;
  segmentRef?: string;
};

export const TopicScorePopover: React.FC<TopicScorePopoverProps> = (props) => {
  const theme = useDevExTheme();

  const [open, setOpen] = useState(false);

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const topicsBySlug = useMemo(() => (topics ? keyBy(topics, 'slug') : undefined), [topics]);
  const promptsBySlug = useMemo(() => (prompts ? keyBy(prompts, 'slug') : undefined), [prompts]);

  const studySegments = useStudySegments({
    studyRef: props.studyRef,
  });

  const segmentRef = props.segmentRef ?? props.teamRef;

  const segmentLabel = useMemo(() => {
    if (studySegments && segmentRef) {
      for (const segment of studySegments) {
        const option = segment.options.find((o) => o.value === segmentRef);
        if (option) {
          return option.label;
        }
      }
    }

    return undefined;
  }, [studySegments, segmentRef]);

  const { score, promptScores, votes, priority } = useTopicResults({
    studyRef: open ? props.studyRef : undefined, // don't fetch data until popover is open
    topicSlug: props.topicSlug,
    teamRef: props.teamRef,
    segmentRef: props.segmentRef,
  });

  const topic = topicsBySlug?.[props.topicSlug];

  const showPriority = !segmentRef;

  return (
    <DevExPopover
      open={open && !!score}
      onOpenChange={setOpen}
      mouseEnterDelay={0.25}
      content={
        <Flex vertical gap={theme.variable.spacing.sm} css={styles.topicScorePopover}>
          <Flex justify="space-between" gap={theme.variable.spacing.md}>
            <Flex vertical gap={theme.variable.spacing.xs}>
              <Flex align="center" gap={theme.variable.spacing.sm}>
                <div className="topicScorePopover__title">{topic?.label}</div>
                {!!segmentLabel && <DevExTag>{segmentLabel}</DevExTag>}
              </Flex>
              <div>{topic?.longDescription}</div>
            </Flex>

            <Flex gap={theme.variable.spacing.sm} align="center" style={{ height: 'fit-content' }}>
              {showPriority && <div className="topicScorePopover__priority">#{priority}</div>}
              <ScoreBlock score={score?.scaledAvg} size="md" />
            </Flex>
          </Flex>

          {showPriority && (
            <Flex vertical gap={theme.variable.spacing.xs}>
              <div className="topicScorePopover__subtitle">Priority</div>
              <div>
                <strong>
                  {votes?.length} respondent{votes?.length === 1 ? '' : 's'}
                </strong>{' '}
                voted for {topic?.label} to be prioritized, making it priority{' '}
                <strong>#{priority}</strong>.
              </div>
            </Flex>
          )}

          <Flex vertical gap={theme.variable.spacing.xs}>
            <div className="topicScorePopover__subtitle">Survey Questions</div>
            <ul>
              {promptScores?.map((promptScore) => (
                <li key={promptScore.slug}>
                  <Flex justify="space-between" align="center" gap={theme.variable.spacing.sm}>
                    {promptsBySlug?.[promptScore.slug].text}
                    <ScoreBlock score={promptScore.scaledAvg} />
                  </Flex>
                </li>
              ))}
            </ul>
          </Flex>
        </Flex>
      }
    >
      {props.children}
    </DevExPopover>
  );
};
