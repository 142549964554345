import { PublicStudyClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

type UseTextResponsesProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

export const useTextResponses = (props: UseTextResponsesProps) => {
  const textResponsesQuery = useClientQuery(
    PublicStudyClient.getTextualResponses,
    {
      studyRef: props.studyRef,
    },
    { enabled: !props.teamRef }
  );

  const textResponsesForTeamQuery = useClientQuery(PublicStudyClient.getTextualResponsesForTeam, {
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  return props.teamRef ? textResponsesForTeamQuery : textResponsesQuery;
};
