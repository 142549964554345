import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackTargetingClient, StudySlackTarget } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExModal } from 'jf/components/DevExModal';
import { SlackTargetEditor } from 'jf/pages/studyBuilder/targetRespondents/SlackTargetEditor';
import { useStudySlackTargetUpdater } from 'jf/pages/studyBuilder/targetRespondents/useStudySlackTargetUpdater';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

export const ManageSlackTargetsModal: React.FC = () => {
  const modal = useJFOverlay(ManageSlackTargetsModal);
  const { studyRef } = useParams<{ studyRef: string; teamRef?: string }>();
  const theme = useDevExTheme();
  const studySlackTargetUpdater = useStudySlackTargetUpdater(studyRef);
  const queryClient = useQueryClient();

  const [unsavedSlackTargets, setUnsavedSlackTargets] = useState<StudySlackTarget[]>();

  const [isSending, setIsSending] = useState(false);

  const { data: slackTargets } = useClientQuery(SlackTargetingClient.getStudySlackTargets, {
    studyRef,
  });

  const { mutateAsync: sendStudyMessages } = useClientMutation(
    SlackTargetingClient.sendStudyMessages
  );

  const onSend = async () => {
    setIsSending(true);

    if (unsavedSlackTargets) {
      await studySlackTargetUpdater.update(unsavedSlackTargets);
    }

    await sendStudyMessages({ studyRef: studyRef!, inviteOnly: true });
    queryClient.invalidateQueries(['GET_STUDY', studyRef]);

    trackEvent(`survey-analyzer:manage-slack-targets:send`);

    setIsSending(false);
    modal.close();
  };

  useEffect(() => {
    if (!unsavedSlackTargets) {
      setUnsavedSlackTargets(slackTargets);
    }
  }, [slackTargets]);

  useEffect(() => {
    setIsSending(false);
    setUnsavedSlackTargets(slackTargets);
  }, [modal.isOpened]);

  const newTargetCount = (unsavedSlackTargets?.length ?? 0) - (slackTargets?.length ?? 0);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={() => modal.close()}
      title="Manage target respondents"
      width={550}
      footer={
        <DevExButton type="outline" disabled={isSending || !newTargetCount} onClick={onSend}>
          Send to {newTargetCount} new respondent{newTargetCount === 1 ? '' : 's'}
        </DevExButton>
      }
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <div>
          Forget to send the survey to someone? Add more target respondents here to invite them via
          Slack.
        </div>

        {unsavedSlackTargets && (
          <SlackTargetEditor
            key={slackTargets?.length} // rerender node after adding slack targets
            slackTargets={unsavedSlackTargets}
            onChange={setUnsavedSlackTargets}
            preventDeletion
            eventCategory="survey-analyzer"
          />
        )}
      </Flex>
    </DevExModal>
  );
};
