import React, { useEffect, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { SlackTargetingClient, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExText } from 'jf/components/DevExText';
import { formatISO } from 'jf/utils/timeUtils';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyParticipation } from './useStudyParticipation';

export const SendSlackReminderModal: React.FC = () => {
  const modal = useJFOverlay(SendSlackReminderModal);
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef?: string }>();
  const theme = useDevExTheme();
  const queryClient = useQueryClient();

  const [isSending, setIsSending] = useState(false);

  const { data: slackTargets } = useClientQuery(SlackTargetingClient.getStudySlackTargets, {
    studyRef,
  });

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  const { data: participation } = useStudyParticipation({ studyRef, teamRef });

  const { mutateAsync: sendStudyMessages } = useClientMutation(
    SlackTargetingClient.sendStudyMessages
  );

  const onSend = async () => {
    setIsSending(true);

    await sendStudyMessages({ studyRef: studyRef! });
    queryClient.invalidateQueries(['GET_STUDY', studyRef]);

    if (participation && slackTargets) {
      const responseRate = (participation.uniqueResponses / slackTargets.length).toFixed(1);
      trackEvent(`survey-analyzer:send-slack-reminder:send`, { responseRate });
    }

    setIsSending(false);
    modal.close();
  };

  useEffect(() => {
    setIsSending(false);
  }, [modal.isOpened]);

  const sentAt = study?.sentAt && DateTime.fromISO(study.sentAt);
  const sentAgoMinutes = sentAt ? DateTime.now().diff(sentAt, 'minute').minutes : 0;
  const sendDisabled = sentAgoMinutes < 60;

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={() => modal.close()}
      title="Send reminder"
      width={600}
      footer={
        <Flex vertical align="end" gap={theme.variable.spacing.sm}>
          <DevExText type="secondary" fontSize="xs">
            Last sent {formatISO(study?.sentAt, DateTime.DATE_MED)}
          </DevExText>

          <Tooltip
            title={
              sendDisabled ? 'You must wait at least an hour before sending another reminder.' : ''
            }
          >
            <DevExButton
              type="primary"
              loading={isSending}
              onClick={onSend}
              disabled={sendDisabled}
            >
              Send
            </DevExButton>
          </Tooltip>
        </Flex>
      }
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <div>Send a reminder in Slack to target respondents that have not yet responded.</div>

        {sentAgoMinutes < 1440 && (
          <DevExExpandableAlert
            title="The last Slack message was sent less than 24 hours ago. We recommend waiting before sending a new reminder."
            type={DevExExpandableAlertType.WARNING}
          />
        )}
      </Flex>
    </DevExModal>
  );
};
