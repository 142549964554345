import React, { useContext } from 'react';
import { notification } from 'antd';

type _JFNotificationContext = Pick<
  typeof notification,
  'open' | 'error' | 'warning' | 'info' | 'success' | 'destroy'
>;

const JFNotificationContext = React.createContext<_JFNotificationContext>({
  open: () => {},
  error: () => {},
  warning: () => {},
  info: () => {},
  success: () => {},
  destroy: () => {},
});

export const useJFNotification = () => useContext(JFNotificationContext);

export const JFNotificationProvider: React.FC = (props) => {
  const [api, contextHolder] = notification.useNotification();

  const { open, error, warning, info, success, destroy } = api;

  const value: _JFNotificationContext = {
    open,
    error,
    warning,
    info,
    success,
    destroy,
  };

  return (
    <JFNotificationContext.Provider value={value}>
      {contextHolder}
      {props.children}
    </JFNotificationContext.Provider>
  );
};
