import React, { useEffect, useMemo, useState } from 'react';
import { Flex, MenuProps } from 'antd';
import { faAngleDown, faEllipsis, faEyeSlash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { partition, sortBy } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { RosterClient, Team } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useJFNotification } from 'jf/common/useJFNotification';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExCollapsible } from 'jf/components/DevExCollapsible';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExMenu } from 'jf/components/DevExMenu';
import { DevExPopover } from 'jf/components/DevExPopover';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { useClientMutationError } from 'jf/utils/useClientMutationError';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

export const TEAM_CARD_MAX_WIDTH = '280px';
const UNSAVED_TEAM_REF = 'UNSAVED_TEAM';

type TeamInHierarchy = {
  team: Team;
  childTeams: TeamInHierarchy[];
};

type TeamHierarchyProps = {
  teams: Team[];
  isFlat?: boolean;
};

export const EditableTeamHierarchy: React.FC<TeamHierarchyProps> = (props) => {
  const theme = useDevExTheme();

  const baseTeamsInHierarchy = useMemo(() => {
    const teams = sortBy(props.teams, 'value');

    // intialize teams in hierarchy
    const teamsInHierarchy: { [ref: string]: TeamInHierarchy } = {};
    for (const team of teams) {
      teamsInHierarchy[team.ref] = { team, childTeams: [] };
    }

    // populate childTeams for each team in hierarchy
    for (const team of teams) {
      if (team.parentRef && teamsInHierarchy[team.parentRef]) {
        teamsInHierarchy[team.parentRef].childTeams.push(teamsInHierarchy[team.ref]);
      }
    }

    // filter down to base teams
    return Object.values(teamsInHierarchy).filter((tih) => tih.team.level === 1);
  }, [props.teams]);

  return (
    <Flex vertical gap={theme.variable.spacing.sm}>
      <HierarchicalTeamCard
        teamInHierarchy={{ team: undefined, childTeams: baseTeamsInHierarchy }}
        isFlat={props.isFlat}
      />
    </Flex>
  );
};

type HierarchicalTeamCardProps = {
  teamInHierarchy: {
    team: Team | undefined;
    childTeams: TeamInHierarchy[];
  };
  isFlat?: boolean;
};

const HierarchicalTeamCard: React.FC<HierarchicalTeamCardProps> = ({
  teamInHierarchy: { team, childTeams },
  ...props
}) => {
  const theme = useDevExTheme();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const [leafTeams, nonLeafTeams] = useMemo(
    () => partition(childTeams, (tih) => tih.team.isLeaf),
    [childTeams]
  );

  const isCollapsible = !!childTeams.length;

  return (
    <Flex
      vertical
      style={{ transition: 'gap 150ms ease', gap: isCollapsed ? 0 : theme.variable.spacing.sm }}
    >
      {team && (
        <Flex align="center" gap={theme.variable.spacing.xs}>
          <DevExCard
            size="small"
            title={
              <Flex align="center" gap={theme.variable.spacing.sm} style={{ overflow: 'hidden' }}>
                {isCollapsible && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    color={theme.color.text.secondary}
                    style={{
                      transform: isCollapsed ? 'rotateZ(180deg)' : undefined,
                      transition: 'transform 150ms ease',
                    }}
                  />
                )}
                <DevExText title={team.value} ellipsis lined strong>
                  {team.value}
                </DevExText>
              </Flex>
            }
            style={{
              background: theme.color.background.sunken,
              paddingInline: theme.variable.spacing.sm,
              width: TEAM_CARD_MAX_WIDTH,
              zIndex: 1,
            }}
            onClick={isCollapsible ? () => setIsCollapsed(!isCollapsed) : undefined}
          />
          <DevExButton
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setIsAdding(true)}
            type="text"
          />
        </Flex>
      )}

      {(isCollapsible || isAdding) && (
        <DevExCollapsible collapsed={isCollapsed}>
          <Flex
            vertical
            gap={theme.variable.spacing.sm}
            style={{ paddingLeft: team ? theme.variable.spacing.lg : undefined }}
          >
            <Flex
              css={css`
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(${TEAM_CARD_MAX_WIDTH}, 1fr));
                gap: ${(props) => props.theme.variable.spacing.sm};
              `}
            >
              {leafTeams.map((tih) => (
                <LeafTeamCard key={tih.team.ref} team={tih.team} />
              ))}
              <LeafTeamCard
                team={{
                  value: '',
                  ref: UNSAVED_TEAM_REF,
                  parentRef: team?.ref,
                  level: 0,
                  levelName: '',
                  isLeaf: true,
                  isHidden: false,
                  isEmp: false,
                  isDeletable: false,
                }}
                onRename={() => setIsAdding(false)}
                visible={isAdding}
              />
              {props.isFlat && !isAdding && (
                <DevExButton
                  type="dashed"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  style={{ height: 42, justifyContent: 'center' }}
                  onClick={() => setIsAdding(true)}
                >
                  Add team(s)
                </DevExButton>
              )}
            </Flex>

            {nonLeafTeams.map((tih) => (
              <HierarchicalTeamCard key={tih.team.ref} teamInHierarchy={tih} />
            ))}
          </Flex>
        </DevExCollapsible>
      )}
    </Flex>
  );
};

type LeafTeamCardProps = {
  team: Team;
  onRename?: () => void;
  visible?: boolean;
};

const LeafTeamCard: React.FC<LeafTeamCardProps> = (props) => {
  const theme = useDevExTheme();
  const queryClient = useQueryClient();
  const notification = useJFNotification();

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [input, setInput] = useState('');

  const { mutateAsync: deleteTeam, isLoading: isDeleting } = useClientMutation(
    RosterClient.deleteTeam
  );
  const { mutateAsync: updateTeam, isLoading: isUpdating } = useClientMutation(
    RosterClient.updateTeam
  );
  const { mutateAsync: createTeam } = useClientMutation(RosterClient.createTeam);
  const createTeamError = useClientMutationError();

  const onDelete = async () => {
    setIsMenuOpen(false);

    if (props.team.isDeletable) {
      trackEvent('team-management:global:delete', {
        teamRef: props.team.ref,
        teamName: props.team.value,
        isEmp: props.team.isEmp,
      });
      queryClient.setQueryData('GET_TEAMS', () =>
        teams!.filter((team) => team.ref !== props.team.ref)
      ); // delete team locally
    } else {
      trackEvent('team-management:global:hide', {
        teamRef: props.team.ref,
        teamName: props.team.value,
        isEmp: props.team.isEmp,
      });
      queryClient.setQueryData('GET_TEAMS', () =>
        teams!.map((team) => (team.ref === props.team.ref ? { ...team, isHidden: true } : team))
      ); // update team locally
    }

    await deleteTeam({ teamRef: props.team.ref });
    queryClient.refetchQueries('GET_TEAMS');
  };

  const onUndelete = async () => {
    setIsMenuOpen(false);

    trackEvent('team-management:global:unhide', {
      teamRef: props.team.ref,
      teamName: props.team.value,
      isEmp: props.team.isEmp,
    });
    queryClient.setQueryData('GET_TEAMS', () =>
      teams!.map((team) => (team.ref === props.team.ref ? { ...team, isHidden: false } : team))
    ); // update team locally

    await updateTeam({ teamRef: props.team.ref, requestBody: { isHidden: false } });
    queryClient.refetchQueries('GET_TEAMS');
  };

  const onStartRename = () => {
    setIsMenuOpen(false);
    setInput(props.team.value);
    setIsRenaming(true);
    setTimeout(() => document.getElementById(inputId)?.focus());
  };

  const onRename = async () => {
    setIsRenaming(false);
    props.onRename?.();

    if (input) {
      if (props.team.ref === UNSAVED_TEAM_REF) {
        trackEvent('team-management:global:create', {
          teamName: input,
        });
        queryClient.setQueryData('GET_TEAMS', () => [
          ...teams!,
          { ...props.team, value: input, ref: 'SAVED' },
        ]); // create team locally

        await createTeam({ requestBody: { parentRef: props.team.parentRef, name: input } }).catch(
          createTeamError.handle
        );
      } else {
        trackEvent('team-management:global:rename', {
          teamRef: props.team.ref,
          oldTeamName: props.team.value,
          newTeamName: input,
        });
        queryClient.setQueryData('GET_TEAMS', () =>
          teams!.map((team) => (team.ref === props.team.ref ? { ...team, value: input } : team))
        ); // update team locally

        await updateTeam({ teamRef: props.team.ref, requestBody: { value: input } });
      }

      queryClient.refetchQueries('GET_TEAMS');
    }
  };

  useEffect(() => {
    if (createTeamError.message) {
      notification.error({
        message: createTeamError.message,
        placement: 'bottom',
      });
    }
  }, [createTeamError.message]);

  useEffect(() => {
    if (props.visible && props.team.ref === UNSAVED_TEAM_REF) {
      onStartRename();
    }
  }, [props.team.ref, props.visible]);

  const menuItems = useMemo(() => {
    const items: MenuProps['items'] = [];

    if (!props.team.isEmp) {
      items.push(
        {
          key: 'rename',
          label: 'Rename',
          onClick: onStartRename,
        },
        {
          type: 'divider',
        }
      );
    }

    if (props.team.isDeletable) {
      items.push({
        key: 'delete',
        label: 'Delete',
        danger: true,
        onClick: onDelete,
        disabled: isDeleting,
      });
    } else {
      items.push({
        key: 'hide',
        label: props.team.isHidden ? 'Unhide' : 'Hide',
        onClick: props.team.isHidden ? onUndelete : onDelete,
        disabled: isDeleting || isUpdating,
      });
    }

    return items;
  }, [props.team, isDeleting, isUpdating]);

  const isActive = isHovered || isMenuOpen;

  const inputId = `team#${props.team.ref}#input`;

  if (props.visible === false) {
    return null;
  }

  return (
    <DevExCard
      key={props.team.ref}
      size="small"
      title={
        isRenaming ? (
          <DevExInput
            id={inputId}
            className="card__nameInput"
            placeholder="Unnamed team"
            onKeyDown={(event) => {
              // clicking Enter completes renaming
              if (event.key === 'Enter') {
                onRename();
              }
            }}
            value={input}
            onChange={setInput}
            onBlur={() => {
              // blurring completes renaming
              onRename();
            }}
            embedded
          />
        ) : (
          <DevExText
            title={props.team.value}
            ellipsis
            lined
            strong
            style={{ color: props.team.isHidden ? theme.color.text.disabled : undefined }}
          >
            {props.team.value}
          </DevExText>
        )
      }
      style={{
        paddingBlock: theme.variable.spacing.xs,
        paddingInline: theme.variable.spacing.sm,
        borderColor: isRenaming ? theme.color.border.active : undefined,
      }}
      css={css`
        .card__header {
          height: 34px;
        }
      `}
      onHoverChange={setIsHovered}
      extra={
        <Flex align="center" gap={theme.variable.spacing.xs}>
          {!isRenaming && (
            <DevExPopover
              trigger={['click']}
              open={isMenuOpen}
              onOpenChange={setIsMenuOpen}
              content={<DevExMenu items={menuItems} selectable={false} />}
            >
              <DevExButton
                icon={<FontAwesomeIcon icon={faEllipsis} />}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                type="text"
                // GBAC RESTRICTION
                // only customer admins can update/delete teams
                adminRequired
                style={{
                  display: isActive ? undefined : 'none',
                  transition: 'none',
                }}
              />
            </DevExPopover>
          )}

          {!isActive && props.team.isHidden && (
            <FontAwesomeIcon
              className="hiddenIcon"
              icon={faEyeSlash}
              style={{ width: 32, color: theme.color.text.disabled }}
            />
          )}

          {!isActive && window.dx.user?.company.isEmp && !props.team.isEmp && (
            <DevExTag color="blue">DevEx</DevExTag>
          )}
        </Flex>
      }
    />
  );
};
