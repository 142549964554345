import React, { useState } from 'react';
import { Flex } from 'antd';
import { faChartSimpleHorizontal } from '@fortawesome/pro-regular-svg-icons';
import { faShieldHalved } from '@fortawesome/pro-thin-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';

import { Study, StudyClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExSegmented, DevExSegmentedOption } from 'jf/components/DevExSegmented';
import { useClientFlags } from 'jf/utils/useClientFlags';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { AnonymityThresholdAlert } from '../AnonymityThresholdAlert';
import { BenchmarkPercentile, BenchmarkSelect } from '../benchmark/BenchmarkSelect';
import { makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { TeamCascader } from '../TeamCascader';
import { useCanViewSurveyResults } from '../useCanViewSurveyResults';
import { useStudySegments } from '../useStudySegments';

import { OverallStudyScoreCard } from './OverallStudyScoreCard';
import { SegmentScoreHeatmap } from './SegmentScoreHeatmap';
import { TopicScoreTable } from './TopicScoreTable';

export const InsightsTab: React.FC = () => {
  const navigate = useNavigate();
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const { overallStudyScore } = useClientFlags();
  const theme = useDevExTheme();
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const isStudyClosed = study?.status === Study.status.CLOSED;
  const canViewResults = useCanViewSurveyResults({ studyRef });
  const showBenchmarks = !!window.dx.user?.company.aggregatable;

  const [benchmarkPercentile, setBenchmarkPercentile] = useState<BenchmarkPercentile>();
  const [segmentKey, setSegmentKey] = useState<string>();

  const studySegments = useStudySegments({ studyRef, teamRef });
  let segmentOptions: DevExSegmentedOption[] = [];

  if (studySegments) {
    segmentOptions = studySegments.map((segment) => ({ value: segment.key, label: segment.name }));
  }

  return (
    <DevExPageLayout>
      <Flex gap={theme.variable.spacing.sm}>
        {canViewResults && (
          <TeamCascader
            studyRef={studyRef}
            value={teamRef}
            onChange={(teamRef) => navigate(makeStudyAnalyzerPath(studyRef, teamRef, 'insights'))}
            allowClear
          />
        )}
        {showBenchmarks && (
          <BenchmarkSelect value={benchmarkPercentile} onChange={setBenchmarkPercentile} />
        )}
      </Flex>
      {overallStudyScore && (
        <OverallStudyScoreCard
          studyRef={studyRef}
          teamRef={teamRef}
          benchmarkPercentile={benchmarkPercentile}
        />
      )}
      <DevExCard title="How are we doing across topics?" bodyStyle={{ overflowX: 'auto' }}>
        <TopicScoreTable
          studyRef={studyRef}
          teamRef={teamRef}
          benchmarkPercentile={benchmarkPercentile}
        />
      </DevExCard>

      <DevExCard
        title="How do we differ across segments?"
        extra={
          !!segmentOptions.length && (
            <DevExSegmented options={segmentOptions} value={segmentKey} onChange={setSegmentKey} />
          )
        }
        bodyStyle={{ overflowX: 'auto' }}
      >
        {isStudyClosed ? (
          <>
            {studySegments?.length === 0 ? (
              <DevExEmpty
                icon={faShieldHalved}
                label="No segments groups met the anonymity threshold."
              />
            ) : (
              <div
                data-cy="segmentation-heatmap"
                style={{ padding: theme.variable.spacing.sm, margin: '0 auto' }}
              >
                <SegmentScoreHeatmap
                  studyRef={studyRef}
                  teamRef={teamRef}
                  segmentKey={segmentKey}
                />
              </div>
            )}

            {!!studySegments && <AnonymityThresholdAlert />}
          </>
        ) : (
          <DevExEmpty
            icon={faChartSimpleHorizontal}
            label="Segmentation will be available after the survey is closed"
          />
        )}
      </DevExCard>
    </DevExPageLayout>
  );
};
