import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faLineChart } from '@fortawesome/pro-thin-svg-icons';
import { keyBy } from 'lodash-es';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { trackEvent } from 'jf/analytics/Analytics';
import { CanonicalTopic, Study, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExRechartsTooltipContent } from 'jf/components/DevExRechartsTooltipContent';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../studyAnalyzer/ScoreBlock';

import { ChartAxisLabel } from './ChartAxisLabel';
import { useSequenceScores } from './useSequenceScores';
import { useSequenceStudies } from './useSequenceStudies';

const CHART_HEIGHT = 500;
const LINE_WIDTH = 3;

type TopicScoreTrendChartProps = {
  sequenceRef: string | undefined;
  teamRef?: string;
};

export const TopicScoreTrendChart: React.FC<TopicScoreTrendChartProps> = (props) => {
  const theme = useDevExTheme();

  const [activeTopicSlug, setActiveTopicSlug] = useState<string>();

  const closedStudies = useSequenceStudies(props.sequenceRef, Study.status.CLOSED);

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const topicsBySlug = keyBy(topics, 'slug');

  const scoreQueries = useSequenceScores({
    sequenceRef: props.sequenceRef,
    teamRef: props.teamRef,
  });

  const data = useMemo(() => {
    if (!closedStudies || !scoreQueries.every((query) => query.isSuccess)) {
      return undefined;
    }

    const data = closedStudies.slice(-6).map((study, i) => {
      const studyScores = scoreQueries[i].data!;
      if (studyScores.length > 0) {
        const datum = {
          name: study.name,
        };

        for (const score of studyScores) {
          datum[score.slug] = score.scaledAvg;
        }

        return datum;
      }
    });
    return data.filter((datum) => datum !== undefined);
  }, [closedStudies, scoreQueries]);
  const numStudies = data?.length;
  // collect topics that have data points on the chart, ordered by latest study scores
  const orderedTopics: CanonicalTopic[] = useMemo(() => {
    if (!topics || !scoreQueries.every((query) => query.isSuccess)) {
      return [];
    }

    // using a Map since ordering is important here
    const orderedTopicsBySlug = new Map<string, CanonicalTopic>();

    // iterate through queries backwards so the most recent study dictates topic order
    const queries = [...scoreQueries].reverse();

    for (const query of queries) {
      const scores = query.data!.sort((a, b) => b.scaledAvg - a.scaledAvg);
      for (const score of scores) {
        orderedTopicsBySlug.set(score.slug, topicsBySlug[score.slug]);
      }
    }

    return [...orderedTopicsBySlug].map(([_key, value]) => value);
  }, [topics, scoreQueries]);

  if (!data || !topics) {
    return <DevExSkeleton height={CHART_HEIGHT} />;
  }

  const activeTopic = activeTopicSlug ? topicsBySlug[activeTopicSlug] : undefined;

  const margin = parseInt(theme.variable.spacing.md);

  const isActive = (topicSlug: string) => !activeTopic || activeTopic.slug === topicSlug;

  return (
    <>
      {numStudies && numStudies > 1 ? (
        <ResponsiveContainer width={'100%'} height={CHART_HEIGHT}>
          <LineChart
            data={data}
            margin={{ left: margin * 2, top: margin, right: margin, bottom: margin }}
            onMouseLeave={() => setActiveTopicSlug(undefined)}
          >
            <CartesianGrid vertical={false} stroke={theme.color.visualization.grid} />
            <RechartsTooltip
              content={(tooltipProps) => (
                <DevExRechartsTooltipContent
                  {...tooltipProps}
                  // custom handling to only allow a single active dot
                  payload={tooltipProps.payload?.filter((p) => p.name === activeTopic?.slug)}
                  formatValue={(value) => (
                    <Flex vertical gap={theme.variable.spacing.xs}>
                      {activeTopic?.label}
                      <ScoreBlock score={value} />
                    </Flex>
                  )}
                />
              )}
            />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              content={({ payload }) => (
                <Flex
                  vertical
                  gap={theme.variable.spacing.xs}
                  style={{ paddingLeft: theme.variable.spacing.md }}
                >
                  {payload?.map((item, i) => {
                    const active = isActive(item.value);
                    return (
                      <Flex
                        key={i}
                        gap={theme.variable.spacing.sm}
                        align="center"
                        style={{
                          opacity: active ? 1 : theme.variable.opacity.disabled,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          trackEvent('series-analyzer:score-chart:click-topic', {
                            seriesRef: props.sequenceRef,
                            topicSlug: item.value,
                          });
                          setActiveTopicSlug(item.value);
                        }}
                      >
                        <div
                          style={{
                            width: theme.variable.spacing.md,
                            height: LINE_WIDTH,
                            backgroundColor: active ? item.color : theme.color.visualization.grid,
                          }}
                        />
                        <div>{topicsBySlug[item.value]?.label}</div>
                      </Flex>
                    );
                  })}
                </Flex>
              )}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tick={(tickProps) => <ChartAxisLabel {...tickProps} width={120} />}
              interval={0}
              padding={{
                left: parseInt(theme.variable.spacing.md),
                right: parseInt(theme.variable.spacing.md),
              }}
              tickMargin={parseInt(theme.variable.spacing.md)}
            />
            <YAxis
              domain={[0, 100]}
              tickLine={false}
              axisLine={false}
              tick={{ fill: theme.color.text.secondary, fontSize: theme.variable.fontSize.xs }}
              width={parseInt(theme.variable.spacing.lg)}
            />
            {orderedTopics.map((topic) => {
              const active = isActive(topic.slug);
              return (
                <Line
                  key={topic.slug}
                  type="monotone"
                  dataKey={topic.slug}
                  stroke={active ? theme.color.topic[topic.slug] : theme.color.visualization.grid}
                  strokeWidth={LINE_WIDTH}
                  dot={{ r: 4 }}
                  // custom handling to only allow a single active dot
                  activeDot={{
                    onMouseOver: () => setActiveTopicSlug(topic.slug),
                    opacity: topic.slug === activeTopicSlug ? 1 : 0,
                  }}
                  opacity={active ? 1 : theme.variable.opacity.disabled}
                  animationDuration={1000}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <DevExEmpty
          label="This team only has detailed results for one closed survey in the series"
          icon={faLineChart}
        />
      )}
    </>
  );
};
