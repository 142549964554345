/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useMemo, useState } from 'react';
import { merge } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { PublicStudy, PublicStudyClient, Study } from 'jf/api';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientMutation } from 'jf/utils/useClientQuery';

const STUDY_STATE_LOCAL_STORAGE_KEY = 'devex_study_state';

type SavedTeamRef = {
  teamRef: string;
  saved: boolean;
};

type SavedPromptResponse = {
  absoluteValue?: number;
  responseText?: string;
  responseTimeMs: number;
  saved: boolean;
};

type SavedVerbatims = {
  verbatims: { [topicSlug: string]: string };
  responseTimeMs: number;
  saved: boolean;
};

type SavedEndTime = {
  endTime: string;
  saved: boolean;
};

type StudyStep = {
  type: 'INTRO' | 'DEMOGRAPHIC' | 'PROMPT' | 'VOTING';
  typeIndex?: number; // index of step of specific type
  numbered?: boolean; // whether step should be numbered, like "Question 1"
};

export const getStudySteps = (study: PublicStudy | Study | undefined): StudyStep[] => {
  if (!study) {
    return [];
  }

  const studySteps: StudyStep[] = [{ type: 'INTRO' }];

  studySteps.push({ type: 'DEMOGRAPHIC', typeIndex: 0 });

  for (let i = 0; i < study.prompts.length; i++) {
    studySteps.push({ type: 'PROMPT', typeIndex: i, numbered: true });
  }

  const allPromptsAreOpenEnd = study.prompts.every((prompt) => prompt.type === 'OPEN_END');
  // Skip the voting step if all prompts are open-ended (there would be nothing to vote on)
  if (study.flags.includes('voting_enabled') && !allPromptsAreOpenEnd) {
    studySteps.push({ type: 'VOTING', numbered: true });
  }

  return studySteps;
};

type StudyState = {
  version: number;
  refs: {
    studyRef?: string;
    targetRef?: string;
    studyResponseRef?: string;
  };
  email?: string;
  respondentId?: string;
  stepIndex: number;
  stageIndex: number;
  savedTeamRef?: SavedTeamRef;
  savedPromptResponses: { [promptSlug: string]: SavedPromptResponse };
  savedVerbatims: SavedVerbatims;
  savedEndTime?: SavedEndTime;
};

const DEFAULT_STUDY_STATE: StudyState = {
  // this version should be incremented if StudyState is ever changed
  // this will prevent an old state in LocalStorage from blowing up the app
  version: 1,
  refs: {},
  stepIndex: 0,
  stageIndex: 0,
  savedPromptResponses: {},
  savedVerbatims: {
    verbatims: {},
    responseTimeMs: 0,
    saved: true,
  },
};

interface SSC extends StudyState {
  update: (partialStudyState: Partial<StudyState>) => void;
  clear: () => void;
  save: () => void;
  loaded: boolean;
  saved: boolean;
  saving: boolean;
  submitted: boolean;
  submitting: boolean;
}

export const StudyStateContext = createContext<SSC>({
  ...DEFAULT_STUDY_STATE,
  update: () => undefined,
  clear: () => undefined,
  save: () => undefined,
  loaded: false,
  saved: true,
  saving: false,
  submitted: false,
  submitting: false,
});

export const StudyStateProvider: React.FC = (props) => {
  const { studyRef, targetRef } = useParams<{ studyRef: string; targetRef?: string }>();

  const [studyState, setStudyState] = useLocalStorage<StudyState>(
    STUDY_STATE_LOCAL_STORAGE_KEY,
    DEFAULT_STUDY_STATE
  );
  const [loaded, setLoaded] = useState(false);

  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const updateStudyState = (partialStudyState: Partial<StudyState>) => {
    setStudyState((studyState) => merge({ ...studyState, ...partialStudyState }));
  };

  const clearStudyState = () => {
    setStudyState({
      ...DEFAULT_STUDY_STATE,
      refs: { studyRef, targetRef },
    });
  };

  const { mutateAsync: respondToStudyPrompt } = useClientMutation(
    PublicStudyClient.respondToStudyPrompt
  );

  const { mutateAsync: respondToStudyVerbatims } = useClientMutation(
    PublicStudyClient.respondToStudyVerbatims
  );

  const { mutateAsync: createTeamAssignment } = useClientMutation(
    PublicStudyClient.createTeamAssignment
  );

  const { mutateAsync: completeStudyResponse } = useClientMutation(
    PublicStudyClient.completeStudyResponse
  );

  const saveStudyState = () => {
    if (!studyState.refs.studyResponseRef) {
      return;
    }

    setSaving(true);

    const queries: Promise<void>[] = [];

    // save teamRef on server
    if (studyState.savedTeamRef && !studyState.savedTeamRef.saved) {
      queries.push(
        createTeamAssignment({
          studyResponseRef: studyState.refs.studyResponseRef,
          requestBody: {
            teamRef: studyState.savedTeamRef.teamRef,
          },
        }).then(() => {
          // mark that save was successful
          updateStudyState({
            savedTeamRef: {
              ...studyState.savedTeamRef!,
              saved: true,
            },
          });
        })
      );
    }

    // save prompt score on server
    for (const [promptSlug, savedPromptResponse] of Object.entries(
      studyState.savedPromptResponses
    )) {
      if (!savedPromptResponse.saved) {
        queries.push(
          respondToStudyPrompt({
            studyResponseRef: studyState.refs.studyResponseRef,
            requestBody: {
              promptSlug,
              absoluteValue: savedPromptResponse.absoluteValue,
              responseText: savedPromptResponse.responseText,
              responseTimeMs: savedPromptResponse.responseTimeMs,
            },
          }).then(() => {
            // mark that save was successful
            setStudyState((studyState) => {
              studyState.savedPromptResponses[promptSlug].saved = true;
              return {
                ...studyState,
                savedPromptResponses: { ...studyState.savedPromptResponses },
              };
            });
          })
        );
      }
    }

    // save verbatims on server
    if (!studyState.savedVerbatims.saved) {
      queries.push(
        respondToStudyVerbatims({
          studyResponseRef: studyState.refs.studyResponseRef,
          requestBody: {
            verbatims: Object.entries(studyState.savedVerbatims.verbatims).map(
              ([topicSlug, responseText]) => ({
                topicSlug,
                responseText,
              })
            ),
            responseTimeMs: studyState.savedVerbatims.responseTimeMs,
          },
        }).then(() => {
          // mark that save was successful
          updateStudyState({
            savedVerbatims: {
              ...studyState.savedVerbatims,
              saved: true,
            },
          });
        })
      );
    }

    Promise.allSettled(queries).then(() => setSaving(false));
  };

  const submitStudy = () => {
    if (!studyState.refs.studyResponseRef) {
      return;
    }

    setSubmitting(true);

    let queries: Promise<void>[] = [];

    if (studyState.savedEndTime && !studyState.savedEndTime.saved) {
      queries.push(
        completeStudyResponse({
          studyResponseRef: studyState.refs.studyResponseRef,
          requestBody: {
            endTime: studyState.savedEndTime.endTime,
          },
        }).then(() => {
          // mark that save was successful
          updateStudyState({
            savedEndTime: {
              ...studyState.savedEndTime!,
              saved: true,
            },
          });
        })
      );
    }

    Promise.allSettled(queries).then(() => setSubmitting(false));
  };

  const saved = useMemo(() => {
    let saved = true;
    if (studyState.savedPromptResponses) {
      // ensure we have prompts to save
      saved =
        (!studyState.savedTeamRef || studyState.savedTeamRef.saved) &&
        Object.values(studyState.savedPromptResponses).every(({ saved }) => saved) &&
        studyState.savedVerbatims.saved;
    }
    if (!saved && !saving) {
      saveStudyState();
    }

    return saved;
  }, [studyState.savedTeamRef, studyState.savedPromptResponses, studyState.savedVerbatims]);

  const submitted = useMemo(() => {
    const submitted = !!studyState.savedEndTime?.saved;

    if (saved && !submitted && !submitting) {
      submitStudy();
    }

    return submitted;
  }, [studyState.savedEndTime, saved]);

  // use useMemo so we clear old ref before fetching getPublicStudy for it
  useMemo(() => {
    // reset state if...
    const isNewStudy = studyRef !== studyState.refs.studyRef; // it's a new study
    const isPreview = !studyState.refs.studyResponseRef && studyState.stageIndex > 0; // it's a preview, don't persist state
    const isOldVersion =
      studyState.version === undefined || DEFAULT_STUDY_STATE.version > studyState.version; // how to store state has changed!
    const isSlackTargeting = targetRef && targetRef !== studyState.refs.targetRef; // it's a new target
    if (isNewStudy || isPreview || isOldVersion || isSlackTargeting) {
      clearStudyState();
    } else {
      // savedPromptResponses was newly added and it might not exist on existing state cached in LocalStorage
      if (!studyState.savedPromptResponses) {
        updateStudyState({ savedPromptResponses: {} });
      }
    }

    setLoaded(true);
  }, []);

  return (
    <StudyStateContext.Provider
      value={{
        ...studyState,
        update: updateStudyState,
        clear: clearStudyState,
        save: saveStudyState,
        loaded,
        saved,
        saving,
        submitted,
        submitting,
      }}
    >
      {props.children}
    </StudyStateContext.Provider>
  );
};

export const useStudyState = () => useContext(StudyStateContext);
