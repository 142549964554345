import React, { useMemo } from 'react';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { computeTextWidth } from 'jf/utils/computeTextWidth';

type ChartAxisLabelProps = {
  x: number;
  y: number;
  payload: {
    value: string;
  };
  width: number;
};

export const ChartAxisLabel: React.FC<ChartAxisLabelProps> = (props) => {
  const theme = useDevExTheme();

  const label = useMemo(() => {
    let text = props.payload.value;
    const font = `${theme.variable.fontSize.xs} ${theme.variable.fontFamily.primary}`;

    // truncate text until it meets the width
    while (computeTextWidth(text, font) > props.width) {
      text = text.slice(1);
    }

    // add ellipsis if needed
    if (text.length < props.payload.value.length) {
      text = '...' + text;
    }

    return text;
  }, [props.payload.value, props.width]);

  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text
        dy={theme.variable.spacing.md}
        fill={theme.color.text.secondary}
        fontSize={theme.variable.fontSize.xs}
        textAnchor="middle"
      >
        {label}
      </text>
    </g>
  );
};
