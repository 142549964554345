import React, { ReactNode, useMemo } from 'react';
import { Flex, Tooltip } from 'antd';
import { faSquare as faSquareLight } from '@fortawesome/pro-light-svg-icons';
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons';
import { faSquare as faSquareSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy, mapValues } from 'lodash-es';
import { css } from 'styled-components';

import { Prompt, StudyLibraryClient } from 'jf/api';
import { DevExSidePanel } from 'jf/common/DevExSidePanel';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { useStudyEstimatedTime } from 'jf/pages/study/useStudyEstimatedTime';
import { arrayOf } from 'jf/utils/arrayOf';
import { formatAvgTimeSec } from 'jf/utils/timeUtils';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientQuery } from 'jf/utils/useClientQuery';

const TOPIC_HYGIENE_PANEL_COLLAPSED_WIDTH = 150;
export const TOPIC_HYGIENE_PANEL_WIDTH = 320;

const styles = {
  topicHygienePanel: css`
    .topicHygienePanel__header {
      font-size: ${(props) => props.theme.variable.fontSize.lg};
      align-items: center;
      gap: ${(props) => props.theme.variable.spacing.sm};
      padding-top: ${(props) => props.theme.variable.spacing.lg};
    }

    .topicHygienePanel__entry {
      display: flex;
      flex-direction: column;
      gap: ${(props) => props.theme.variable.spacing.sm};

      .entry__title {
        color: ${(props) => props.theme.color.text.secondary};
      }

      .entry__metric {
        font-size: ${(props) => props.theme.variable.fontSize.xl};
        color: ${(props) => props.theme.color.text.primary};

        .metric__suffix {
          font-size: ${(props) => props.theme.variable.fontSize.sm};
          color: ${(props) => props.theme.color.text.secondary};
        }
      }
    }
  `,
};

type TopicHygienePanelProps = {
  icon: ReactNode;
  title: ReactNode;
  promptSlugs: string[];
  className?: string;
  collapsible?: boolean;
};

export const TopicHygienePanel: React.FC<TopicHygienePanelProps> = (props) => {
  const theme = useDevExTheme();

  const [expanded, setExpanded] = useLocalStorage('devex_topicHygienePanel_expanded', true);

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const topicsBySlug = keyBy(topics, 'slug');
  const promptsBySlug = keyBy(prompts, 'slug');

  // collect given promptSlugs into topics
  // (include all topics so we know which ones are empty as well)
  const promptsByTopicSlug: { [topicSlug: string]: Prompt[] } = mapValues(topicsBySlug, () => []);

  if (topics) {
    for (const promptSlug of props.promptSlugs) {
      const prompt = promptsBySlug[promptSlug];
      if (prompt) {
        promptsByTopicSlug[prompt.topicSlug].push(prompt);
      }
    }
  }

  const sortedPromptsByTopicSlug = Object.entries(promptsByTopicSlug).sort(
    ([_A, promptsA], [_B, promptsB]) => promptsB.length - promptsA.length
  );

  const openEndCount = useMemo(() => {
    return Object.values(promptsByTopicSlug)
      .flat()
      .filter((prompt) => prompt.type === Prompt.type.OPEN_END).length;
  }, [promptsByTopicSlug]);

  const topicSquares = (topicSlug: string) => {
    const prompts = promptsByTopicSlug[topicSlug];

    if (!prompts.length) {
      return arrayOf(1).map((i) => <FontAwesomeIcon key={i} icon={faSquareLight} />);
    }

    return prompts.map((prompt, i) => (
      <Tooltip key={i} title={prompt.text}>
        <div className="fa-layers">
          <FontAwesomeIcon
            icon={faSquareSolid}
            style={{
              color:
                prompt.scope === Prompt.scope.CUSTOMER
                  ? theme.color.offbrand.background.default
                  : theme.color.offbrand.default,
            }}
          />
          <FontAwesomeIcon
            icon={faSquareLight}
            style={{
              color: theme.color.offbrand.default,
            }}
          />
        </div>
      </Tooltip>
    ));
  };

  const promptCount = props.promptSlugs.length;
  const topicCount = Object.values(promptsByTopicSlug).filter((prompts) => prompts.length).length;
  const tooManyOpenTextWarning =
    'Limit open-ended questions to prevent survey fatigue and drop-offs';
  const { minutes, seconds } = useStudyEstimatedTime(promptCount);
  return (
    <DevExSidePanel
      width={TOPIC_HYGIENE_PANEL_WIDTH}
      className={props.className}
      collapse={
        props.collapsible
          ? {
              collapsed: !expanded,
              onCollapse: (collapsed) => setExpanded(!collapsed),
              collapsedWidth: TOPIC_HYGIENE_PANEL_COLLAPSED_WIDTH,
            }
          : undefined
      }
    >
      <Flex
        vertical
        style={{
          height: '100%',
          paddingInline: expanded ? theme.variable.spacing.lg : theme.variable.spacing.md,
          transition: 'padding-inline 150ms ease',
        }}
        gap={theme.variable.spacing.lg}
        css={styles.topicHygienePanel}
      >
        <Flex className="topicHygienePanel__header">
          {props.icon}
          <div style={{ transition: 'opacity 150ms ease', opacity: expanded ? 1 : 0 }}>
            {props.title}
          </div>
        </Flex>

        <div className="topicHygienePanel__entry">
          <div className="entry__title">{expanded ? 'Estimated Time to Complete ' : 'Time'} </div>
          <div className="entry__metric">{formatAvgTimeSec(minutes * 60 + seconds)}</div>
        </div>

        <div className="topicHygienePanel__entry">
          <div className="entry__title">Topic Questions</div>
          <Flex style={{ overflow: 'hidden' }} gap={theme.variable.spacing.sm} align="center">
            <div className="entry__metric">{promptCount}</div>
            {openEndCount >= 4 ? (
              <Flex
                style={{
                  color: theme.color.status.discovery.text,
                  fontSize: theme.variable.fontSize.xs,
                  lineHeight: theme.variable.lineHeight,
                }}
                gap={theme.variable.spacing.sm}
                align="center"
              >
                <Tooltip title={expanded ? '' : tooManyOpenTextWarning}>
                  <FontAwesomeIcon
                    icon={faLightbulb}
                    style={{ paddingTop: theme.variable.spacing.xs }}
                  />
                </Tooltip>
                {expanded ? <div>{tooManyOpenTextWarning}</div> : null}
              </Flex>
            ) : null}
          </Flex>
        </div>

        <div className="topicHygienePanel__entry">
          <div className="entry__title">Topics</div>
          <div className="entry__metric">{topicCount}</div>
          {!!topicCount && (
            <Flex
              gap={theme.variable.spacing.xs}
              style={{
                transition: 'opacity 150ms ease',
                opacity: expanded ? 1 : 0,
                pointerEvents: expanded ? 'all' : 'none',
              }}
            >
              {arrayOf(topicCount).map((i) => (
                <Tooltip key={i} title={topicsBySlug[sortedPromptsByTopicSlug[i][0]].label}>
                  <FontAwesomeIcon
                    icon={faSquareSolid}
                    style={{ color: theme.color.offbrand.default }}
                  />
                </Tooltip>
              ))}
            </Flex>
          )}
        </div>

        <div
          className="topicHygienePanel__entry"
          style={{
            transition: 'opacity 150ms ease',
            opacity: expanded ? 1 : 0,
            gap: theme.variable.spacing.md,
            pointerEvents: expanded ? 'all' : 'none',
          }}
        >
          <div className="entry__title">Questions by Topic</div>
          <Flex vertical gap={theme.variable.spacing.sm}>
            {sortedPromptsByTopicSlug.map(([topicSlug, prompts]) => (
              <Flex
                vertical
                key={topicSlug}
                gap={theme.variable.spacing.lg}
                style={{
                  color:
                    prompts.length === 0 ? theme.color.text.tertiary : theme.color.text.primary,
                  width: '300px',
                }}
              >
                <Flex
                  style={{ fontSize: theme.variable.fontSize.xs }}
                  gap={theme.variable.spacing.sm}
                >
                  <Tooltip title={topicsBySlug[topicSlug].longDescription}>
                    <div style={{ width: 150, textAlign: 'end' }}>
                      {topicsBySlug[topicSlug].label}
                    </div>
                  </Tooltip>
                  <Flex wrap="wrap" gap={2}>
                    {topicSquares(topicSlug)}
                  </Flex>
                  {prompts.length}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </div>
      </Flex>
    </DevExSidePanel>
  );
};
