import React, { useRef } from 'react';
import { Flex } from 'antd';
import { useScroll } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/themes/DevExTheme';

import { TopicDataRecord } from '../topicDrawer/useTopicData';
import { useStudyVerbatims } from '../useStudyVerbatims';

import { GradientTransition } from './GradientTransition';
import { ScrollableComments } from './ScrollingComments';
import { usePageIndexTracker } from './studyReveal';

const styles = {
  comments: css`
    width: 100vw;
    height: 800vh;
    background-color: ${(props) => props.theme.color.reveal.purple.main.background};

    .comments__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: 64px;
      color: ${(props) => props.theme.color.reveal.purple.main.text};
      font-weight: bold;
      padding-top: ${(props) => props.theme.variable.spacing.xxl};
    }
  `,
};

type CommentsPageProps = {
  type?: 'HIGH' | 'LOW';
  filteredTopics: TopicDataRecord[];
  slug: string;
};

export const CommentsPage: React.FC<CommentsPageProps> = (props) => {
  const theme = useDevExTheme();

  //fetch study data
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const { data: topicVerbatims } = useStudyVerbatims({
    studyRef: studyRef,
    teamRef: teamRef,
  });

  let topicIndex = -1;

  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  });

  usePageIndexTracker(targetRef, props.slug);

  const getRandomVerbatims = (textVerbatims) => {
    if (textVerbatims.length === 1 || textVerbatims.length === 2) {
      return textVerbatims;
    } else {
      const index1 = Math.floor(Math.random() * textVerbatims.length);
      let index2;
      do {
        index2 = Math.floor(Math.random() * textVerbatims.length);
      } while (index2 === index1);

      return [textVerbatims[index1], textVerbatims[index2]];
    }
  };

  return (
    <>
      <div css={styles.comments} ref={targetRef}>
        <Flex
          justify="center"
          style={{
            position: 'sticky',
            top: theme.variable.spacing.sm,
            overflow: 'clip',
          }}
        >
          <Flex vertical gap={theme.variable.spacing.md} style={{ position: 'relative' }}>
            <div className="comments__header"> Comments </div>
            {props.filteredTopics?.map((topicData, i) => {
              const filteredVerbatims = topicVerbatims?.filter(
                (verbatim) => verbatim.topicSlug === topicData.topic.slug && !!verbatim.responseText
              );

              const numFilteredVerbatims = filteredVerbatims?.length;

              if (filteredVerbatims && !!numFilteredVerbatims) {
                topicIndex = topicIndex + 1;
                const verbatimsPair = getRandomVerbatims(filteredVerbatims);
                return (
                  <div key={`${i}`}>
                    <ScrollableComments
                      scrollYProgress={scrollYProgress}
                      verbatims={verbatimsPair}
                      title={topicData.topic.label}
                      topicIndex={topicIndex}
                    />
                  </div>
                );
              }
              return null;
            })}
          </Flex>
        </Flex>
      </div>
      <div
        style={{
          height: '20vh',
          backgroundColor: theme.color.reveal.purple.main.background,
        }}
      />
      <GradientTransition
        topColor={theme.color.reveal.purple.main.background}
        bottomColor={theme.color.reveal.dark.background}
      />
    </>
  );
};
