import React, { useMemo, useState } from 'react';
import { Flex, Pagination } from 'antd';
import { debounce, keyBy } from 'lodash-es';

import { trackEvent } from 'jf/analytics/Analytics';
import { NumericScore, StudyLibraryClient, TextualResponse, TextualSummary } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExSearch } from 'jf/components/DevExSearch';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../ScoreBlock';

import { AISummary } from './AISummary';
import { FreqDistHistogram } from './FreqDistHistogram';

type PromptScoreCardProps = {
  score?: NumericScore;
  textResponses?: TextualResponse[];
  summary?: TextualSummary;
  promptSlug: string;
};

export const PromptScoreCard: React.FC<PromptScoreCardProps> = (props) => {
  const theme = useDevExTheme();
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const promptsBySlug = keyBy(prompts, 'slug');
  const prompt = promptsBySlug[props.promptSlug];
  const [searchQuery, setSearchQuery] = useState('');

  const debounceTrackSearch = useMemo(() => {
    return debounce((responseLength, search) => {
      trackEvent('survey-analyzer:open-text-questions:search', {
        search,
        results: responseLength,
        total: props.textResponses?.length,
        topic: prompt?.topicSlug,
      });
    }, 1000);
  }, [prompt]);

  const filteredResponses: TextualResponse[] | undefined = useMemo(() => {
    const results = props.textResponses?.filter((textResponse) =>
      textResponse.responseText.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (searchQuery?.length) {
      debounceTrackSearch(results?.length, searchQuery);
    }
    return results;
  }, [props.textResponses, searchQuery]);

  const showCount = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * showCount;
  const endIndex = startIndex + showCount;
  const filteredResponsesByPage = filteredResponses?.slice(startIndex, endIndex);

  return props.score !== undefined && prompt !== undefined ? (
    <DevExCard>
      <Flex gap={theme.variable.spacing.md} justify="space-between">
        <Flex vertical gap={theme.variable.spacing.sm}>
          <div style={{ lineHeight: theme.variable.lineHeight }}>{prompt?.text}</div>
        </Flex>
        <Flex vertical gap={theme.variable.spacing.sm}>
          <DevExText type="secondary">Score</DevExText>
          <ScoreBlock score={props.score?.scaledAvg} size="md" />
        </Flex>
      </Flex>
      <FreqDistHistogram prompt={prompt} score={props.score} />
      {prompt.choiceLabels && prompt.choiceLabels.length > 1 && (
        <Flex
          style={{
            width: '85%',
            marginTop: '-' + theme.variable.spacing.lg,
            alignSelf: 'center',
            fontSize: theme.variable.fontSize.xs,
            color: theme.color.text.secondary,
          }}
          justify="space-between"
        >
          <span>{prompt.choiceLabels[0]}</span>
          <span style={{ textAlign: 'right' }}>
            {prompt.choiceLabels[prompt.choiceLabels.length - 1]}
          </span>
        </Flex>
      )}
    </DevExCard>
  ) : (
    <DevExCard style={{ paddingInline: theme.variable.spacing.lg }}>
      <div
        style={{
          lineHeight: theme.variable.lineHeight,
          paddingTop: theme.variable.spacing.sm,
        }}
      >
        {prompt.text}
      </div>
      <Flex vertical gap={theme.variable.spacing.lg}>
        {window.dx.user?.company.canUseGenerativeAiFeatures && (
          <AISummary summary={props.summary?.summary} summaryType={props.summary?.summaryType} />
        )}
        <Flex align="center" justify="space-between">
          <Flex gap={theme.variable.spacing.sm}>
            <div
              style={{ lineHeight: theme.variable.lineHeight, color: theme.color.text.secondary }}
            >
              Responses
            </div>
            <DevExTag>{props.textResponses?.length} </DevExTag>
          </Flex>
          <DevExSearch
            placeholder="Search for a response"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            collapsible
          />
        </Flex>
        <Flex vertical gap={theme.variable.spacing.sm}>
          {filteredResponses &&
            filteredResponses?.length > 0 &&
            filteredResponsesByPage?.map((response, index) => (
              <div key={index}>
                <div
                  style={{
                    paddingBottom: theme.variable.spacing.sm,
                    borderBottom: theme.color.border.secondary,
                  }}
                >
                  "{response.responseText}"
                </div>
                <hr
                  style={{
                    border: `1px solid` + theme.color.border.secondary,
                    borderRadius: theme.variable.borderRadius,
                  }}
                />
              </div>
            ))}
        </Flex>
        <Flex justify="center" gap={theme.variable.spacing.md}>
          <Pagination
            total={props.textResponses?.length}
            pageSize={4}
            onChange={(page) => {
              trackEvent('study-analyzer:open-text-results:pagination', {
                page,
              });
              setCurrentPage(page);
            }}
          />
        </Flex>
      </Flex>
    </DevExCard>
  );
};
