import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { difference, intersectionBy, partition, sortBy, uniqBy } from 'lodash-es';
import { css } from 'styled-components';

import { Team } from 'jf/api';
import { useDevExTheme } from 'jf/common/themes/DevExTheme';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExCheckbox } from 'jf/components/DevExCheckbox';
import { DevExCollapsible } from 'jf/components/DevExCollapsible';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExText } from 'jf/components/DevExText';
import { TEAM_CARD_MAX_WIDTH } from 'jf/pages/teams/EditableTeamHierarchy';

type TeamInHierarchy = {
  team: Team;
  childTeams: TeamInHierarchy[];
};

type TeamHierarchyProps = {
  teams: Team[];
  selectedTeamRefs: string[];
  onChangeSelectedTeamRefs: (selectedTeamRefs: string[]) => void;
};

export const SelectableTeamHierarchy: React.FC<TeamHierarchyProps> = (props) => {
  const theme = useDevExTheme();

  const baseTeamsInHierarchy = useMemo(() => {
    const teams = sortBy(props.teams, 'value');

    // intialize teams in hierarchy
    const teamsInHierarchy: { [ref: string]: TeamInHierarchy } = {};
    for (const team of teams) {
      teamsInHierarchy[team.ref] = { team, childTeams: [] };
    }

    // populate childTeams for each team in hierarchy
    for (const team of teams) {
      if (team.parentRef && teamsInHierarchy[team.parentRef]) {
        teamsInHierarchy[team.parentRef].childTeams.push(teamsInHierarchy[team.ref]);
      }
    }

    // filter down to base teams
    return Object.values(teamsInHierarchy).filter((tih) => tih.team.level === 1);
  }, [props.teams]);

  const selectedTeams = props.teams.filter((team) => props.selectedTeamRefs.includes(team.ref));

  const onChangeSelectedTeams = (selectedTeams: Team[]) => {
    props.onChangeSelectedTeamRefs(selectedTeams.map((team) => team.ref));
  };

  return (
    <Flex vertical gap={theme.variable.spacing.sm}>
      <HierarchicalTeamCard
        teamInHierarchy={{ team: undefined, childTeams: baseTeamsInHierarchy }}
        selectedTeams={selectedTeams}
        onChangeSelectedTeams={onChangeSelectedTeams}
      />
    </Flex>
  );
};

type HierarchicalTeamCardProps = {
  teamInHierarchy: {
    team: Team | undefined;
    childTeams: TeamInHierarchy[];
  };
  selectedTeams: Team[];
  onChangeSelectedTeams: (selectedTeams: Team[]) => void;
};

const HierarchicalTeamCard: React.FC<HierarchicalTeamCardProps> = (props) => {
  const { team, childTeams } = props.teamInHierarchy;
  const theme = useDevExTheme();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [leafTeams, nonLeafTeams] = useMemo(
    () => partition(childTeams, (tih) => tih.team.isLeaf),
    [childTeams]
  );

  // leaf teams, including deeply-nested leaf teams
  const deepLeafTeams = useMemo(() => {
    const getDeepLeafTeams = (tih: TeamInHierarchy) => {
      const leafTeams: Team[] = [];
      for (const childTeam of tih.childTeams) {
        if (childTeam.team.isLeaf) {
          leafTeams.push(childTeam.team);
        } else {
          leafTeams.push(...getDeepLeafTeams(childTeam));
        }
      }
      return leafTeams;
    };

    return team ? getDeepLeafTeams({ team, childTeams }) : [];
  }, []);

  const selectedDeepLeafTeams = useMemo(
    () => intersectionBy(props.selectedTeams, deepLeafTeams, 'ref'),
    [props.selectedTeams, deepLeafTeams]
  );

  const onSelectTeam = (team: Team) => {
    props.onChangeSelectedTeams([...props.selectedTeams, team]);
  };

  const onDeselectTeam = (team: Team) => {
    props.onChangeSelectedTeams(
      props.selectedTeams.filter((selectedTeam) => selectedTeam.ref !== team.ref)
    );
  };

  const onSelectAllTeams = () => {
    props.onChangeSelectedTeams(uniqBy([...props.selectedTeams, ...deepLeafTeams], 'ref'));
  };

  const onDeselectAllTeams = () => {
    props.onChangeSelectedTeams(difference(props.selectedTeams, selectedDeepLeafTeams));
  };

  // hide parents with no children
  if (!childTeams.length) {
    return null;
  }

  return (
    <Flex
      vertical
      style={{ transition: 'gap 150ms ease', gap: isCollapsed ? 0 : theme.variable.spacing.sm }}
    >
      {team && (
        <Flex align="center" gap={theme.variable.spacing.sm}>
          <DevExCard
            size="small"
            title={
              <Flex align="center" gap={theme.variable.spacing.sm} style={{ overflow: 'hidden' }}>
                <FontAwesomeIcon
                  icon={faAngleDown}
                  color={theme.color.text.secondary}
                  style={{
                    transform: isCollapsed ? 'rotateZ(180deg)' : undefined,
                    transition: 'transform 150ms ease',
                  }}
                />
                <DevExText title={team.value} ellipsis lined strong>
                  {team.value}
                </DevExText>
              </Flex>
            }
            style={{
              background: theme.color.background.sunken,
              paddingInline: theme.variable.spacing.sm,
              width: TEAM_CARD_MAX_WIDTH,
              zIndex: 1,
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <DevExCheckbox
            checked={selectedDeepLeafTeams.length === deepLeafTeams.length}
            indeterminate={
              !!selectedDeepLeafTeams.length &&
              selectedDeepLeafTeams.length !== deepLeafTeams.length
            }
            onChange={() => {
              if (selectedDeepLeafTeams.length === deepLeafTeams.length) {
                onDeselectAllTeams();
              } else {
                onSelectAllTeams();
              }
            }}
          />
        </Flex>
      )}

      <DevExCollapsible collapsed={isCollapsed}>
        <Flex
          vertical
          gap={theme.variable.spacing.sm}
          style={{ paddingLeft: team ? theme.variable.spacing.lg : undefined }}
        >
          {!!leafTeams.length && (
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(${TEAM_CARD_MAX_WIDTH}, 1fr));
                gap: ${(props) => props.theme.variable.spacing.sm};
              `}
            >
              {leafTeams.map((tih) => (
                <LeafTeamCard
                  key={tih.team.ref}
                  team={tih.team}
                  selected={props.selectedTeams.some((team) => team.ref === tih.team.ref)}
                  onSelect={(selected) => {
                    if (selected) {
                      onSelectTeam(tih.team);
                    } else {
                      onDeselectTeam(tih.team);
                    }
                  }}
                />
              ))}
            </div>
          )}

          {nonLeafTeams.map((tih) => (
            <HierarchicalTeamCard
              key={tih.team.ref}
              teamInHierarchy={tih}
              selectedTeams={props.selectedTeams}
              onChangeSelectedTeams={props.onChangeSelectedTeams}
            />
          ))}
        </Flex>
      </DevExCollapsible>
    </Flex>
  );
};

type LeafTeamCardProps = {
  team: Team;
  selected: boolean;
  onSelect: (selected: boolean) => void;
};

const LeafTeamCard: React.FC<LeafTeamCardProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <DevExCard
      data-cy="selectable-team-card"
      key={props.team.ref}
      size="small"
      title={
        <Flex gap={theme.variable.spacing.sm} style={{ overflow: 'hidden' }}>
          <DevExCheckbox checked={props.selected} onChange={props.onSelect} />
          <DevExText title={props.team.value} ellipsis lined strong>
            {props.team.value}
          </DevExText>
        </Flex>
      }
      onClick={() => props.onSelect(!props.selected)}
      extra={
        <Flex align="center" gap={theme.variable.spacing.xs}>
          {window.dx.user?.company.isEmp && !props.team.isEmp && (
            <DevExTag color="blue">DevEx</DevExTag>
          )}
        </Flex>
      }
    />
  );
};
