import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  CreatedStudyRequest,
  CreatedStudySequenceRequest,
  StudyClient,
  StudySequenceClient,
} from 'jf/api';
import { prefetchClientQuery, useClientMutation } from 'jf/utils/useClientQuery';

import { useStudyBuilderState } from './StudyBuilderState';

export const useStudyCreator = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const state = useStudyBuilderState();

  const { mutateAsync: createStudy } = useClientMutation(StudyClient.createStudy);
  const { mutateAsync: createStudySequence } = useClientMutation(
    StudySequenceClient.createStudySequence
  );
  const { mutateAsync: duplicateStudy } = useClientMutation(StudyClient.duplicateStudy);

  const duplicate = async (studyRef: string) => {
    state.update({ creatingStudy: true });
    return duplicateStudy({
      studyRef,
    })
      .then(({ ref }) => {
        queryClient.invalidateQueries('GET_STUDIES');
        queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
        queryClient.invalidateQueries('GET_TEAMS'); // in case we synced teams from the EMP
        prefetchClientQuery(queryClient, StudyClient.getStudy, { studyRef: ref }).then(() => {
          navigate(`/study/${ref}/build`);
          state.update({ creatingStudy: false, step: 'customize-study' });
        });
      })
      .catch(() => {
        state.update({ creatingStudy: false }); // TODO: display error message
      });
  };

  const create = async (
    studyRequest: CreatedStudyRequest,
    sequenceRequest?: CreatedStudySequenceRequest
  ) => {
    state.update({ creatingStudy: true });

    if (sequenceRequest) {
      await createStudySequence({ requestBody: sequenceRequest }).then((sequence) => {
        studyRequest.sequenceRef = sequence.ref;
      });
    }

    return createStudy({
      requestBody: studyRequest,
    })
      .then(({ ref }) => {
        queryClient.invalidateQueries('GET_STUDIES');
        queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
        queryClient.invalidateQueries('GET_TEAMS'); // in case we synced teams from the EMP
        prefetchClientQuery(queryClient, StudyClient.getStudy, { studyRef: ref }).then(() => {
          navigate(`/study/${ref}/build`);
          state.update({ creatingStudy: false, step: 'customize-study' });
        });
      })
      .catch(() => {
        state.update({ creatingStudy: false }); // TODO: display error message
      });
  };

  return { create, duplicate };
};
