import { useMemo } from 'react';
import { keyBy } from 'lodash-es';

import {
  CanonicalTopic,
  Prompt,
  PublicStudyClient,
  StudyClient,
  StudyLibraryClient,
  TextualResponse,
} from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

type useTopicDataProps = {
  studyRef: string | undefined;
};

export type TopicTextDataRecord = {
  topic: CanonicalTopic;
  textResponses: TextualResponse[];
  prompt: Prompt;
};

export const useSortedTextTopicData = (props: useTopicDataProps) => {
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const { data: textResponses } = useClientQuery(PublicStudyClient.getTextualResponses, {
    studyRef: props.studyRef,
  });

  const topicsBySlug = keyBy(topics, 'slug');
  const promptsBySlug = keyBy(prompts, 'slug');
  const textPrompts = [...new Set(textResponses?.map((textResponse) => textResponse.prompt))];

  const data = useMemo(() => {
    if (!study || !topics || !prompts || !textResponses) {
      return undefined;
    }

    const data: TopicTextDataRecord[] = [];

    // create a data record for each topic
    for (const textPrompt of textPrompts) {
      const prompt = promptsBySlug[textPrompt];
      if (prompt) {
        data.push({
          topic: topicsBySlug[prompt.topicSlug],
          textResponses: textResponses.filter(
            (textResponses) => textResponses.prompt === textPrompt
          ),
          prompt: prompt,
        });
      }
    }
    return data;
  }, [study, topics, prompts, textResponses]);

  return data;
};
